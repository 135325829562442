<template>
  <div class="dropdown-container">
    <h6 @click="toggleDropdown" class="h6_custom">{{ selectedLabel }}</h6>
    <img 
      src="../assets/icons/blueIcons/arrow.svg"
      alt="Seta para dropdown" 
      :class="isDropdownOpen ? 'dropdown-arrow rotated': 'dropdown-arrow'"
      @click="toggleDropdown"
      width="15"
      height="15"
    />
    <div class="optionsList" v-if="isDropdownOpen">
      <div v-for="(option, index) in optionsToSelect" :key="index">
        <p class="label_option" @click="setSelectedOption(option)">{{ option.label }}</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['optionsToSelect'],
    emits: ['updateTable'],
    data() {
      return {
        selectedLabel: null,
        isDropdownOpen: false,
      };
    },
    methods: {
      toggleDropdown() {
        this.isDropdownOpen = !this.isDropdownOpen;
      },
      setSelectedOption(option) {
        this.selectedLabel = option.label;
        this.$emit('updateTable', option)
        this.toggleDropdown();
      }
    },
    created() {
      this.selectedLabel = this.optionsToSelect[0].label;
    }
  };
</script>

<style>
  .dropdown-container {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: right;
    gap: 10px;
    min-width: 50%;
    max-width: 80%;
  }
  .h6_custom {
    cursor: pointer;
    color: var(--dm_azul_00);
  }

  .optionsList {
    position: absolute;
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    gap: 8px;
    top: 100%;
    z-index: 2;
    padding: 10px 0;
    box-shadow: 2px 2px 5px rgb(96, 93, 93);
    background-color: white;
    width: 120%;
  }

  .label_option {
    width: 100%;
    padding: 0 10px;
    border-radius: 6px;
  }

  .label_option:hover {
    background-color: rgb(249, 250, 255);
  }

  .dropdown-arrow {
    cursor: pointer;
    transition: transform 0.3s ease;
  }

  .rotated {
    transform: rotate(180deg);
  }
</style>