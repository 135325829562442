<template>
  <header v-if="isAuthenticated" style="margin-bottom: 50px;">
    <HeaderPrincipal />
  </header>
  <router-view>
  </router-view>
</template>

<script>
import { useStore } from 'vuex';
import HeaderPrincipal from './components/HeaderPrincipal.vue';

export default {
  name: 'App',
  components: { HeaderPrincipal },
  data: () => ({
    //
  }),
  setup() {
    //
  },
  computed: {
    isAuthenticated() {
      const store = useStore();
      return store.getters['auth/isAuthenticated'];
    },
  },
};
</script>

<style>
body {
  background: var(--dm_creme_02);
}

/* Estilos globais são estilos repetidos em mais de um componente */

.global-asideBarAnimation--on {
    animation: asideBar--on .5s forwards;
}

.global-asideBarAnimation--off {
    animation: asideBar--off .5s forwards;
}

.global__content_marker {
  display: flex;
  width: 100%;
  max-width: 196px;
  align-items: center;
}

@keyframes asideBar--on {
    from {
        transform: translatex(-10px);
        opacity: 0;
    }

    to {
        transform: translatex(0);
        opacity: 1;
    }
}

@keyframes asideBar--off {
    from {
        transform: translatex(0);
        opacity: 1;
    }

    to {
        transform: translatex(-10px);
        opacity: 0;
    }
}

.global-div_marker {
  display: flex;
  align-items: center;
  width: 215px;
}

.global-insteresting_points {
  color: var(--dm_preto_00);
  margin-left: 10px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 85%;
  cursor: pointer;
}

.global-shadow {
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.1);
}

.global-ballon {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 3;
}

.global-ballon:hover .global-ballon__content {
  opacity: 1;
  pointer-events: initial;
}

.global-ballon__content {
  pointer-events: none;
  opacity: 0;
  border-radius: 6px;
  display: flex;
  align-items: center;
}

.global-ballon__left{
  display: flex;
  align-items: center;
  position: absolute;
  padding-left: 36px;
  left: 0;
}

.global-ballon__content dm-icon {
  z-index: 3;
}

.global-ballon__content::after {
  content: '';
  position: absolute;
  display: block;
  background: var(--dm_creme_02);
  padding: 12px;
  transform: rotate(45deg);
  z-index: 1;
  left: 25px;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.4);
}

.global-ballon__buttons {
  box-shadow: 3px 0px 3px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  gap: 10px;
  padding-right: 10px;
  height: 37px;
  border-radius: 6px;
  background: var(--dm_creme_02);
  padding-left: 8px;
  z-index: 2;
  overflow: visible;
}


/* Modificação no Radio do Element plus */
.el-popper.is-light{
  border: none;
}

.el-tabs {
  min-height: 600px;
}

.el-radio__input.is-checked .el-radio__inner {
  border: 4px solid var(--dm_azul_00) !important;
}

.el-radio__input.is-checked+.el-radio__label {
  color: var(--dm_azul_00) !important;
}

.el-radio {
  color: var(--dm_cinza_01) !important;
}

.el-radio__inner {
  border: 2px solid var(--dm_cinza_01);
}

/* Radio Disabled */
.el-radio__input.is-disabled.is-checked .el-radio__inner {
  border-color: var(--dm_cinza_03) !important;
}

.el-radio__input.is-disabled+span.el-radio__label {
  color: var(--dm_cinza_03) !important;
}

.el-radio__input.is-disabled.is-checked .el-radio__inner::after {
  background: none;
}

/* Slider */
.el-slider__bar {
  background-color: var(--dm_azul_00) !important;
  height: 10px;
}

.el-slider__button {
  border: 2px solid var(--dm_azul_00) !important;
}

.el-slider__runway {
  background-color: var(--dm_creme_00);
  height: 10px;
}

.el-slider__stop {
  background: var(--dm_creme_02) !important;
  border: solid 2px var(--dm_azul_00);
  width: 10px;
  height: 10px;
}

/* Legenda */
.container__mapLegend .el-collapse-item__content {
  padding-bottom: 0 !important;
}

.el-collapse {
  border: none !important;
  --el-collapse-border-color: none;
}

/* Icons */
.el-input .el-input__icon,
.el-input .el-input__icon:hover {
  color: var(--dm_azul_00);
}

.el-button.is-disabled {
  background: var(--dm_cinza_03) !important;
}
</style>