<template>
  <div>
    <div class="zoom-buttons">
      <button class= "button__up" @click="zoomUp()">+</button>
      <button class= "button__down" @click="zoomDown()">_</button>
    </div>
  </div>
</template>

<script>
  import MapService from '../services/map-service';
  export default {
    name: 'MapComponentZoom',
    methods: {
      zoomUp() {
        MapService.zoomUp();
      },
      zoomDown() {
        MapService.zoomDown();
      }
    }
  }
</script>

<style scoped>
.zoom-buttons {
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: var(--dm_creme_02);
  bottom: 50px;
  width: 30px;
  right: 14px;
  border: 2px solid var(--dm_creme_02);
  border-radius: 4px;
}

.button__down {
  position: relative;
  top: -8px;
  font-size: 20px;
  font-weight: bold;
}

.button__up {
  border-bottom: 1px solid #ccc;
  font-size: 20px;
  font-weight: bold;
}
</style>