<template>
  <div>
    <Alert ref="alertComponent" />
    <div class="exportar" v-if="popupHandle">
      <div class="exportar__content">
        <p>Para onde você deseja exportar esse pontos?</p>
        <el-row>
          <div class="mb-2 flex items-center text-sm">
            <el-radio-group v-model="exportFromWhere" class="ml-4">
              <el-radio label="1" size="large">Pipedrive</el-radio>
            </el-radio-group>
          </div>
        </el-row>
        <el-row>
          <el-button type="primario" @click="confirmExport"
            >Confirmar <dm-icon SendWhite style="display: flex; margin-left: 8px"
          /></el-button>
          <el-button @click="cancel" style="width: 120px" type="secundario">Cancelar</el-button>
        </el-row>
      </div>
    </div>

    <div
      class="area-de-estudo global-shadow global-asideBarAnimation--on"
      v-if="asideBoolean"
      id="areaDeEstudo"
    >
      <div class="area-estudo__container-close">
        <button @click="closeAsideBar(), closeLegendPosition()">
          <dm-icon Close />
        </button>
      </div>
      <p class="detail_01">{{ styleAsideBar }}</p>
      <h3>{{ data.properties.nome }}</h3>

      <span :class="`demo-tabs ${hideTabIcons ? `hideTab` : ''}`">
        <el-tooltip placement="top" content="Excluir" effect="light">
          <button class="demo-tabs--delete">
            <dm-icon @click="remove" Delete />
          </button>
        </el-tooltip>

        <el-tabs v-model="activeTab" type="card" @tab-click="handleClick">
          <!--  -->

          <el-tab-pane name="first">
            <template #label>
              <el-tooltip placement="top" content="Informações" effect="light">
                <dm-icon Warning style="margin-right: 10px" />
              </el-tooltip>
            </template>
            <div
              style="display: flex; gap: 12px; margin-bottom: 14px; flex-direction: column"
              v-if="!isMarker"
            >
              <dm-card-estatico>
                <h6>Total de pontos na área de estudo</h6>
                <h1 class="dm_azul_00--color">{{ getTotalPoints() }}</h1>
              </dm-card-estatico>
              <div class="area-estudo__cards">
                <dm-card-estatico>
                  <h6>Ponto Único</h6>
                  <h1 class="dm_azul_00--color">{{ pointsData?.unique }}</h1>
                </dm-card-estatico>
                <dm-card-estatico v-for="(groups, index) of pointsData?.groups" :key="index">
                  <h6>{{ groups.name }}</h6>
                  <h1 class="dm_azul_00--color">{{ groups.length }}</h1>
                </dm-card-estatico>
                <dm-card-estatico v-if="loading">
                  <div class="background-container">
                    <div class="loading-container">
                      <div class="loading-spinner" />
                    </div>
                  </div>
                </dm-card-estatico>
              </div>
            </div>

            <el-collapse class="receita" v-model="activeNames">
              <el-collapse-item title="Informações Receita Federal" v-if="isMarker && !isPin">
                <p>
                  <strong>Nome fantasia</strong>:
                  {{
                    data?.properties["nome fantasia"] || data?.properties["nome fantasia"] === "-"
                      ? data?.properties["nome fantasia"]
                      : "Não informado"
                  }}
                </p>
                <p>
                  <strong>Capital Social</strong>:
                  {{
                    currencyFormatter(parseInt(data?.properties["capital social"])) ??
                    "Não informado"
                  }}
                </p>
                <p>
                  <strong>Data de abertura</strong>:
                  {{ data?.properties["data inicio atividade"] ?? "Não informado" }}
                </p>
                <p><strong>Razão social</strong>: {{ data?.properties.nome ?? "Não informado" }}</p>
                <p>
                  <strong>CNPJ</strong>: {{ formatCNPJ(data?.properties.cnpj) ?? "Não informado" }}
                </p>
                <p><strong>Endereço</strong>: {{ data?.properties.address ?? "Não informado" }}</p>
                <p>
                  <strong>Telefone</strong>:
                  {{
                    data?.properties.telefone || data?.properties.telefone === "-"
                      ? formatFone(data?.properties.ddd, data?.properties.telefone)
                      : "Não informado"
                  }}
                </p>
                <p>
                  <strong>E-mail</strong>:
                  {{
                    data?.properties.email || data?.properties.email === "-"
                      ? lowerCases(data?.properties.email)
                      : (
                        data?.properties['e-mail'] || data?.properties['e-mail'] === "-"
                        ? lowerCases(data?.properties['e-mail'])
                        : "Não informado"
                      )
                  }}
                </p>
                <p>
                  <strong>CNAE</strong>:
                  {{
                    data?.properties["cnae principal"] || data?.properties["cnae principal"] === "-"
                      ? formatCNAE(data?.properties["cnae principal"])
                      : "Não informado"
                  }}
                </p>
                <p>
                  <strong>Descrição CNAE</strong>:
                  {{
                    data?.properties["cnaeDescricao"] || data?.properties["cnaeDescricao"] === "-"
                      ? data?.properties["cnaeDescricao"]
                      : "Não informado"
                  }}
                </p>
              </el-collapse-item>
              <hr />
              <div v-if="store.state.map.map.isMarker && data.properties.rating !== null">
                <el-collapse-item title="Informações Google">
                  <div flex-row v-if="isMarker">
                    <p class="body_02 dm_cinza_01--color">Avaliação Google:&nbsp;</p>
                    <span class="body_03 dm_cinza_01--color"
                      >{{ data.properties.rating }} &nbsp;</span
                    >
                    <div class="slide__star">
                      <img
                        v-for="(el, index) in 5"
                        class="slide__star--assessment"
                        :src="
                          index < Math.floor(data.properties.rating)
                            ? 'star__yellow.svg'
                            : 'star__gray.svg'
                        "
                        alt="Estrela"
                      />
                    </div>
                    <p class="body_03 dm_cinza_01--color">
                      &nbsp; ({{ data.properties.userRatingCount }})
                    </p>
                  </div>
                  <div flex-row class="body_02 dm_cinza_01--color" v-if="isMarker">
                    Faixa de preço:&nbsp;
                    <p class="body_03">
                      {{ getPriceLevel(data.properties.priceLevel.toUpperCase()) }}
                    </p>
                  </div>
                  <!-- <div flex-row class="body_02 dm_cinza_01--color" v-if="isMarker">
                    <p class="body_02"> {{data.properties.primaryType}}</p>
                  </div> -->
                  <div flex-row class="body_02 dm_cinza_01--color" v-if="!isMarker">
                    Estado:&nbsp;
                    <p class="body_03">{{ data?.properties.states ?? "Não Definido" }}</p>
                  </div>
                  <div flex-row v-else>
                    <p class="body_02 dm_cinza_01--color">
                      Endereço:&nbsp;
                      <span class="body_03 dm_cinza_01--color">{{
                        capitalizeStrings(data?.properties.address) ?? "Rod. Pres. Castello Branco."
                      }}</span>
                    </p>
                  </div>
                  <div flex-row class="body_02 dm_cinza_01--color" v-if="isMarker">
                    <p>
                      <span class="body_02" style="margin-right: 6px">Status:</span
                      ><span
                        :style="
                          data.properties.businessStatus.toUpperCase() === 'OPERATIONAL'
                            ? 'color: var(--dm_verde_00)'
                            : 'color: var(--dm_vermelho_00)'
                        "
                        >{{
                          data.properties.businessStatus.toUpperCase() === "OPERATIONAL"
                            ? "Ativo"
                            : "Desativado"
                        }}</span
                      >
                    </p>
                  </div>
                  <div flex-row class="body_02 dm_cinza_01--color" v-if="isMarker">
                    Telefone:&nbsp;
                    <p class="body_03">
                      {{ data.properties.nationalPhoneNumber }}
                    </p>
                  </div>
                  <div flex-row class="body_02 dm_cinza_01--color" v-if="isMarker">
                    <p class="body_02">Site:&nbsp;</p>
                    <a
                      class="slide__links lead__info"
                      :href="data.properties.websiteUri"
                      target="_blank"
                      >{{ data.properties.websiteUri }}</a
                    >
                  </div>
                  <div flex-row class="body_02 dm_cinza_01--color" v-if="!isMarker">
                    Área total:&nbsp;
                    <p class="body_03">{{ data?.properties.totalKm ?? 0 }} km²</p>
                  </div>
                  <div class="maps__details" v-else>
                    <a class="maps__link" @click="goToMaps()">
                      <p class="body_02 dm_cinza_01--color">
                        Google Maps:&nbsp;
                        <span
                          style="color: var(--dm_azul_00); text-decoration: underline"
                          class="body_03"
                          >{{ data?.properties.nome ?? "Não encontrado" }}</span
                        >
                      </p>
                      <img
                        style="margin-top: 5px"
                        src="../assets/icons/blueicons/detalhamento.svg"
                        alt="detalhamento do posto no Google Maps"
                      />
                    </a>
                  </div>
                </el-collapse-item>
                <hr />
              </div>
              <el-collapse-item title="Dados socioeconônimos" name="actived">
                <div style="margin-left: 4px">
                  <el-radio-group v-model="radio" @change="handleChange" v-if="isMarker">
                    <el-radio :label="MUNICIPIO">Município</el-radio>
                    <el-radio :label="SETOR_CENSITARIO">Setor censitário</el-radio>
                  </el-radio-group>
                </div>
                <div class="area-estudo__cards">
                  <dm-card-estatico>
                    <p class="body_04">Renda média p/ Número de Salários Mínimos</p>
                    <p class="body_02">2,08</p>
                  </dm-card-estatico>
                  <dm-card-estatico>
                    <p class="body_04">PIB</p>
                    <p class="body_02">{{ currencyFormatter(parseInt(data.properties[PIB])) }}</p>
                  </dm-card-estatico>
                  <dm-card-estatico>
                    <p class="body_04">População casada</p>
                    <p class="body_02">45,24%</p>
                  </dm-card-estatico>
                  <dm-card-estatico>
                    <p class="body_04">Número de domicílios</p>
                    <p class="body_02">{{ miFormatter(parseInt(data.properties[DOMICILIOS])) }}</p>
                  </dm-card-estatico>
                  <dm-card-estatico>
                    <p class="body_04">Pessoas por domicílio</p>
                    <p class="body_02">
                      {{ (data.properties[RESIDENTES] / data.properties[DOMICILIOS]).toFixed(2) }}
                    </p>
                  </dm-card-estatico>
                  <dm-card-estatico>
                    <p class="body_04">População</p>
                    <p class="body_02">{{ miFormatter(parseInt(data.properties[RESIDENTES])) }}</p>
                  </dm-card-estatico>
                  <dm-card-estatico>
                    <p class="body_04">Idosos</p>
                    <p class="body_02">
                      {{ miFormatter(parseInt(data.properties[POP_IDOSOS.toLowerCase()])) }}
                    </p>
                  </dm-card-estatico>
                  <dm-card-estatico>
                    <p class="body_04">Adultos</p>
                    <p class="body_02">{{ miFormatter(parseInt(data.properties[POP_ADULTOS])) }}</p>
                  </dm-card-estatico>
                  <dm-card-estatico>
                    <p class="body_04">Jovens</p>
                    <p class="body_02">{{ miFormatter(parseInt(data.properties[POP_JOVENS])) }}</p>
                  </dm-card-estatico>
                  <dm-card-estatico>
                    <p class="body_04">Crianças</p>
                    <p class="body_02">
                      {{ miFormatter(parseInt(data.properties[POP_CRIANCAS])) }}
                    </p>
                  </dm-card-estatico>
                  <!-- <dm-card-estatico>
                    <p class="body_04">Renda mensal homens</p>
                    <p class="body_02">{{ miFormatter(parseInt(data.properties[RENDA_HOMENS])) }}</p>
                  </dm-card-estatico>
                  <dm-card-estatico>
                    <p class="body_04">Renda mensal mulheres</p>
                    <p class="body_02">{{ miFormatter(parseInt(data.properties[RENDA_MULHERES])) }}</p>
                  </dm-card-estatico> -->
                  <dm-card-estatico>
                    <p class="body_04">Mortes homens</p>
                    <p class="body_02">
                      {{ miFormatter(parseInt(data.properties[MORTES_HOMENS])) }}
                    </p>
                  </dm-card-estatico>
                  <dm-card-estatico>
                    <p class="body_04">Mortes mulheres</p>
                    <p class="body_02">
                      {{ miFormatter(parseInt(data.properties[MORTES_MULHERES])) }}
                    </p>
                  </dm-card-estatico>
                  <dm-card-estatico>
                    <p class="body_04">Total mortes</p>
                    <p class="body_02">
                      {{ miFormatter(parseInt(data.properties[TOTAL_MORTES])) }}
                    </p>
                  </dm-card-estatico>
                  <dm-card-estatico>
                    <p class="body_04">Mortes por domicílio</p>
                    <p class="body_02">
                      {{
                        floatFormatter(
                          parseInt(data.properties[TOTAL_MORTES]) /
                            parseInt(data.properties[DOMICILIOS])
                        )
                      }}
                    </p>
                  </dm-card-estatico>
                </div>
              </el-collapse-item>
              <hr />
            </el-collapse>
          </el-tab-pane>

          <!--  -->

          <el-tab-pane
            label="Config"
            name="second"
            v-if="data.properties.radius"
            style="min-height: 500px"
          >
            <template #label>
              <el-tooltip placement="top" content="Busca web" effect="light">
                <dm-icon Website />
              </el-tooltip>
            </template>
            <div class="lead">
              <h5>Busca web</h5>
              <div class="auto__complete--icon">
                <el-input
                  v-model="inputVModel"
                  @change="querySearch"
                  clearable
                  class="inline-input w-100"
                  placeholder="Digite um tipo de estabelecimento"
                />
              </div>

              <div class="lead__btn" id="lead__btn">
                <div class="lead__content__filters">
                  <el-tooltip placement="top" content="Filtro" effect="light">
                    <button @click="handlePopUpFilter">
                      <dm-icon Filter v-if="countSelectedFilter === 0" />
                      <dm-icon FilterBlue v-else />
                    </button>
                  </el-tooltip>
                  <span class="selected_filter detail_03" v-if="countSelectedFilter !== 0"
                    >Filtros {{ countSelectedFilter }}</span
                  >
                </div>
                <div class="lead__content__filters">
                  <el-tooltip placement="top" content="Ordenar" effect="light">
                    <button ref="buttonRefOrder" v-click-outside="onClickOutsideOrder">
                      <dm-icon OrderFilter v-if="!selectedOrder" />
                      <dm-icon OrderFilterBlue v-else />
                    </button>
                  </el-tooltip>
                  <span class="selected_filter detail_03" v-if="selectedOrder">{{
                    selectedOrder
                  }}</span>
                </div>
                <el-popover
                  ref="popUpOrder"
                  :virtual-ref="buttonRefOrder"
                  trigger="click"
                  virtual-triggering
                  width="none"
                >
                  <div class="lead_order">
                    <h6>Ordenar por:</h6>
                    <div class="order_content">
                      <button @click="sortPlaces('a-z', 'A - Z')" class="order_btn">
                        <span class="body_02 order_text">A - Z</span>
                      </button>
                      <hr class="horizontal" />
                    </div>
                    <div class="order_content">
                      <button @click="sortPlaces('z-a', 'Z - A')" class="order_btn">
                        <span class="body_02 order_text">Z - A</span>
                      </button>
                      <hr class="horizontal" />
                    </div>
                    <div class="order_content">
                      <button @click="sortPlaces('highestPrice', 'Maior preço')" class="order_btn">
                        <span class="body_02 order_text">Maior preço</span>
                      </button>
                      <hr class="horizontal" />
                    </div>
                    <div class="order_content">
                      <button @click="sortPlaces('lowestPrice', 'Menor preço')" class="order_btn">
                        <span class="body_02 order_text">Menor preço</span>
                      </button>
                      <hr class="horizontal" />
                    </div>
                    <div class="order_content">
                      <button
                        @click="sortPlaces('highestClassification', 'Maior classificação')"
                        class="order_btn"
                      >
                        <span class="body_02 order_text">Maior classificação</span>
                      </button>
                      <hr class="horizontal" />
                    </div>
                    <div class="order_content">
                      <button
                        @click="sortPlaces('lowestClassification', 'Menor classificação')"
                        class="order_btn"
                      >
                        <span class="body_02 order_text">Menor classificação</span>
                      </button>
                    </div>
                  </div>
                </el-popover>

                <hr class="vertical" />

                <el-tooltip placement="top" content="Salvar" effect="light">
                  <button @click="createPin">
                    <dm-icon Folder />
                  </button>
                </el-tooltip>
                <el-tooltip placement="top" content="Exportar" effect="light">
                  <button @click="upExportar">
                    <dm-icon Upload />
                  </button>
                </el-tooltip>
                <el-tooltip placement="top" content="Selecionar tudo" effect="light">
                  <el-checkbox v-model="mainCheckBox" @change="handleCheckAllChange" />
                </el-tooltip>
              </div>

              <!-- Cards -->

              <el-checkbox-group v-model="checkList" v-if="loadingPlaces">
                <div
                  class="slide__card"
                  v-for="(lead, index) in placesFiltered"
                  :key="index"
                  :id="`card_${index}`"
                >
                  <div style="display: flex; justify-content: space-between">
                    <h6>{{ lead.geojson.properties.displayName.text }}</h6>
                    <el-checkbox :label="index"><span /></el-checkbox>
                  </div>
                  <div class="slide__content">
                    <div class="slide__animation">
                      <div class="slide__content--ctn">
                        <p>{{ lead.geojson.properties.rating }}</p>
                        <div class="slide__star">
                          <img
                            v-for="(el, index) in 5"
                            class="slide__star--assessment"
                            :src="
                              index < Math.floor(lead.geojson.properties.rating)
                                ? 'star__yellow.svg'
                                : 'star__gray.svg'
                            "
                            alt="Estrela"
                          />
                        </div>
                        <p>({{ lead.geojson.properties.userRatingCount }})</p>
                      </div>

                      <!--  -->

                      <div class="slide__flex">
                        <p>
                          <span class="lead_hide body_02">Endereço:</span>
                          <span class="lead__info">{{
                            lead.geojson.properties.shortFormattedAddress
                          }}</span>
                        </p>
                      </div>

                      <div class="slide__flex" v-if="lead.geojson.properties.primaryType">
                        <p>
                          <span class="lead_hide body_02">{{ getTypeToView(lead.geojson.properties.primaryType) }}</span>
                        </p>
                      </div>

                      <!--  -->

                      <div class="slide__flex lead_hide">
                        <p>
                          <span class="body_02" style="margin-right: 6px">Status:</span
                          ><span
                            :style="
                              lead.geojson.properties.businessStatus === 'OPERATIONAL'
                                ? 'color: var(--dm_verde_00)'
                                : 'color: var(--dm_vermelho_00)'
                            "
                            >{{
                              lead.geojson.properties.businessStatus === "OPERATIONAL"
                                ? "Ativo"
                                : "Desativado"
                            }}</span
                          >
                        </p>
                      </div>

                      <!--  -->

                      <div class="slide__flex" v-if="lead.geojson.properties.priceLevel">
                        <p>
                          <span class="body_02 dm_cinza_01--color">Faixa de preço: </span>
                          <span class="body_03">{{
                            getPriceLevel(lead.geojson.properties.priceLevel)
                          }}</span>
                        </p>
                      </div>

                      <!--  -->

                      <span class="lead_hide">
                        <div class="slide__flex" v-if="lead.geojson.properties.nationalPhoneNumber">
                          <p class="body_02">Telefone:</p>
                          <p class="slide__phone lead__info">
                            {{ lead.geojson.properties.nationalPhoneNumber }}
                          </p>
                        </div>

                        <div class="slide__flex" v-if="lead.geojson.properties.websiteUri">
                          <p class="body_02">Site:</p>
                          <a
                            class="slide__links lead__info"
                            :href="lead.geojson.properties.websiteUri"
                            target="_blank"
                            >{{ lead.geojson.properties.websiteUri }}</a
                          >
                        </div>

                        <!--<div class="slide__flex">
                          <p class="body_02">Redes sociais:</p>
                          <a
                            class="slide__links lead__info"
                            v-for="media in lead.socialMedia"
                            :href="media.link"
                            target="_blank"
                            >{{ media.media }}</a
                          >
                        </div> -->

                        <!--  -->
                        <div class="slide__flex" v-if="lead.geojson.properties.googleMapsUri">
                          <p class="body_02">Google Maps:</p>
                          <a
                            class="slide__links lead__info"
                            :href="lead.geojson.properties.googleMapsUri"
                            target="_blank"
                            >{{ lead.geojson.properties.displayName.text }}</a
                          >
                        </div>
                      </span>

                      <!--  -->
                    </div>
                    <!-- <img class="slice__img" :src="getImg(lead.geojson.properties.photos)" alt="Foto do local" /> -->
                  </div>
                  <button class="slide__handle" @click="expandiCard(`card_${index}`)">
                    <dm-icon ArrowDown />
                  </button>
                </div>
              </el-checkbox-group>
              <div class="lead__filter" v-if="popUpFilter">
                <span class="close_filters">
                  <button @click="handlePopUpFilter">
                    <dm-icon Close />
                  </button>
                </span>

                <h6>Filtro</h6>
                <el-select
                  v-model="selectedFilters.rating"
                  placeholder="Classificação"
                  style="width: 258px"
                >
                  <el-option
                    v-for="(item, i) in DROPDOWN_RATING"
                    :key="i"
                    :label="item.label"
                    :value="item.value"
                  >
                    <div class="content_item">
                      <span>{{ item.label }}</span>
                      <span v-if="item.value != undefined"
                        ><img
                          v-for="(el, index) in 5"
                          class="slide__star--assessment"
                          :src="
                            index < Math.floor(item.value) ? 'star__yellow.svg' : 'star__gray.svg'
                          "
                          alt="Estrela"
                      /></span>
                    </div>
                  </el-option>
                </el-select>
                <el-select
                  v-model="selectedFilters.priceLevel"
                  placeholder="Preço"
                  style="width: 258px"
                >
                  <el-option
                    v-for="(item, i) in DROPDOWN_PRICE"
                    :key="i"
                    :label="item.label"
                    :value="item.value"
                  >
                    <span>{{ item.label }}</span>
                  </el-option>
                </el-select>
                <el-select
                  v-model="selectedFilters.businessStatus"
                  placeholder="Status"
                  style="width: 258px"
                >
                  <el-option
                    v-for="(item, i) in DROPDOWN_STATS"
                    :key="i"
                    :label="item.label"
                    :value="item.value"
                  >
                    <span>{{ item.label }}</span>
                  </el-option>
                </el-select>
                <el-select v-model="selectedFilters.types" placeholder="Tipo" style="width: 258px">
                  <el-option
                    v-for="(item, i) in listAllTypes"
                    :key="i"
                    :label="item.label"
                    :value="item.value"
                  >
                    <span>{{ item.label }}</span>
                  </el-option>
                </el-select>
                <div class="lead__btns">
                  <el-button type="primario" @click="applyFilters">Aplicar</el-button>
                  <el-button type="secundario" @click="removeFilters">Limpar</el-button>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </span>
    </div>
  </div>
</template>
<script>
import {
  STYLE_POINTS_RESULT,
  STYLE_POINTS,
  SETOR_CENSITARIO,
  MUNICIPIO,
  GROUP_POINTS,
  RENDA,
  DOMICILIOS,
  RESIDENTES,
  PIB,
  POP_CRIANCAS,
  POP_JOVENS,
  POP_ADULTOS,
  POP_IDOSOS,
  MORTES_HOMENS,
  MORTES_MULHERES,
  TOTAL_MORTES,
  RENDA_HOMENS,
  RENDA_MULHERES,
  DROPDOWN_RATING,
  DROPDOWN_PRICE,
  DROPDOWN_STATS,
  DICIONARY_TYPES,
} from "../helpers/constants";
import { ref, unref, watch, computed } from "vue";
import openEyes from "../assets/icons/blueicons/visualizar.svg";
import MapService from "../services/map-service";
import { FeatureService } from "../services/feature-service";
import { useStore } from "vuex";
import optionsBlack from "../../../assets/icons/blackicons/Options.svg";
import { currencyFormatter, miFormatter, floatFormatter } from "../helpers/formatters";
import { capitalizeStrings } from "../helpers/transforms";
import {
  POINTS_NAME,
  LEADS_LAYER,
  PRICES_LEVEL,
  CLASSIFICATION_PRICE_LEVEL,
} from "../helpers/constants";
import { format } from "date-fns";
import { LayerService } from "../services/layer-service";
import { GoogleService } from "../services/google-service";
import { makePopup } from "../helpers/transforms";
import MyFeature from "../models/feature";
import { UserService } from "../services/user-service";
import Alert from "../../../components/Alert.vue";

export default {
  name: "MapAsideBar",
  components: {
    Alert,
  },
  setup() {
    const store = useStore();
    const user = store.getters["auth/getUser"];
    const pointsData = ref({ groups: [] });
    const radio = ref(MUNICIPIO);
    const data = ref();
    const loading = ref(true);
    const isMarker = ref(false);
    const featureService = new FeatureService(user);
    const googleService = new GoogleService(user);
    const layerService = new LayerService(user);
    const userService = new UserService(user);
    const isPin = ref(false);
    const inputVModel = ref("");
    const checkList = ref([]);
    const places = ref([]);
    const placesFiltered = ref([]);
    const loadingPlaces = ref(false);
    const mainCheckBox = ref(false);
    const getFeatureCollection = computed(() => store.getters["map/getFeatureCollection"]);
    const updateFeatures = ref(false);
    const selectedOrder = ref(null);
    const countSelectedFilter = ref(0);
    const listAllTypes = ref([]);
    const selectedFilters = ref({
      rating: null,
      priceLevel: null,
      businessStatus: null,
      types: null,
    });

    const formatDate = (date) => {
      return format(new Date(date), "dd/MM/yyyy");
    };

    const getRevenueData = async (data) => {
      isPin.value = false;
      const { id } = data;
      let feature = await featureService.getFeatureById(id, user.token);
      feature.properties["data inicio atividade"]
        ? (feature.properties["data inicio atividade"] = formatDate(
            feature.properties["data inicio atividade"]
          ))
        : null;
      return {
        ...data,
        properties: {
          ...data.properties,
          ...feature.properties,
        },
      };
    };

    const getDataPoints = async (oldData) => {
      const getCountiesAndCensusTract = await MapService.addNameByLngLat(
        { lat: oldData.geometry.coordinates[1], lng: oldData.geometry.coordinates[0] },
        user.token
      );
      const idFeature = getCountiesAndCensusTract.filter((el) => el.name === radio.value)[0]
        .idFeature;

      let feature = await featureService.getFeatureById(idFeature, user.token);
      let pib = 0;
      delete feature.properties.nome;
      delete feature.properties.discriminator;
      isMarker.value = true;
      if (radio.value === SETOR_CENSITARIO) {
        pib = oldData.properties[PIB];
        delete feature.properties[PIB];
      } else {
        pib = feature.properties[PIB];
      }
      let properties = {
        totalKm: oldData.properties.totalKm ?? "0",
        states: capitalizeStrings(oldData.properties.states) ?? "Não cadastrado",
        address:
          oldData.properties.address ??
          oldData.properties.shortformattedaddress ??
          "Rod. Pres. Castello Branco",
        nome:
          oldData.properties.nome ??
          oldData.properties[POINTS_NAME] ??
          oldData.properties.displayname,
        rating: oldData.properties.rating ?? null,
        businessStatus: oldData.properties.businessstatus ?? "Não definido",
        googleMapsUri: oldData.properties.googlemapsuri ?? "Não definido",
        priceLevel: oldData.properties.pricelevel ?? "Não definido",
        userRatingCount: oldData.properties.userratingcount ?? "Não definido",
        websiteUri: oldData.properties.websiteuri ?? "Não definido",
        style: oldData.properties.style ?? "Não definido",
        visibility: oldData.properties.visibility ?? "Não definido",
        nationalPhoneNumber: oldData.properties.nationalphonenumber ?? "Não definido",
        idLead: oldData.properties.name ?? "Não definido",
        discriminator: oldData.properties.discriminator,
        [PIB]: pib,
        ...feature.properties,
        radius: oldData.properties.radius ?? false,
      };
      return {
        ...oldData,
        type: oldData.type,
        geometry: oldData.geometry,
        id: oldData.id,
        properties,
      };
    };

    const addGroups = async (feature) => {
      const idFeature = feature.properties.idfeaturestudy
        ? feature.properties.idfeaturestudy
        : feature.id;
      for (const [i, group] of GROUP_POINTS.entries()) {
        const discriminatorRes = await featureService.getFeatureByDiscriminatorContainedFeatures(
          idFeature,
          user.token,
          group.discriminator
        );
        if (
          (data.value.properties.idfeaturestudy
            ? data.value.properties.idfeaturestudy
            : data.value.id) === idFeature
        ) {
          pointsData.value.groups.push({ name: group.name, length: discriminatorRes.length });
          if (i === GROUP_POINTS.length - 1) {
            loading.value = false;
          }
        }
      }
    };

    const getPoints = async (feature) => {
      feature.geometry.type === "Point" ? (data.value = await getDataPoints(feature)) : null;
      pointsData.value.groups = [];
      const uniquePoints = await featureService.getFeatureByDiscriminatorContainedFeatures(
        feature.id,
        user.token,
        "points"
      );
      pointsData.value.unique = uniquePoints.length;
      addGroups(feature);
    };

    const getTotalPoints = () => {
      let total = 0;
      pointsData.value.groups.length > 0
        ? pointsData.value.groups.forEach((el) => {
            total += el.length;
          })
        : null;
      pointsData.value.unique ? (total += pointsData.value.unique) : null;
      return total;
    };

    const goToMaps = () => {
      const url = "https://www.google.com/maps/search/?api=1&query=";
      window.open(
        `${url}${
          data.value.properties.address ? data.value.properties.address : data.value.properties.nome
        }`,
        "_blank"
      );
    };

    const getPriceLevel = (value) => {
      return PRICES_LEVEL[value];
    };

    const getTotalArea = async (data) => {
      if (data.geometry.type === "Point") return `${data.properties.radius / 1000}`;
      const area = await featureService.getFeatureCalculateArea(data.id, user.token);
      return floatFormatter(parseInt(area));
    };

    /*  */
    const buttonRef = ref();
    const buttonRefOrder = ref();
    const popUpFilter = ref(false);
    const popUpOrder = ref();

    const onClickOutsideOrder = () => {
      unref(popUpOrder).popperRef?.delayHide?.();
    };

    const handlePopUpFilter = () => {
      popUpFilter.value = !popUpFilter.value;
    };

    const applyFilters = () => {
      countSelectedFilter.value = 0;
      let placesToFilter = places.value;
      for (const [key, value] of Object.entries(selectedFilters.value)) {
        if (value === null) continue;
        countSelectedFilter.value += 1;
        switch (key) {
          case "rating":
            placesToFilter = placesToFilter.filter((lead) => {
              if (!value) return lead;
              return lead.geojson.properties[key] >= value;
            });
            placesFiltered.value = placesToFilter;
            popUpFilter.value = false;
            break;
          case "priceLevel":
            placesToFilter = placesToFilter.filter((lead) => {
              if (!value) return lead;
              return lead.geojson.properties[key] == value;
            });
            placesFiltered.value = placesToFilter;
            popUpFilter.value = false;
            break;
          case "businessStatus":
            placesToFilter = placesToFilter.filter((lead) => {
              if (!value) return lead;
              if (lead.geojson.properties[key] !== "OPERATIONAL" && value !== "OPERATIONAL")
                return lead;
              return lead.geojson.properties[key] === value;
            });
            placesFiltered.value = placesToFilter;
            popUpFilter.value = false;
            break;
          case "types":
            placesToFilter = placesToFilter.filter((lead) => {
              return lead.geojson.properties[key].includes(value);
            });
            placesFiltered.value = placesToFilter;
            popUpFilter.value = false;
            break;
        }
      }
      popUpFilter.value = false;
    };

    const removeFilters = () => {
      placesFiltered.value = places.value;
      for (const [key, value] of Object.entries(selectedFilters.value)) {
        selectedFilters.value[key] = null;
      }
      countSelectedFilter.value = 0;
      handlePopUpFilter();
    };

    let popupHandle = ref(false); // Abre o popup

    const upExportar = () => {
      popupHandle.value = !popupHandle.value;

      setTimeout(() => {
        document.querySelector(".exportar").classList.add("exportar__animation--add");
      }, 0);
    };

    const confirmExport = async () => {
      switch (exportFromWhere.value) {
        case "1":
          if (checkList.value.length === 0) {
            return store.dispatch("map/handleAlert", {
              type: "error",
              message: "Selecione um lead",
              active: true,
            });
          }
          try {
            for (const check of checkList.value) {
              await userService.setLeadInPipedrive(placesFiltered.value[check].geojson);
            }
            store.dispatch("map/handleAlert", {
              type: "sucess",
              message: "Leads exportados com sucesso!",
              active: true,
            });

            popupHandle.value == "exportados";
            setTimeout(() => {
              popupHandle.value == false;
            }, 1000);
          } catch (e) {
            store.dispatch("map/handleAlert", {
              type: "error",
              message: "Ocorreu um erro ao adicionar os leads",
              active: true,
            });
          }
          break;
        // case 2:
        //   // new export
        //   break;
        default:
          store.dispatch("map/handleAlert", {
            type: "error",
            message: "Selecione a forma de exportação",
            active: true,
          });
          break;
      }
      popupHandle.value = false;
      // store.dispatch("map/handleAlert", {
      //   type: "sucess",
      //   message: "Leads exportados com sucesso!",
      //   active: true,
      // });
    };

    const cancel = () => {
      document.querySelector(".exportar").classList.add("exportar__animation--remove");
      setTimeout(() => {
        popupHandle.value = false;
      }, 1000);
    };

    const querySearch = (queryString) => {
      const types = new Set();
      if (queryString) {
        googleService.getLeadsByQuery(
          data.value.geometry.coordinates,
          Number(data.value.properties.totalKm),
          queryString,
          store
        ).then((data) => {
          places.value = data;
          places.value.forEach((lead) => {
            if (!lead.geojson.properties.types) return;
              lead.geojson.properties.types.forEach((type) => {
                types.add(type);
              });
          });
          types.forEach((type) => {
            DICIONARY_TYPES.forEach((dictionaryType) => {
              if (dictionaryType.value !== type) return;
              listAllTypes.value.push(dictionaryType);
            });
          });
          placesFiltered.value = places.value;

        }).catch((err) => {
          let mensagem = "Erro interno, por favor tente novamente.";
          if (err.response?.status === 429){
            mensagem = "O limite de pesquisas semanais foi atingido."
          } else if (err.response?.status === 401){
            mensagem = "Não autorizado."
          }
          const alertData = {
            type: "error",
            message: mensagem,
            active: true,
          };
          store.dispatch("map/handleAlert", alertData);
        });
        
      }
    };

    const createPin = async () => {
      for (const index of checkList.value) {
        const geometry = placesFiltered.value[index].geojson.geometry;
        const discriminator = "points";
        let properties = placesFiltered.value[index].geojson.properties;
        properties.style = "Marker";
        await addToFeatureCollection({ geometry, discriminator, properties });
      }
      store.dispatch("map/updateMarkerMenu");
    };

    const addToFeatureCollection = async (data) => {
      const featurePlace = await featureService.createFeature(
        data.geometry,
        data.discriminator,
        data.properties,
        true
      );
      await layerService.setFeatureInLayer(featurePlace, getFeatureCollection.value);
      const myFeaturePlace = new MyFeature(
        featurePlace.id,
        user.data.idCompany,
        featurePlace.geojson.geometry,
        featurePlace.geojson.properties,
        featurePlace.discriminator,
        featurePlace.properties
      );
      getFeatureCollection.value.addFeature(myFeaturePlace);
      const popupHtml = makePopup("Ponto único", myFeaturePlace);
      MapService.addMarker(
        myFeaturePlace.geometry.coordinates,
        popupHtml,
        "coordinates",
        myFeaturePlace
      );
      return true;
    };

    const getImg = (arrPhotos) => {
      arrPhotos.forEach((imgData) => {});
      return "#";
    };

    const handleCheckAllChange = (active) => {
      if (!active) return (checkList.value = []);
      checkList.value = placesFiltered.value.map((el, i) => {
        return i;
      });
    };

    const sortPlaces = (type, label) => {
      selectedOrder.value = label;
      const alertData = {
        type: "error",
        message: "Nenhum lead para ordenar",
        active: true,
      };
      if (placesFiltered.value.length === 0) return store.dispatch("map/handleAlert", alertData);
      switch (type) {
        case "a-z":
          placesFiltered.value.sort((a, b) =>
            a.geojson.properties.displayName.text.localeCompare(
              b.geojson.properties.displayName.text
            )
          );
          break;
        case "z-a":
          placesFiltered.value.sort((a, b) =>
            b.geojson.properties.displayName.text.localeCompare(
              a.geojson.properties.displayName.text
            )
          );
          break;
        case "highestPrice":
          placesFiltered.value.sort((a, b) => {
            if (a.geojson.properties.priceLevel === undefined) return 1;
            if (b.geojson.properties.priceLevel === undefined) return -1;
            // Comparando os preços
            return (
              CLASSIFICATION_PRICE_LEVEL[b.geojson.properties.priceLevel] -
              CLASSIFICATION_PRICE_LEVEL[a.geojson.properties.priceLevel]
            );
          });
          break;
        case "lowestPrice":
          placesFiltered.value.sort((a, b) => {
            if (a.geojson.properties.priceLevel === undefined) return 1;
            if (b.geojson.properties.priceLevel === undefined) return -1;
            // Comparando os preços
            return (
              CLASSIFICATION_PRICE_LEVEL[a.geojson.properties.priceLevel] -
              CLASSIFICATION_PRICE_LEVEL[b.geojson.properties.priceLevel]
            );
          });
          break;
        case "highestClassification":
          placesFiltered.value.sort(
            (a, b) => b.geojson.properties.rating - a.geojson.properties.rating
          );
          break;
        case "lowestClassification":
          placesFiltered.value.sort(
            (a, b) => a.geojson.properties.rating - b.geojson.properties.rating
          );
          break;
      }
    };

    const getTypeToView = (value) => {
      const result = DICIONARY_TYPES.find(type => type.value === value);
      if (!result) return 'Tipo não encontrado';
      return result.label;
    }

    watch(placesFiltered, () => {
      loadingPlaces.value = true;
      store.dispatch(
        "map/changeLeads",
        placesFiltered.value.map(
          (feature) =>
            new MyFeature(
              Math.random() * 1000,
              user.data.idCompany,
              feature.geojson.geometry,
              feature.geojson.properties,
              feature.discriminator
            )
        )
      );
    });
    let exportFromWhere = ref("1");

    return {
      getAside: computed(() => store.getters["map/getAside"]),
      getPointInter: computed(() => store.getters["map/getPointInter"]),
      getLegendPosition: computed(() => store.getters["map/getLegendPosition"]),
      getAlert: computed(() => store.getters["map/getAlert"]),
      getFeatureCollection,
      places,
      updateFeatures,
      inputVModel,
      loadingPlaces,
      buttonRef,
      buttonRefOrder,
      checkList,
      popUpFilter,
      popUpOrder,
      mainCheckBox,
      popupHandle,
      exportFromWhere,
      selectedFilters,
      selectedOrder,
      countSelectedFilter,
      placesFiltered,
      sortPlaces,
      cancel,
      getImg,
      handleCheckAllChange,
      getPriceLevel,
      confirmExport,
      createPin,
      onClickOutsideOrder,
      handlePopUpFilter,
      upExportar,
      querySearch,
      applyFilters,
      removeFilters,
      getTypeToView,
      DROPDOWN_RATING,
      DROPDOWN_PRICE,
      DROPDOWN_STATS,
      STYLE_POINTS_RESULT,
      STYLE_POINTS,
      SETOR_CENSITARIO,
      MUNICIPIO,
      RENDA,
      DOMICILIOS,
      RESIDENTES,
      PIB,
      POP_CRIANCAS,
      POP_IDOSOS,
      POP_JOVENS,
      POP_ADULTOS,
      MORTES_HOMENS,
      MORTES_MULHERES,
      TOTAL_MORTES,
      RENDA_HOMENS,
      RENDA_MULHERES,
      DICIONARY_TYPES,
      user,
      store,
      pointsData,
      data,
      radio,
      isMarker,
      loading,
      isPin,
      listAllTypes,
      formatDate,
      getRevenueData,
      getDataPoints,
      getPoints,
      getTotalPoints,
      currencyFormatter,
      miFormatter,
      floatFormatter,
      capitalizeStrings,
      goToMaps,
      getTotalArea,
    };
  },
  data() {
    const visualizar = openEyes;
    return {
      hideTabIcons: true,
      activeNames: "actived",
      activeTab: "first",
      legendComparator: "",
      radioData: ref("ranking"),
      check: false,
      visualizar,
      styleAsideBar: "",
      asideBoolean: false,
      googleData: null,
    };
  },
  watch: {
    getAlert: {
      handler(newVal, oldVal) {
        if (newVal) this.$refs.alertComponent.showCustomAlert({ ...newVal });
      },
    },
    getAside: {
      async handler(newVal, oldVal) {
        this.activeTab = "first"; // Ao clicar no pin, a tab ativa deve ser a 'first'
        if (newVal.hideTabIcons == true)
          this.hideTabIcons = true; // Esconde o tab caso for Ponto Único
        else this.hideTabIcons = false;
        if (newVal == "closePopupTrue")
          this.closeAsideBar(); // Se o remove do ballon for ativado, fecha o slidebar
        else this.handlerFunction(newVal, oldVal);
      },
    },
    updateFeatures: {
      handler(newVal, oldVal) {
        this.store.dispatch("map/updateMarkerMenu", newVal);
      },
    },
    getLegendPosition() {
      //getLegendPosition() faz comparações entre os componentes que estão abertos, para evitar que os asides se sobreponham ao abrir a "Área de estudo" e o aside de "Pontos de interesse".

      if (this.getLegendPosition[0] === true) {
        let isItOverlapping = this.getLegendPosition[1].split("px")[0];
        if (this.legendComparator == "") {
          this.legendComparator = isItOverlapping;
        } else if (this.legendComparator > isItOverlapping) {
          this.closeAsideBar();
        } else {
          this.legendComparator = isItOverlapping;
        }
      }
    },
    data: {
      async handler(newVal, oldVal) {
        this.showAside();
      },
    },
    radio: {
      async handler(newVal, oldVal) {
        this.data = await this.getDataPoints(this.data);
        if (this.data.properties.discriminator !== "points") {
          this.data = await this.getRevenueData(this.data);
        } else {
          this.isPin = true;
        }
      },
    },
  },
  methods: {
    remove() {
      this.store.dispatch("map/changeDeleteArea", this.getAside); // Ativa a função de delete no componente MapMenuLayer.vue
      this.closeAsideBar();
    },
    expandiCard(card) {
      const cardClicked = document.getElementById(card);
      let change = document.querySelector(".change__slide");

      let tretre;
      cardClicked.classList.forEach((obj) => {
        if (obj == "change__slide") tretre = true;
      });
      cardClicked.classList.add("change__slide");

      if (tretre) change.classList.remove("change__slide");
      else if (change)
        change.classList.remove("change__slide"), cardClicked.classList.add("change__slide");
      else if (!change) cardClicked.classList.add("change__slide");
    },
    lowerCases(email) {
      return email.toLowerCase();
    },
    formatCNPJ(cnpj) {
      if (cnpj)
        cnpj = cnpj.replace(/\D/g, "");
      return cnpj
        ? `${cnpj.slice(0, 2)}.${cnpj.slice(2, 5)}.${cnpj.slice(5, 8)}/${cnpj.slice(
            8,
            12
          )}-${cnpj.slice(12, 14)}`
        : null;
    },
    formatFone(ddd, fone) {

      if ((ddd == "-" && fone == "-") || (!fone)) {
        return "Não informado";
      } else {
        if (fone.length == 9) {
          fone = fone.replace(/\D/g, "");
          return `(${ddd}) ${fone.slice(0, 5)}-${fone.slice(5, 9)}`;
        } else if (fone.length == 8) {
          fone = fone.replace(/\D/g, "");
          return `(${ddd}) ${fone.slice(0, 4)}-${fone.slice(4, 8)}`;
        } else {
          fone = fone.replace(/\D/g, "");
          return `(${fone.slice(0, 2)}) ${fone.slice(2, 6)}-${fone.slice(6, 10)}`;
        }
        
      }
    },
    formatCNAE(cnae) {
      return `${cnae.slice(0, 2)}.${cnae.slice(2, 4)}-${cnae.slice(4, 5)}/${cnae.slice(5, 7)}`;
    },
    async handlerFunction(newVal, oldVal) {
      if (newVal.properties.discriminator === "study") {
        this.data = newVal;
        this.data.properties.nome = capitalizeStrings(this.data.properties.nome);
        this.data.properties.totalKm = await this.getTotalArea(this.data);
        this.getPoints(this.data);
      } else {
        this.data = await this.getDataPoints(newVal);
        if (this.data.properties.discriminator !== "points") {
          this.data = await this.getRevenueData(this.data);
        } else {
          this.isPin = true;
        }
      }
      !newVal.isActive ? this.closeAsideBar() : null;
    },
    showData() {
      this.check = !this.check;
    },
    resetVariaveis() {
      this.places = [];
      this.placesFiltered = [];
      this.selectedFilters = {
        rating: null,
        priceLevel: null,
        businessStatus: null,
        type: null,
      };
      this.countSelectedFilter = 0;
      this.selectedOrder = null;
      this.listAllTypes = [];
      this.inputVModel = "";
      this.checkList = [];
      this.mainCheckBox = false;
    },
    showAside() {
      this.resetVariaveis();
      this.pointsData.groups = [];
      this.loading = true;
      this.styleAsideBar = STYLE_POINTS_RESULT[this.data.properties.discriminator];
      this.data.properties.discriminator !== "study"
        ? (this.isMarker = true)
        : (this.isMarker = false);
      this.asideBoolean = true;
    },
    closeLegendPosition() {
      if (this.getPointInter) {
        //Se o aside de pontos de inteeresse estiver aberto
        this.store.dispatch("map/changeLegendPosition", [true, "320px"]);
      } else {
        //Se o aside de pontos de interesse estiver fechado
        this.store.dispatch("map/changeLegendPosition", [false]);
      }
    },
    closeAsideBar() {
      this.resetVariaveis();
      MapService.handleVisibilityLayer(LEADS_LAYER, "none");
      MapService.deselectPins();
      this.radio = MUNICIPIO;
      const { features } = this.getFeatureCollection;
      features.forEach((feature) => {
        let elementId = document.getElementById(feature.id);
        let elementIdOptions = document.getElementById(`${feature.id}_Options`);
        if (elementId === null || elementIdOptions === null) {
          return;
        } else {
          elementId.style.color = "var(--dm_preto_00)";
          elementIdOptions.style.backgroundImage = `url(${optionsBlack})`;
        }
      });

      let elementAside = document.getElementById("areaDeEstudo");

      if (elementAside) {
        //Caso o elemento não tenha sido captura, para evitar erros no console, ele não executa.
        elementAside.classList.add("global-asideBarAnimation--off");
      }
      setTimeout(() => {
        this.asideBoolean = false;
        this.loading = true;
      }, 500);
    },
  },
};
</script>

<style>
[flex-row] {
  display: flex;
}

.area-de-estudo dm-card-estatico {
  max-width: 100%;
  min-width: 100%;
  height: 100%;
}

.area-de-estudo hr {
  border-color: var(--dm_cinza_03);
}

.maps__link {
  cursor: pointer;
  text-decoration: none;
  display: flex;
  gap: 8px;
  align-items: flex-start;
  flex-wrap: nowrap;
}

.maps__details {
  display: flex;
  justify-content: space-between;
}

.demo-tabs {
  position: relative;
  width: 100%;
}

.demo-tabs--delete {
  position: absolute;
  right: 0px;
  top: 5px;
  cursor: pointer;
  z-index: 1;
}

.selected_filter {
  color: #0567ff;
}

.content_item {
  display: flex;
  flex-direction: row;
  justify-items: center;
  gap: 16px;
}

.content_item span {
  height: fit-content;
}

.demo-tabs .el-tabs--card > .el-tabs__header .el-tabs__nav,
.demo-tabs .el-tabs--card > .el-tabs__header .el-tabs__item {
  border: none !important;
}

.demo-tabs .el-tabs--card > .el-tabs__header {
  border-bottom: 2px solid var(--dm_cinza_03);
}

.demo-tabs .el-tabs__item,
.demo-tabs .el-tabs--top.el-tabs--card > .el-tabs__header .el-tabs__item:nth-child(2) {
  padding: 0;
}

.demo-tabs .is-active [WebSite] {
  background-image: url("../../../../src/assets/icons/blueIcons/WebSiteBlue.svg");
}

.demo-tabs .is-active [Warning] {
  background-image: url("../../../../src/assets/icons/blueIcons/WarningBlue.svg");
}

/* Receita Federal */
.receita p {
  color: var(--dm_cinza_01);
  font-size: 16px;
}

.receita__h3 {
  margin-bottom: 10px;
}

.receita .el-collapse-item__header {
  font-family: var(--roboto-slab);
  font-weight: 500;
  font-size: 20px;
  color: var(--dm_preto_00);
}

.receita .el-collapse-item__header {
  padding: 24px 0;
}

.receita .el-collapse-item__content {
  padding-bottom: 8px;
}

/*  */

.lead__btn--dropdown {
  width: 220px;
}

.el-popper {
  border: 1px solid var(--dm_cinza_03) !important;
  border-radius: 6px;
}

.hideTab .el-tabs__header,
.hideTab .demo-tabs--delete,
.hideTab .el-tabs__header.is-top {
  display: none !important;
}

/*  */

.slide__card .el-checkbox__label {
  padding: 0;
}
</style>

<style scoped>
/* Popup Exportar */
.exportar {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  transition: 1s;
  opacity: 0;
  z-index: 2;
}

.exportar__content {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  position: relative;
  background: var(--dm_creme_00);
  padding: 20px;
  border-radius: 6px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
}
.exportar__animation--add {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-name: exportar-add;
}

@keyframes exportar-add {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.exportar__animation--remove {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-name: exportar-remove;
}

@keyframes exportar-remove {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/*  */

.area-de-estudo {
  position: fixed;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 10px;
  border-left: 1px solid var(--dm_cinza_03);
  top: 50px;
  left: 312px;
  gap: 10px;
  width: 392px;
  height: calc(100vh - 50px);
  background: var(--dm_creme_02);
  z-index: 1;
}

.area-estudo__container-close {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  transition: 0.2s;
}

.area-estudo__container-close {
  position: sticky;
  top: 0;
}

.area-estudo__container-close:hover {
  opacity: 0.7;
  transition: 0.2s;
}

.area-estudo__cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
}

/*  */
.auto__complete--icon {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
}

.auto__complete--icon dm-icon {
  position: absolute;
  right: 10px;
}

/* slide */

.lead {
  margin-bottom: 40px;
  border-bottom: 1px solid var(--dm_cinza_03);
}

.lead__btn {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  align-items: center;
  margin: 10px 0;
}

.lead__content__filters {
  display: flex;
  gap: 5px;
}

.lead_order {
  width: 207px;
}

.close_filters {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.lead__filter {
  position: absolute;
  background-color: var(--dm_creme_02);
  top: 100px;
  right: 0;
  z-index: 2;
  width: 298px;
}

.lead__filter,
.lead_order {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  border: 1px solid var(--dm_cinza_03);
  border-radius: 6px;
}

.order_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.order_text {
  color: var(--dm_preto_00);
}

.order_btn {
  margin-bottom: 10px;
}

.lead__btns {
  display: flex;
}

.vertical {
  height: 16px;
  width: 2px;
  background: var(--dm_cinza_03);
  border: none;
}

.horizontal {
  width: 100%;
  height: 1px;
  background: var(--dm_cinza_03);
  border: none;
}

.lead * {
  transition: 0.5s !important;
}

.slide {
  display: flex;
  gap: 20px;
}

.slide__handle {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: calc(50% - 15px);
  bottom: -10px;
  height: 20px;
  background: var(--dm_creme_02);
  width: 20px;
  border-radius: 50%;
  border: 1px solid var(--dm_cinza_01);
  transition: 0.2s;
  z-index: 1;
}

.slide__handle:hover {
  background: var(--dm_creme_01);
  transition: 0.2s;
}

.slide__handle dm-icon {
  width: 10px;
  height: 10px;
  padding: 6px;
}

.slide__handle:hover dm-icon {
  opacity: 0.9;
  transition: 0.2s;
}

.slide__card {
  position: relative;
  gap: 20px;
  padding: 20px 0px;
  border-top: 1px solid var(--dm_cinza_03);
}

.slide__content {
  position: relative;
  display: flex;
  gap: 20px;
  /* padding-right: 86px; */
}

.slide__content--ctn {
  display: flex;
  gap: 6px;
}

.slice__img {
  position: absolute;
  right: 0;
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 6px;
}

.slide__star--assessment {
  width: 15px;
  height: 15px;
  object-fit: contain;
}

.slide__links {
  color: var(--dm_azul_00);
}

.slide__flex {
  display: flex;
}

.lead_hide {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  color: var(--dm_cinza_01);
}

.change__slide .lead__info {
  margin-left: 6px;
}

.change__slide {
  flex-direction: column-reverse;
}

.change__slide .lead_hide {
  position: relative;
  pointer-events: initial;
  opacity: 1;
}

.change__slide .slide__content {
  padding-right: 0;
}

.change__slide .slide__card {
  flex-direction: column-reverse;
  justify-content: space-between;
}

.change__slide .slide__animation {
  /* padding-top: 150px; */
  width: 100%;
}

.change__slide .slide__handle {
  transform: rotate(180deg);
}

.change__slide .slice__img {
  width: 100%;
  height: 140px;
}

/*  */

.background-container {
  width: 100%;
  height: 100%;
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loading-spinner {
  width: 35px;
  height: 35px;
  border: 5px dashed black;
  border-radius: 50%;
  animation: spin 3s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
