<template>
  <section>
    <div class="area">
      <div class="area_titulo">
        <img src="../assets/icons/blueicons/pin.svg" />
        <h5>Pontos de interesse</h5>
      </div>
      <button class="area_btn" @click="openPopUp()">+</button>
    </div>
    <div style="margin: 3% 0 0 5%" id="mapMenuMarker">

      <!-- PONTO ÚNICO -->
      <div v-for="(marker, index) in markers" :key="index" class="container__marker">
        <div class="global-div_marker">
          <img src="../assets/point_color_blue.svg" alt="Cor dos pontos" width="15" height="15" />
          <div class="global__content_marker">
            <div
              class="global-insteresting_points"
              @click="flyToItem(marker)"
              :id="marker.id"
              @mouseenter="handleMouseOver"
              @mouseleave="hidePopUp"
            >
              {{ marker.properties.nome ?? marker.properties.displayname ?? 'Ponto sem nome' }}
            </div>
          </div>
        </div>
        <div class="actions">
          <el-checkbox
            :checked="marker.properties.visibility"
            @change="changeCheckMarker(marker)"
            class="checkbox_marker"
          />
          <button class="global-ballon">
            <div :id="`${marker.id}_Options`" class="options" />
            <div class="global-ballon__content">
              <div class="global-ballon__left">
                <div class="global-ballon__buttons">
                  <dm-icon Warning @click="handleChangeAside(marker)" />
                  <dm-icon Delete @click="deleteMarker(marker)" />
                </div>
              </div>
            </div>
          </button>
        </div>
      </div>

      <!-- GRUPO DE PONTOS -->
      <el-checkbox-group v-model="handlecCheckbox">
        <div class="container__marker" v-for="(groups, index) in groupsPoints" :key="index">
          <div class="global-div_marker">
            <div :style="`background-color: var(${groups.color[0]})`" class="group_point" />
            <div class="global__content_marker">
              <div
                class="global-insteresting_points"
                @mouseenter="handleMouseOver"
                @mouseleave="hidePopUp"
              >
                {{ groups.name }}
                <!--  <span class="after_text">
                    {{
                    `(${Object.entries(groups)[0][1]})`
                    }}
                  </span> -->
              </div>
            </div>
          </div>
          <div class="actions">
            <el-checkbox
              @change="handleActiveGroup($event, Object.entries(groups)[0][0])"
              :label="Object.entries(groups)[0][0]"
              class="checkbox_marker"
            >
              <span><!-- O span evita que apareça o conteúdo do label --></span>
            </el-checkbox>

            <button class="global-ballon">
              <div :id="`${groups.id}_Options`" class="options" />
              <div class="global-ballon__content">
                <div class="global-ballon__left">
                  <div class="global-ballon__buttons">
                    <dm-icon Warning @click="handleSelectGroup(Object.entries(groups)[0][0])" />
                    <dm-icon
                      Delete
                      @click="
                        removePoint(Object.entries(groups)[0][0]),
                          [groups.color[1], groups.borderColor[1]]
                      "
                    />
                  </div>
                </div>
              </div>
            </button>
          </div>
        </div>
      </el-checkbox-group>
    </div>
    <hr />
    <MapPopup />
    <MapSideBarTable />
    <PopUpHover ref="popup" :longerText="19" />
  </section>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { FeatureService } from "../services/feature-service";
import { LayerService } from "../services/layer-service";
import MapService from "../services/map-service";
import PopUpHover from "../../../components/PopUpHover.vue";
import EditFeatures from "@/components/editFeatures.vue";
import { GROUP_POINTS_COMPANY, GROUP_POINTS_COMPANY_2, POINTS_SOURCE, TRADUCTOR } from "../helpers/constants";
import MapSideBarTable from "./MapSideBarTable.vue";
import MapPopup from "./MapPopup.vue";
import { handleSelectedFeatures } from "../helpers/transforms";

export default {
  setup() {
    const store = useStore();
    const searchArea = ref(false);
    const getFeatureCollection = computed(() => store.getters['map/getFeatureCollection']);
    const markers = ref(getFeatureCollection.value.getMarkers());
    const user = store.getters["auth/getUser"];
    const featureService = new FeatureService(user);
    const layerService = new LayerService(user);
    const groupsList = computed(() => store.getters["map/getGroupList"]);
    const groupsPoints = ref([]);
    const layerCompanySelected = ref(false);
    const isItMarker = computed(() => store.getters["map/getIsItMarker"])
    let hasDataCompany = { group_zelo: false, competitors: false };

    onMounted(() => {
      groupsPoints.value = groupsList.value;
    });

    const handleChangeAside = async (feature) => {
      store.dispatch("map/changeLegendPosition", [true, "392px"]);
      handleSelectedFeatures(feature.id, getFeatureCollection.value.features);
      MapService.flyto(feature);
      MapService.selectPin(feature.geometry.coordinates);
      store.dispatch("map/changeAside", { ...feature, isActive: true, hideTabIcons: true});
      store.dispatch("map/changeIsMarker", true);
    };

    const handleActiveGroup = (event, nameGroup) => {
      console.log("nome do grupo", nameGroup);
      const isCompanyLayer = GROUP_POINTS_COMPANY_2[user.data.idCompany].filter(
        (group) => group.discriminator === nameGroup
      );
      isCompanyLayer.length > 0 && event
        ? addCompanyGroupsLayer(nameGroup)
        : MapService.handleGroupLayer(nameGroup, POINTS_SOURCE, false);
    };

    const addCompanyGroupsLayer = async (nameGroup) => {
      if (!hasDataCompany[nameGroup]) {
        let features = await featureService.getFeatureByDiscriminator(nameGroup, user.token, false);
        MapService.updateSource(
          { type: "FeatureCollection", features: features },
          `group-company-${nameGroup}`
        );
        store.dispatch("map/changeCompanyGroups", { [nameGroup]: features });
        hasDataCompany[nameGroup] = true;
      }
      MapService.handleGroupLayer(nameGroup, "", true);
    };

    const openPopUp = () => {
      store.dispatch("map/changeMapPopup", true);
    };

    const removePoint = (item) => {
      let groups = [];
      const ArrString = groupsList.value.map((group) => group.discriminator);
      if (ArrString.includes(item)) {
        MapService.notVisibleGroup(item);
        groups = groupsList.value.filter((group) => group.discriminator !== item);
        store.dispatch(
          "map/changeGroupList",
          groups.map((el) => el.discriminator)
        );
        layerService.updateLayer(
          getFeatureCollection.value,
          groups.map((el) => el.discriminator),
          getFeatureCollection.value.dadosGerais
        );
      }
    };

    return {
      searchArea,
      isItMarker,
      markers,
      user,
      store,
      mapState: computed(() => store.getters["map/getMapActualState"]),
      mapLoader: computed(() => store.getters["map/getMapLoader"]),
      mapUpdateLengthgroups: computed(() => store.getters["map/getLengthGroups"]),
      mapPopupHandler: computed(() => store.getters["map/getMapPopup"]),
      getUpdateMarkerMenu: computed(() => store.getters["map/getUpdateMarkerMenu"]),
      groupsList,
      getFeatureCollection,
      featureService,
      layerCompanySelected,
      handleCheckbox: false,
      layerService,
      groupsPoints,
      TRADUCTOR,
      hasDataCompany,
      MapService,
      handleChangeAside,
      handleActiveGroup,
      openPopUp,
      removePoint,
    };
  },
  data() {
    return {
      openSideBar: false,
      selectedGroup: [],
      handlecCheckbox: [],
    };
  },

  components: { EditFeatures, MapSideBarTable, MapPopup, PopUpHover },
  watch: {
    groupsList: {
      handler(newVal, oldVal) {
        this.handlecCheckbox = [];
        this.groupsPoints = newVal;
        newVal.forEach((group) => {
          MapService.removeLayer(`group-${group.discriminator}`);
        });
        MapService.addGroups(newVal);
      },
    },
    mapState: {
      handler(newVal, oldVal) {
        if (newVal === "createMarker") {
          this.markers = this.getFeatureCollection.getMarkers();
        }
      },
    },
    getUpdateMarkerMenu: {
      handler(newVal, oldVal) {
        this.markers = this.getFeatureCollection.getMarkers();
      },
    },
  },
  methods: {
    hidePopUp(event) {
      this.$refs.popup.hidePopup(event);
    },
    handleMouseOver(event) {
      this.$refs.popup.showPopup(event);
    },
    activeLegend() {
      //Envia a quantidade de pixels que a legenda deve se mover para direita (a quantidade de pixels é relativa a "width" do componente)
      this.store.dispatch("map/changeLegendPosition", [true, "324px"]);
    },
    handleSelectGroup(nameGroup) {
      const isCompanyLayer = GROUP_POINTS_COMPANY_2[this.user.data.idCompany].filter(
        (group) => group.discriminator === nameGroup
      );
      isCompanyLayer.length > 0
        ? (this.store.dispatch("map/updateGroups", { activeCompany: true, nameGroup }),
          this.activeLegend())
        : MapService.getFeaturesActive(nameGroup);
      this.store.dispatch("map/changePointInter", true);
    },
    flyToItem(feature) {
      MapService.flyto(feature);
    },
    updateMarkers() {
      this.markers = this.getFeatureCollection.getMarkers();
    },
    changeCheckMarker(feature) {
      feature.properties.visibility = !feature.properties.visibility;
      MapService.handleMarkerOfLayer(feature);
    },
    deleteMarker(feature) {
      const featureService = new FeatureService(this.user);
      featureService.deleteFeatureById(feature.id);
      this.getFeatureCollection.removeFeature(feature);
      MapService.deleteMarker(feature.geometry.coordinates);
      this.updateMarkers();
    },
  },
};
</script>

<style>
#mapMenuMarker .el-checkbox__inner {
  width: 15px !important;
  height: 15px !important;
}

#mapMenuMarker .el-checkbox__inner::after {
  width: 4px;
  height: 12px;
}

#mapMenuMarker .el-checkbox__label {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
</style>

<style scoped>
.area {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

hr {
  border: 1px solid #cacaca;
  margin-top: 10px;
}

.area_titulo {
  display: flex;
  gap: 10px;
}

.area_btn {
  display: flex;
  color: #0567ff;
  align-items: center;
  justify-content: center;
  background: var(--dm_creme_00);
  border-radius: 50px;
  border: 2px solid #0567ff;
  width: 20px;
  height: 20px;
}

.options {
  display: flex;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 20px;
  height: 20px;
  background-image: url("../../../assets/icons/blackicons/Options.svg");
}

.area_btn:hover {
  background: var(--dm_azul_01);
  color: var(--dm_creme_02);
  border: var(--dm_azul_01);
}

.global-insteresting_points {
  color: var(--dm_preto_00);
  margin-left: 10px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.after_text {
  font-weight: 300;
  font-size: 12px;
}

.group_point {
  width: 16.32px;
  height: 15px;
  border-radius: 8px;
}

.actions {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 2px;
}

.checkbox_marker {
  width: 20px;
}

.icons_btn {
  display: flex;
}

.container__marker {
  display: flex;
  justify-content: space-between;
  padding: 0px 10px 5px 10px;
}

.container__marker:hover {
  background-color: var(--dm_cinza_05);
}
</style>
