class State {
  states = [
    'initial',
    'clickMarker',
    'clickRadius',
    'popupMarker',
    'popupRadius',
    'createMarker',
    'createRadius',
    'createPolygon'
  ];

  alphabet = [
    'clickBtnRadius',
    'clickBtnMarker',
    'clickMap',
    'confirmar',
    'cancelar'
  ];

  transictions = {
    'initial': { 'clickBtnRadius': 'clickRadius', 'clickBtnMarker': 'clickMarker', 'confirmar': 'createPolygon' },
    'clickMarker': { 'clickMap': 'popupMarker', 'clickBtnMarker': 'initial', 'clickBtnRadius': 'initial' },
    'clickRadius': { 'clickMap': 'popupRadius', 'clickBtnRadius': 'initial', 'clickBtnMarker': 'initial' },
    'popupMarker': { 'confirmar': 'createMarker', 'cancelar': 'initial', 'clickBtnMarker': 'initial', 'clickBtnRadius': 'initial' },
    'popupRadius': { 'confirmar': 'createRadius', 'cancelar': 'initial', 'clickBtnRadius': 'initial', 'clickBtnRadius': 'initial' },
  };

  finalStates = ['createRadius', 'createMarker', 'createPolygon'];

  initialState = 'initial';

  actualState = this.initialState;

  compute(alphabet) {
    try {
      this.actualState = this.transictions[this.actualState][alphabet];
    } catch {
      console.error(`Transição invalida para o estado ${this.actualState} com essa entrada ${alphabet}. Reseting state.`);
      this.finishCompute();
    }
  }

  finishCompute() {
    this.actualState = this.initialState;
  }

  getActualState() {
    return this.actualState;
  }
}
import { ESTADO } from '../helpers/constants';
export default {
  map: {
    isMarker: false,
    state: new State(),
    updateMarkerMenu: false,
    visualizationZoom: undefined,
    visualization: ESTADO,
    legend: null,
    loader: false,
    aside: false,
    groupsList: [],
    groupsCompany: [],
    legendPosition: [false], //Altera a posição da legenda de acorddo com o Aside aberto
    pointInter: false, //Verifica se o aside de pontos de interesse está aberto
    mapPopup: [false], //Ativa e desativa o popup de pontos de interesse
    groupList: [],
    dataList: [], // Itens selecionados em Dados Gerais
    popupPipedrive: false, // Abre o Popup para adicionar token do pipedrive
    leads: [],
    deleteArea: undefined, // Deleta a área de estudo
    alert: {type: '', message: '', active: false},
  },
  featureCollection: {},
};
