import axios from 'axios';
import { API_URL } from "@/modules/bi/helpers/constants";
export class UserService {

	constructor(userData) {
		this.userData = userData;
		this.url = API_URL
	}

	async getUserById() {
		let response;
		try{
      response = await axios.get(
        `${this.url}/users/${this.userData.data.id}`,
        {
          headers: {
            Authorization: `Bearer ${this.userData.token}`
          }
        }
      );
			return response.data
    } catch (e) {
      console.log(e);
    }
	}

	async createUser(newUser) {
		let response;
		try{
      response = await axios.post(
        `${this.url}/users`,
				{
					idCompany: newUser.idCompany,
					_name: newUser.name,
					_email: newUser.email,
					role: newUser.role,

				},
        {
          headers: {
            Authorization: `Bearer ${this.userData.token}`
          }
        }
      );
			return response.data
    } catch (e) {
      console.log(e);
    }
	}

  async updatePipedriveUser(tokenPipedrive) {
    console.log(tokenPipedrive, this.userData.data.idCompany, this.userData.data.name, this.userData.data.email)
		try{
      let response = await axios.put(
        `${this.url}/users/${this.userData.data.id}`,
				{
          idCompany: this.userData.data.idCompany,
					name: this.userData.data.name,
					email: this.userData.data.email,
          pipedriveToken: tokenPipedrive,
				},
        {
          headers: {
            Authorization: `Bearer ${this.userData.token}`
          }
        }
      );
      console.log(response);
			return response.data
    } catch (e) {
      console.log(e);
    }
	}

  async setLeadInPipedrive(dataLead) {
		const toPost = {
      name: dataLead.properties.displayName.text,
      phones: [],
      emails: [],
    }
    if (dataLead.properties.nationalPhoneNumber) toPost.phones.push(dataLead.properties.nationalPhoneNumber);
    if(dataLead.properties.email) toPost.emails.push(dataLead.properties.email);
    try{
      let response = await axios.post(
        `${this.url}/pipedrive/pearson/${this.userData.data.pipedriveToken}`,
				toPost,
        {
          headers: {
            Authorization: `Bearer ${this.userData.token}`
          }
        }
      );
			return response.data
    } catch (e) {
      throw e;
    }
	}
}
