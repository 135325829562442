/* eslint-disable import/prefer-default-export */
import router from './router';
import store from './store';
import authMiddleware from './modules/auth/authMiddleware';

const registerModule = (name, module) => {
  if (module.store) {
    store.registerModule(name, module.store);
  }

  if (module.router) {
    module.router(router);
  }
};

export const registerModules = (modules) => {
  
  Object.keys(modules).forEach((moduleKey) => {
    const module = modules[moduleKey];
    registerModule(moduleKey, module);
  });
  router.options.routes.forEach((route) => {
    if (route.meta.requiresAuth) {
      Object.assign(route.meta, { requiresAuth: true, middleware: authMiddleware });
    }
  });
};
