<template>
  <div class="container_bg">
    <div class="container flex" :style="{ 'margin-right': cnaeMessage ? '310px' : '' }">
      <h5>{{ title }}</h5>
      <div class="flex">
        <label>{{subtitle}}</label>
        <a href="mailto:beta@datamachina.com.br." v-if="cnaeMessage">beta@datamachina.com.br</a>
        <el-input
          v-if="buttonConfirm"
          type="email"
          v-model="sendEmail"
          style="width: 400px"
          placeholder="Digite o seu e-mail"
          class="flex-input"
        />
      </div>
      <div style="display: flex; gap: 20px" class="container-botoes">
        <el-button
          v-if="buttonConfirm"
          @click="sendEmailPassword(sendEmail)"
          type="primario"
          class="form__button btn_confirmar"
        >
          Confirmar
        </el-button>
        <el-button
          v-if="buttonConfirm"
          @click="ClosePopUpButton"
          type="primario"
          class="form__button btn_confirmar secundario"
        >
          Cancelar
        </el-button>
        <el-button
          v-else
          @click="ClosePopUpButton"
          type="primario"
          class="form__button btn_confirmar"
        >
        ok</el-button
      >
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
export default {
  name: "ErrorMessages",
  props: {
    title: String,
    subtitle: String,
    buttonConfirm: Boolean,
    sendEmailPassword: Function,
    ClosePopUpButton: Function,
    cnaeMessage: Boolean
  },
  setup() {
    const sendEmail = ref("");
    return {
      sendEmail,
    };
  },
}
</script>

<style scoped>
.container_bg {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: rgb(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
.flex {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 5px;
}
@media screen and (max-width: 560px) {
  .flex-input {
    width: 275px !important;
  }
  .container-botoes {
    margin-left: 20px;
  }
  .flex > h5 {
    margin: 0 auto;
  }
}
.container {
  gap: 10px;
  width: max-content;
  max-width: 500px;
  height: max-content;
  padding: 20px;
  background: var(--dm_creme_02);
  display: flex;
  border-radius: 6px;
}
.btn_confirmar {
  margin: 15px 0;
  background: var(--dm_azul_00);
  width: max-content;
  height: max-content;
  padding: 10px 20px;
}
.secundario{
  background: var(--dm_creme_02);
  color: var(--dm_azul_00) !important;
  border: solid 2px var(--dm_azul_00);
}
</style>