<template>
    <div class="container__PopUp--Pin">
        <h6>{{ name }}</h6>
		<p>{{ city }}, {{ state }}</p>
        <p>{{  lat }}, {{ lng }}</p>
    </div>
</template>

<script>
import { floatFormatter } from '../helpers/formatters';

export default {
    name: "MapMarkerPopup",
		props: ["name", "city", "state", "coordinates"],

		setup(props){
			const lat = floatFormatter(props.coordinates['lat']);
			const lng = floatFormatter(props.coordinates['lng']);
			return { lat, lng };
		}
};
</script>

<style scoped>
/* .container__PopUp--Pin {
	height: 100%;
	width: 100%;
	background-color: var(--dm_creme_02);
	border-radius: 6px 6px 0px 0px;
	border-bottom: 7px solid var(--dm_amarelo_01);
} */

</style>