//index map

const Module = () => import('../ModuleMap.vue');
const MapView = () => import('../views/MapView.vue');

const moduleRoute = {
  name: 'Map',
  path: '/map',
  component: Module,
  children: [
    {
      name: 'MapBase',
      path: '',
      component: MapView,
    },
  ],
  meta: {
    requiresAuth: true,
  },
};

export default (router) => {
  router.addRoute(moduleRoute);
};
