<template>
  <div class="container__mapLegend" ref="legendRef">
    <el-collapse v-model="activeName" accordion>
      <el-collapse-item title="Legenda" name="1">
        <div class="container-ponto-area">
          <p><img src="../../map/assets/pin_basico_azul.svg" alt="Pin" style="margin-right: 5px; width: 16px;">Ponto único</p>
          <p><img src="../../map/assets/rectangle_icon.svg" alt="ícone" style="margin-right: 5px;"> Área de estudo</p>
        </div>
        <div class="hr-div"></div>
        <p style="margin-bottom: 5%">{{ property }}</p>
        <div>
          <div
            class="container__input--legend"
            v-for="item in data"
            :key="item.id"
            v-if="showLegend"
          >
            <input
              type="checkbox"
              name="legenda"
              v-model="item.activated"
              :value="item.id"
              @change="handlerLegend(item)"
              checked
            />
            <span
              class="block__color"
              :style="{ 'background-color': item.color }"
            ></span>
            <span style="text-align: initial; width: 100%; height: max-content;">
              <div v-if="item.propertyAux === CLASSES">
                {{  item.legenda }}
              </div>
              <div v-else>
                {{formatter(item.minValue, item.valueType) }} - {{formatter(item.value, item.valueType)}}
              </div>
            </span>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>
  
<script>
import { ref, computed, watch } from "vue";
import { useStore, mapGetters } from 'vuex';
import { formatter } from "../helpers/formatters";
import MapService from "../services/map-service";
import { 
  PIB, RENDA, DOMICILIOS, RESIDENTES, CLASSES, CLASSES_LEGENDA,POP_CRIANCAS, POP_JOVENS,
  POP_ADULTOS, POP_IDOSOS, MORTES_HOMENS, MORTES_MULHERES, TOTAL_MORTES, MORTES_DOMICILIOS
} from "../helpers/constants";

export default {
  name: "MapComponentLegend",
  props:  {
      showLegend: {
        type: Boolean,
        required: true,
      }
  },
  computed: { ...mapGetters("map", ["getLegend"]) },
  setup() {
    const store = useStore();
    const activeName = ref("1");
    let legendRef = ref(null);
    
    const activePosition = computed(() => store.getters['map/getLegendPosition']);

    watch((activePosition), (paramns) => {
      if(activePosition.value[0]) {
        legendRef.value.style.transform = `translatex(${paramns[1]})`;
      }
      else {
        legendRef.value.style.transform = '';
      };
    });
    
    return { 
      activeName, 
      legendRef,
      formatter,
      CLASSES
     };
  },
  data() {
    return {
      data: [],
      layerId: "map-layer-state",
      property: "",
      activeName: "",
      mapProperty: {
        [PIB]: 'PIB',
        [RENDA]: 'Renda média mensal',
        [DOMICILIOS]: 'Nº de domicílios',
        [RESIDENTES]: 'Nº de habitantes',
        [CLASSES]: 'Classe social',
        [POP_CRIANCAS]: 'crianças',
        [POP_JOVENS]: 'jovens',
        [POP_ADULTOS]: 'adultos',
        [POP_IDOSOS]: 'IDOSOS',
        [MORTES_HOMENS]: 'mortes homens',
        [MORTES_MULHERES]: 'mortes mulheres', 
        [TOTAL_MORTES]: 'total mortes',
        [MORTES_DOMICILIOS]: 'Mortes por domicílio'
      }
    };
  },
  watch: {
    getLegend: {
      async handler(newVal, oldVal) {
        if (newVal!==null) {
          this.property = this.mapProperty[newVal[2]];
          this.layerId = newVal[3];
          this.data = Object.entries(newVal[0]).flatMap(
            ([interval, color], idx) => [
              {
                id: idx,
                activated: true,
                color: color,
                value: Number(interval.split("-")[1]),
                valueType: newVal[1],
                minValue: Number(interval.split("-")[0]),
                property: newVal[2] === CLASSES ? RENDA : newVal[2],
                propertyAux: newVal[2],
                legenda: (newVal[2] === CLASSES) ? CLASSES_LEGENDA[idx] : "", 
                propertyNameDivisor: newVal[4]
              },
            ]
          );
        } else {
          this.property = '';
        }

      },
      immediate: true,
    },
  },
  methods: {
    handlerLegend(item) {
      console.log(item);
      if (!item.activated)
        MapService.hideRange(
          this.layerId,
          item.property,
          item.minValue,
          item.value,
          item.propertyNameDivisor
        );
      else
        MapService.showRange(
          this.layerId,
          item.property,
          item.minValue,
          item.value,
          item.propertyNameDivisor
        );
    },
  },
};
</script>

<style scoped>
.container__input--legend {
  display: flex;
  /* justify-content: space-between; */
  gap: 10px;
  margin: 10px 0;
  align-items: center;
  width: max-content;
}
.block__color {
  display: block;
  width: 19px;
  height: 19px;
}
.container__mapLegend {
  position: absolute;
  bottom: 1%;
  padding: 10px;
  overflow: hidden;
  width: max-content;
  min-width: 180px; 
  margin-left: 1%;
  transition: 1s;
  background: var(--dm_creme_02);
  filter: drop-shadow(0 4px 2px rgba(0, 0, 0, 0.25));
  border-radius: 5px;
  z-index: 2;
}

.hr-div{
  border-width: 1px 0 0 0;
  border-style: solid;
  border-color: #cacaca;
  margin: 5px 0;
}

.container-ponto-area{
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.container-ponto-area > p{
  display: flex;
  align-items: center;
}

@media screen and (min-width: 560px) and (max-width: 900px) {
  .container__mapLegend {
    z-index: 1;
  }
}

@media screen and (max-width: 560px) {
  .container__mapLegend {
    z-index: 100;
  }
}
</style>
  