// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBWzfRiQHYHKPtXbnvBHtYDk1SHNL_N7PA",
  authDomain: "mapalytics-dm.firebaseapp.com",
  projectId: "mapalytics-dm",
  storageBucket: "mapalytics-dm.appspot.com",
  messagingSenderId: "202769640831",
  appId: "1:202769640831:web:99181b68ab77e6374afe4a",
  measurementId: "G-32YEGPNGER"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth();

export { app, analytics, auth };
