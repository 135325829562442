<template>
  <div class="container__marker">
    <p>Nomear o ponto único</p>
    <el-input v-model="markerName" placeholder="Ponto A" @keyup="checkNames()" />
    <span v-if="featureNameFree" class="name_layer">Nome de camada já está sendo utilizado</span>
    <p>Deseja adicionar um raio a partir desse ponto?</p>
    <el-radio-group v-model="radio">
      <el-radio :label="1">Sim</el-radio>
      <el-radio :label="2">Não</el-radio>
    </el-radio-group>
    <el-slider v-if="radio === 1" class="slider" v-model="radiusDistance" :step="0.5" :marks="marks" :show-tooltip="false"
      :max="3" :min="0.5" />
    <div class="buttons">
      <el-button type="primario" @click="createMarker(true)" :disabled="featureNameFree"> Confirmar </el-button>
      <el-button type="secundario" @click="createMarker(false)">
        Cancelar
      </el-button>
    </div>
  </div>
</template>

<script>
import { Search } from "@element-plus/icons-vue";
import { ref, reactive } from "vue";
import MapService from '../services/map-service'
import MapMarkerPopup from "./MapMarkerPopup";
import wrapper from "vue3-webcomponent-wrapper";
import { createApp, h } from "vue";
import { makePopup } from '../helpers/transforms';
import { STYLE_POINTS, MUNICIPIO, ESTADO } from '../helpers/constants'
import { useStore } from 'vuex';
import { computed } from 'vue';
import MyFeature from "../models/feature";
import { LayerService } from "../services/layer-service";
import { FeatureService } from '../services/feature-service';


const CustomElement = wrapper(MapMarkerPopup, createApp, h);
window.customElements.define("my-component", CustomElement);


export default {
  name: "MapComponentMarkerConfig",
  components: {
    CustomElement,
    MapMarkerPopup
  },
  data() {
    return {
      show: false,
      featureNameFree: false,
    };
  },
  setup() {
    const radio = ref(2);
    const radiusDistance = ref(0.5);
    const store = useStore();
    const markerName = ref(null);
    const user = computed(() => store.getters['auth/getUser']);
    const markerCreated = ref({});
    const featureService = new FeatureService(user.value);
    const layerService = new LayerService(user.value);
    const getFeatureCollection = computed(() => store.getters['map/getFeatureCollection']);
    const marks = reactive({
      0.5: "0,5 km",
      1: "1 km",
      1.5: "1,5 km",
      2: "2 km",
      2.5: "2,5 km",
      3: "3 km",
    });

    const createPopupContent = (name, city, state) => {
      let lastMarker = 0;
      let setName;
      getFeatureCollection.value.features.forEach(el => {
        if (el.properties.style === STYLE_POINTS[0] && el.properties.nome.split(' ')[0] === 'Ponto') {
          lastMarker = ++el.properties.nome.split(' ')[1];
        };
      });
      setName = name === null ?
        'Ponto ' + lastMarker :
        name;
      const data = {
        properties: {
          nome: setName,
          city,
          state,
        }
      };
      return makePopup('Ponto único', data);
    };

    const addToFeatureCollection = async (name, city, state, coordinates, style, radius = 0) => {
      let lastMarker = 0;
      let setName;
      getFeatureCollection.value.features.forEach(el => {
        if (el.properties.style === STYLE_POINTS[0] && el.properties.nome.split(' ')[0] === 'Ponto') {
          lastMarker = ++el.properties.nome.split(' ')[1];
        };
      });
      setName = name === null ?
        'Ponto ' + lastMarker + (style === STYLE_POINTS[0] ? '' : ' (Raio)') :
        name + (style === STYLE_POINTS[0] ? '' : ' (Raio)');
      const { lng, lat } = coordinates;
      const feature = await featureService.createFeature(
        {
          type: 'Point',
          coordinates: [lng, lat],
        },
        style === STYLE_POINTS[1] ? 'study' : 'points',
        {
          nome: setName,
          city: city,
          state: state,
          radius: `${radius}`,
          style: style,
        },
      );
      layerService.setFeatureInLayer(feature, getFeatureCollection.value);
      const myFeature = new MyFeature(feature.id, user.value.data.idCompany, feature.geojson.geometry, feature.geojson.properties, feature.discriminator, feature.properties);
      getFeatureCollection.value.addFeature(myFeature);
      MapService.updateSource(getFeatureCollection.value.toGeoJSON());
      return myFeature;
    };

    const createMarker = async (action) => {
      if (action) {
        // get city and state from lat lng
        let [city, state] = ['Cidade não encontrada', 'Estado não encontrado'];
        const coordinates = MapService.getEvent();
        const resName = await MapService.addNameByLngLat(coordinates.lngLat, user.value.token);
        resName.forEach(el => {
          console.log(el.name === 'counties')
          el.name === MUNICIPIO ? city = el.value : null;
          el.name === ESTADO ? state = el.value : null;
        });

        addToFeatureCollection(markerName.value, city, state, coordinates.lngLat, STYLE_POINTS[0]).then((feature) => {
          MapService.flyto(feature);
          const popupHtml = createPopupContent(markerName.value, city, state, coordinates.lngLat);
          MapService.addMarker(null, popupHtml, 'event', feature);
          markerCreated.value = { city, state, coordinates };
        });
      };
      if (!action) {
        store.dispatch('map/changeMapState', 'cancelar');
      };
    };

    const createRadius = (data) => {
      addToFeatureCollection(markerName.value, data.city, data.state, data.coordinates.lngLat, STYLE_POINTS[1], radiusDistance.value * 1000).then((feature) => {
        MapService.flyto(feature);
        store.dispatch('map/changeMapState', 'confirmar');
      });
    };

    return {
      Search,
      radiusDistance,
      marks,
      radio,
      user,
      store,
      token: computed(() => store.getters['auth/getToken']),
      markerName,
      markerCreated,
      getFeatureCollection,
      addToFeatureCollection,
      createMarker,
      createRadius,
    };
  },
  watch: {
    markerCreated: {
      async handler(newVal, oldVal) {
        this.radio === 1 ? this.createRadius(newVal) : this.store.dispatch('map/changeMapState', 'confirmar');
      },
    },
  },
  methods: {
    checkNames() {
      this.getFeatureCollection.features.forEach(el => {
        el.properties.nome === this.markerName ? this.featureNameFree = true : this.featureNameFree = false;
      });
    },
  },
};
</script>

<style scoped>
.name_layer {
  font-size: 12px;
  color: firebrick;
}

.container__marker {
  position: absolute;
  left: calc(40vw - 200px);
  top: 25vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 400px;
  height: max-content;
  padding: 15px;
  background: var(--dm_creme_02);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  z-index: 200;
}

.slider {
  width: 100%;
  padding: 0 15px;
  ;
}

.buttons {
  margin-top: 20px;
}
</style>