<template>
  <div class="container__mapComponent">
    <el-col height="100%">
      <MapboxMap class="container__mapbox" :access-token=MAPBOX_KEY :map-style=MAP_STYLE :center="[-46.6388, -23.5489]"
        :zoom="8" @mb-created="(mapInstance) => onMapCreate(mapInstance)" />
    </el-col>
    <el-col class="container__mapLegend">
      <MapComponentLegend :show-legend="showLegend"/>
    </el-col>
    <el-col class="container__mapRadius">
      <MapComponentRadius />
      <MapSearchBar />
    </el-col>
    <MapComponentMarkerConfig v-if="this.mapState === 'popupMarker'" />
    <MapComponentZoom />
    <MapComponentNotification v-if="(this.mapState === 'clickMarker') || (this.mapState === 'clickRadius')" />
    
  </div>
  <MapComponentVisualization v-if="check" :levelZoom="zoomMapLevel" />
</template>

<script>
import { MapboxMap } from '@studiometa/vue-mapbox-gl';
import { useStore } from 'vuex';
import { computed, ref } from 'vue';
import { MAP_STYLE, MAPBOX_KEY } from '../map-box.config';
import MapService from '../services/map-service'
import MapComponentLegend from './MapComponentLegend.vue';
import MapComponentRadius from './MapComponentRadius.vue';
import MapComponentMarkerConfig from './MapComponentMarkerConfig.vue';
import MapComponentZoom from './MapComponentZoom.vue';
import MapComponentNotification from './MapComponentNotification.vue';
import MapComponentVisualization from './MapComponentVisualization.vue';
import Alert from '../../../components/Alert.vue'
import MapSearchBar from './MapSearchBar.vue';
import { LEADS_SOURCE, LEADS_LAYER} from '../helpers/constants'

export default {
  name: 'MapComponent',
  components: {
    MapboxMap,
    MapComponentLegend,
    MapComponentRadius,
    MapComponentMarkerConfig,
    MapComponentZoom,
    MapComponentNotification,
    MapSearchBar,
    Alert,
    MapComponentVisualization
},
  data() {
    return {
      showLegend: true,
    };
  },
  setup() {
    const store = useStore();
    const loaderMap = ref(false);
    const check = ref(true);
    const zoomMapLevel = ref(8);
    const onMapCreate = async (mapInstance) => {
      mapInstance.on("idle", () => {
        zoomMapLevel.value = mapInstance.getZoom();
      });
      MapService.initMap(mapInstance, store);
      loaderMap.value = true;
    }
    return {
      zoomMapLevel,
      check,
      MAP_STYLE,
      MAPBOX_KEY,
      store,
      loaderMap,
      mapState: computed(() => store.getters['map/getMapActualState']),
      getLegend: computed(() => store.getters['map/getLegend']),
      getLeads: computed(() => store.getters['map/getLeads']),
      getFeatureCollection: computed(() => store.getters['map/getFeatureCollection']),
      onMapCreate,
    };
  },
  watch: {
    getLeads: {
      async handler(newVal, oldVal) {
        if (newVal.length === 0) MapService.handleVisibilityLayer(LEADS_LAYER, 'none');
        if (newVal) {
          MapService.updateSource({type: 'FeatureCollection', features: newVal}, LEADS_SOURCE)
          MapService.handleVisibilityLayer(LEADS_LAYER, 'visible');
        }
      },
    },
    getLegend: {
      async handler(newVal, oldVal) {
        if (newVal) {
          this.showLegend = true;
        } else {
          this.showLegend = false;
        }
      },
    },
    loaderMap: {
      async handler(newVal, oldVal) {
        if (newVal) {
          const myFeatureCollectionGeoJSON = this.getFeatureCollection.toGeoJSON();
          MapService.addSource(myFeatureCollectionGeoJSON);
          MapService.addCircleLayer();
          MapService.addMarkerLayer();
          MapService.addPolygonLayer();
          this.store.dispatch('map/mapLoader', newVal);
        }
      },
    },
  },
}

</script>

<style scoped>
.container__mapComponent {
  /* position: relative; */
  position: fixed;
  width: 100%;
  left: 312px;
}

.container__mapVisualization {
  position: absolute;
  min-width: 440px;
  width: 36%;
  bottom: 0;
  left: calc(38vw - 200px);
  overflow: hidden;
  margin-bottom: 1%;
  filter: drop-shadow(0 4px 2px rgba(0, 0, 0, 0.25));
  text-align: center;
  border-radius: 6px;
}

.container__mapRadius {
  display: flex;
  align-items: center;
  gap: 10px;
  position: absolute;
  top: 0;
  margin: 1% 0 0 1%;
  filter: drop-shadow(0 4px 2px rgba(0, 0, 0, 0.25));
}
</style>

<style>
.container__mapbox {
  position: relative;
  height: calc(100vh - 50px);
  overflow: hidden;
}

.mapboxgl-control-container {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 22%;
}

.mapboxgl-canvas {
  height: 100%;
}

.point .mapboxgl-popup-content .mapboxgl-popup-close-button {
  display: none;
}

.layers .mapboxgl-popup-content .mapboxgl-popup-close-button {
  margin-right: 5px;
}

.layers .mapboxgl-popup-content {
  width: 320px;
}

.mapboxgl-map {
  height: calc(100vh - 50px);
}

.mapboxgl-popup-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 95px;
  width: 300px;
  background-color: var(--dm_creme_02);
  border-radius: 6px 6px 0px 0px;
  border-bottom: 7px solid var(--dm_amarelo_01);
}

.mapboxgl-ctrl-attrib-inner {
  position: absolute;
  font-size: 0.7em;
  padding: 0.7% 0.8% 0.5% 3%;
  text-align: center;
  bottom: -3px;
  right: 2px;
}

.mapboxgl-ctrl-attrib-inner>a {
  text-decoration: none;
  color: var(--dm_preto_00);
}

.mapbox-improve-map {
  font-weight: bold;
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
  background: none !important;
  padding: 0;
  width: 208px;
  min-width: 208px;
  white-space: nowrap;
}

.el-collapse-item__header {
  height: 33px;
}

.mapboxgl-ctrl-logo {
  position: absolute;
  bottom: 9px;
  right: -16.7vw;
}

.mapboxgl-ctrl-attrib.mapboxgl-compact {
  background: none;
}
</style>
