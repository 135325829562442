import {
  CHANGE_STATE_MAP,
  FINISH_COMPUTE,
  CHANGE_VISUALIZATION,
  CHANGE_ZOOM_VISUALIZATION,
  MAP_LOADER,
  UPDATE_GROUPS,
  CHANGE_LEGEND,
  COMPANY_GROUPS,
  CHANGE_ASIDE,
  CHANGE_LEGEND_POSITION,
  CHANGE_POINT_INTER,
  MAP_POPUP,
  IS_MARKER,
  MAP_GROUP_LIST,
  MAP_DATA_LIST,
  MAP_PIPEDRIVE_POPUP,
  CHANGE_LEADS,
  UPDATE_MARKER_MENU,
  MAP_DELETE_AREA,
  HANDLE_ALERT,
  SET_FEATURE_COLLECTION
} from "./mutations-types";
import { TRADUCTOR, COLORS_PINS, COLORS_PINS_BORDER } from "../helpers/constants";
import { CustomFeatureCollection } from "../models/feature-collection";

export default {
  [CHANGE_STATE_MAP](state, action) {
    state.map.state.compute(action);
  },
  [FINISH_COMPUTE](state) {
    state.map.state.finishCompute();
  },
  [CHANGE_VISUALIZATION](state, visualization) {
    state.map.visualization = visualization;
  },
  [CHANGE_ZOOM_VISUALIZATION](state, data) {
    state.map.visualizationZoom = data;
  },
  [MAP_LOADER](state, data) {
    state.map.loader = data;
  },
  [UPDATE_GROUPS](state, data) {
    state.map.groupsList = data;
  },
  [COMPANY_GROUPS](state, data) {
    state.map.groupsCompany.push(data);
  },
  [CHANGE_LEGEND](state, data) {
    state.map.legend = data;
  },
  [CHANGE_ASIDE](state, data) {
    state.map.aside = data;
  },
  [CHANGE_LEGEND_POSITION](state, data) {
    state.map.legendPosition = data;
  },
  [UPDATE_MARKER_MENU](state) {
    state.map.updateMarkerMenu = !state.map.updateMarkerMenu;
  },
  [CHANGE_POINT_INTER](state, data) {
    state.map.pointInter = data;
  },
  [MAP_POPUP](state, data) {
    state.map.mapPopup = data;
  },
  [IS_MARKER](state, data){
    state.map.isMarker = data;
  },
  [CHANGE_LEADS](state, data){
    state.map.leads = data;
  },
  [MAP_GROUP_LIST](state, data) {
    state.map.groupList = data.map((group, i) => {
      return {
        [group]: 0,
        discriminator: group,
        name: TRADUCTOR[group] || group,
        color: Object.entries(COLORS_PINS)[i],
        borderColor: Object.entries(COLORS_PINS_BORDER)[i],
      };
    });
  },
  [MAP_DATA_LIST](state, data) {
    state.map.dataList = data;
  },
  [MAP_PIPEDRIVE_POPUP](state, data) {
    state.map.popupPipedrive = data;
  },
  [MAP_DELETE_AREA](state, data) {
    state.map.deleteArea = data;
  },
  [SET_FEATURE_COLLECTION](state, data) {
    state.featureCollection = new CustomFeatureCollection(data.id, data.name, data.geojson.features, data.grupos, data.dadosGerais);
  },
  [HANDLE_ALERT](state, data) {
    state.map.alert = data;
  },
};
