<template>
  <div class="container__popUp--Config">
    <h6>Nomear o raio</h6>
    <el-input v-model="radiusName" placeholder="Raio 1" @keyup="checkNames()"></el-input>
    <span v-if="featureNameFree" class="name_layer">Nome de camada já está sendo utilizado</span>
    <p>Escolha o tamanho do raio:</p>
    <el-slider
      class="slider"
      v-model="radiusDistance"
      :step="0.5"
      :marks="marks"
      :show-tooltip="false"
      :max="3"
      :min="0.5"
    />
    <div class="container__popUp--Config--btn">
      <el-button type="primario" @click="closeConfig('confirmar')"> Confirmar </el-button>
      <el-button type="secundario" @click="closeConfig('cancelar')"> Cancelar </el-button>
    </div>
  </div>
</template>

<script>
import { ref, reactive } from "vue";
import MapService from "../services/map-service";
import { getFeatureCollection } from "../helpers/global";
import { STYLE_POINTS, MUNICIPIO, ESTADO } from "../helpers/constants";
import { computed } from "vue";
import { useStore } from "vuex";
import { LayerService } from "../services/layer-service";
import { FeatureService } from "../services/feature-service";
import MyFeature from "../models/feature";

export default {
  name: "MapComponentRadiusConfig",
  data() {
    return {
      radiusName: null,
      featureNameFree: false,
    };
  },
  setup() {
    const store = useStore();
    const radiusDistance = ref(0);
    const marks = reactive({
      0.5: "0,5 km",
      1: "1 km",
      1.5: "1,5 km",
      2: "2 km",
      2.5: "2,5 km",
      3: "3 km",
    });
    return {
      radiusDistance,
      user: computed(() => store.getters["auth/getUser"]),
      token: computed(() => store.getters["auth/getToken"]),
      getFeatureCollection: computed(() => store.getters['map/getFeatureCollection']),
      marks,
    };
  },

  methods: {
    checkNames() {
      this.getFeatureCollection.features.forEach((el) => {
        el.properties.nome === this.radiusName
          ? (this.featureNameFree = true)
          : (this.featureNameFree = false);
      });
    },
    async closeConfig(action) {
      if (action === "confirmar") {
        // get city and state from lat lng
        let [city, state] = ['Cidade não encontrada', 'Estado não encontrado'];
        const coordinates = MapService.getEvent();
        const resName = await MapService.addNameByLngLat(coordinates.lngLat, this.token);
        resName.forEach((el) => {
          el.name === MUNICIPIO ? (city = el.value) : null;
          el.name === ESTADO ? (state = el.value) : null;
        });
        this.addToFeatureCollection(
          this.radiusName,
          city,
          state,
          coordinates.lngLat,
          STYLE_POINTS[1],
          this.radiusDistance * 1000
        ).then((feature) => {
          MapService.flyto(feature);
        });
        this.$store.dispatch("map/finishCompute");
      }
      if (action === "cancelar") {
        this.$store.dispatch("map/changeMapState", "cancelar");
      }
    },

    async addToFeatureCollection(name, city, state, coordinates, style, radius = 0) {
      const featureService = new FeatureService(this.user);
      const layerService = new LayerService(this.user);
      // add to Feature collection
      let lastMarker = 0;
      this.getFeatureCollection.features.forEach((el) => {
        if (
          el.properties.style === STYLE_POINTS[1] &&
          el.properties.nome.split(" ")[0] === "Raio"
        ) {
          lastMarker = ++el.properties.nome.split(" ")[1];
        }
      });
      const setName = name === null ? "Raio " + lastMarker : name + " (Raio)";
      const { lng, lat } = coordinates;
      const feature = await featureService.createFeature(
        {
          type: "Point",
          coordinates: [lng, lat],
        },
        "study",
        {
          nome: setName,
          city: city,
          states: state,
          radius: `${radius}`,
          style: style,
        }
      );
      layerService.setFeatureInLayer(feature, this.getFeatureCollection);
      const myFeature = new MyFeature(
        feature.id,
        this.user.data.idCompany,
        feature.geojson.geometry,
        feature.geojson.properties,
        feature.discriminator
      );

      this.getFeatureCollection.addFeature(myFeature);
      MapService.updateSource(this.getFeatureCollection.toGeoJSON());
      this.$store.dispatch("map/changeMapState", "confirmar");
      return myFeature;
    },
  },
};
</script>

<style scoped>
.name_layer {
  font-size: 12px;
  color: firebrick;
}

.container__popUp--Config {
  position: absolute;
  left: calc(40vw - 200px);
  top: 23vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 400px;
  height: 240px;
  padding: 15px;
  background: var(--dm_creme_02);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
}

.slider {
  width: 100%;
  padding: 0 15px;
}

.container__popUp--Config--btn {
  margin-top: 25px;
}
</style>
