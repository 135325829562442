import axios from "axios";
import { API_URL } from "@/modules/bi/helpers/constants";
export class GoogleService {
  constructor(user) {
    this.user = user;
    this.url = API_URL;
  }

  async getLeadsByQuery(latLng, radius, queryString, store, filters) {
    let response;
    try {
      response = await axios.get(
        `${this.url}/companies/${this.user.data.idCompany}/features/search-text/${latLng[1]},${
          latLng[0]
        }?radius=${radius * 1000}&keyword=${queryString}`,
        {
          headers: {
            Authorization: `Bearer ${this.user.token}`,
          },
        }
      );
      console.log(response)
      return response.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
}

const googleService = new GoogleService();

export default googleService;
