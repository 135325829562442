export default {
  user: {
    loggedIn: false,
    data: null,
    token: null,
    authExpiration: null,
  },
  company: {
    data: null,
  }
};
