<template>
  <div class="geral">
    <!-- Container -->
    <div class="geral__popup">
      <p>Buscar dados sociodemográficos</p>

      <!-- Barra de pesquisa -->

      <div class="geral__container--autocomplete">
        <el-autocomplete 
            placeholder="Digite um tipo de dado sociodemográfico" style="width: 100%;"
            v-model="vModelAutoCompleate"
            :fetch-suggestions="querySearch"
            clearable
            @select="resultSearchAdd"
        >
        </el-autocomplete>
        <dm-icon Search />
      </div>

      <!-- Sugestões -->

      <div v-if="sugestoes.length != 0">
        <h6>Sugestões</h6>
        <div @click="addSugestion">
          <el-checkbox-group v-model="checkedCities" @change="sugestoesAdd" class="geral__sugestion--item" >
            <el-checkbox v-for="sugestao in sugestoes" :label="sugestao.content" />
          </el-checkbox-group>
        </div>
      </div>

      <!-- Selecionados -->
        
      <div class="popups__selected">
          <h6>Selecionados</h6>
            <el-checkbox-group class="geral__sugestion--item">
              <el-checkbox  
                  @change="checkBoxRemove(check)" 
                  v-for="check in itensSelecionados" 
                  checked 
                  :label="check" 
              />
            </el-checkbox-group>

            <p v-if="itensSelecionados.length == 0" class="popups__selecione">Selecione algum item</p>
        </div>

      <div class="geral__button">
        <el-button type="primario" @click="confirm('confirm')">Confirmar</el-button>
        <el-button type="secundario" @click="confirm('cancel')">Cancelar</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import { LayerService } from '../services/layer-service';
export default {
  name: "MapMenuDataPopUp",
  setup(props) {
    const store = useStore()
    const user = store.getters["auth/getUser"];
    const layerService = new LayerService(user);
    const getFeatureCollection = computed(() => store.getters['map/getFeatureCollection']);
    const vModelAutoCompleate = ref()
    const checkedCities = ref()
    const itensSelecionados = ref([])
    const elementosProps = props.dadosGeraisOptions
    const sugestoes = ref([])
    let elementos = ref([]) // recebe os itens de Dados Gerais
    const sugestions = [0, 1, 2] // Posições dos itens que ficam de sugestão
    
    for(let i = 0; i < elementosProps.length; i++) { // Adiciona os itens da props para a variável "elementos"
      elementos.value.push({
        value: elementosProps[i].content,
        label: elementosProps[i].label,
      })
    }

    for(let i = 0; sugestions.length > i; i++) {
      const index = sugestions[i]
      sugestoes.value.push({
        content: elementosProps[index].content,
        label: elementosProps[index].label
        }
      )
    }

    let newRadiosOptions = ref([])
    onMounted(() => {
      for(let item of props.radiosOptions) {
        newRadiosOptions.value.push(item.content)
      }
      sugestoesAdd(transformProxy(newRadiosOptions.value))
    })

    const resultSearchAdd = (params) => { // Adiciona o item clicado pela barra de pesquisa na lista de "selecionados"
      sugestoesAdd(params.value)
      const index = sugestoes.value.findIndex(obj => obj.content == params.value)
      if(index != -1) {
        sugestoes.value.splice(index, 1) // Se a opção clica na lista for mesma que está na sugestão, exluí ela da lista
      }
      vModelAutoCompleate.value = '' // Limpa o campo de pesquisa
    }
    
    const querySearch = (queryString, cb) => {
      let gnrioeg = elementos.value.filter(obj => transform(obj.value).includes(transform(queryString)))
      cb(transformProxy(gnrioeg)) // Compara os caracteres digitado, e lista os objetos encontrados 
    }

    const transformProxy = (params) => {
      return JSON.parse(JSON.stringify(params))
    }
    
    const transform = (text) => {
      return text.toLowerCase()
    }
   
    const sugestoesAdd = (params) => {
      let discriminator = computed(() => store.getters['map/getDataList'])
      if(typeof(params) == 'object') {
        for(let item of params) {
          if(!ifExist(item)) {
              sugestoes.value.splice(sugestoes.value.findIndex(obj => obj.content === item), 1) // Remove da lista de sugestão
              itensSelecionados.value.push(item) // Adiciona a sugestão    
              let index = elementos.value.findIndex(obj => obj.value === item) // Ao adicionar o item, remove ele da lista de pesquisa
              elementos.value.splice(index, 1)
          }
        }
      } else if(!ifExist(params)){
        if(discriminator == undefined){
          let index = elementos.value.findIndex(obj => obj.value === params) // Ao adicionar o item, remove ele da lista de pesquisa
          elementos.value.splice(index, 1)
          itensSelecionados.value.push(params)
          } else {
            let index = elementos.value.findIndex(obj => obj.label === params) // Ao adicionar o item, remove ele da lista de pesquisa
            elementos.value.splice(index, 1)
            itensSelecionados.value.push(params)
        }   
      }
    }

    const ifExist = (params) => {
      return itensSelecionados.value.some(obj => obj == params)
    }

    const checkBoxRemove = (params) => {
      itensSelecionados.value.splice(itensSelecionados.value.findIndex(obj => obj == params), 1) // Remove da lista Selecionados, o item clicado 
    }

    return {
      store,
      checkedCities,
      vModelAutoCompleate,
      sugestoes,
      itensSelecionados,
      grupoDePoints: computed(() => store.getters['map/getGroupList']),
      layerService,
      getFeatureCollection,
      querySearch,
      checkBoxRemove,
      sugestoesAdd,
      resultSearchAdd,
    };
  },
  data () {
    return {
      arrData: [],
    }
  },
  props: {
    closePopUpSearch: Function,
    dadosGeraisOptions: Array,
    radiosOptions: Array,
  },
  methods: {
    async confirm(param){
      this.arrData = [] // limpa o array para receber os novos itens
      this.$store.dispatch('map/changeDataList', this.arrData)
      await this.layerService.updateLayer(
        this.getFeatureCollection,
        this.grupoDePoints.map((el) => el.discriminator), // Pega os grupos de pontos salvos  
        this.loadItens()
      )
      this.closePopUpSearch()
    },
    loadItens(){
      this.itensSelecionados.filter(obj => {
        for(let i = 0; i < this.dadosGeraisOptions.length ;i++){
          if(obj == this.dadosGeraisOptions[i].content) {
            this.arrData.push(this.dadosGeraisOptions[i].label) // pega o discriminator
          }
        }
      })
      let convert = JSON.parse(JSON.stringify(this.arrData)) 
      return convert
    }
  },
};
</script>

<style>
.geral .el-input__wrapper {
  padding: 0 10px !important;
  width: 100%;
}
.el-checkbox__input.is-checked + .el-checkbox__label {
  color: var(--dm_cinza_01);
}

</style>


<style scoped>
.geral {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  width: calc(100% - 312px);
  height: 100vh;
}

/* Popups */

.geral__popup {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 400px;
  height: max-content;
  padding: 15px;
  background: var(--dm_creme_02);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
}

.geral__container--autocomplete {
  width: 100% !important;
}

.geral__container--autocomplete .el-input__inner {
  padding: 10px !important;
}

.geral__container--autocomplete .el-input__wrapper {
  border-radius: 6px !important;
  border: 1px !important;
  border-color: var(--dm_cinza_03);
  padding: 0 60px 0 10px !important;
  width: 100% !important;
}

.popups__selecione {
  display: flex;
  align-items: center;
  height: 32px;
  color: var(--dm_rosa_00)
}

.geral__container--autocomplete {
  display: flex;
  align-items: center;
  position: relative;
}

.geral__sugestion--item {
  display: flex;
  flex-direction: column;
}

[Search] {
  position: absolute;
  right: 10px;
}

.geral__button {
  display: flex;
}

</style>