<template>
    <div class="container__popUp--Notification">
     <p>Selecione um local no mapa.</p>
    </div>
</template>

<script>
export default {
    name: "MapComponentRadiusPopUpNotification"
}
</script>

<style scoped>
  .container__popUp--Notification {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: calc(40vw - 200px);
    top: 2%;
    width: 400px;
    height: 50px;
    border: 2px solid #FFBB40;
    border-radius: 6px;
    background-color: #FAF6F0;
  }
</style>