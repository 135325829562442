export const CHANGE_STATE_MAP = 'CHANGE_STATE_MAP';
export const FINISH_COMPUTE = 'FINISH_COMPUTE';
export const CHANGE_VISUALIZATION = 'CHANGE_VISUALIZATION';
export const MAP_LOADER = 'MAP_LOADER';
export const CHANGE_ZOOM_VISUALIZATION = 'CHANGE_ZOOM_VISUALIZATION';
export const CHANGE_LEGEND = 'CHANGE_LEGEND';
export const CHANGE_ASIDE = 'CHANGE_ASIDE';
export const UPDATE_GROUPS = 'UPDATE_GROUPS';
export const COMPANY_GROUPS = 'COMPANY_GROUPS';
export const CHANGE_LEGEND_POSITION = 'CHANGE_LEGEND_POSITION';
export const CHANGE_POINT_INTER = 'CHANGE_POINT_INTER';
export const MAP_POPUP = 'MAP_POPUP';
export const IS_MARKER = 'IS_MARKER';
export const MAP_GROUP_LIST = 'MAP_GROUP_LIST';
export const MAP_DATA_LIST = 'MAP_DATA_LIST';
export const MAP_PIPEDRIVE_POPUP = 'MAP_PIPEDRIVE_POPUP';
export const CHANGE_LEADS = 'CHANGE_LEADS';
export const UPDATE_MARKER_MENU = 'UPDATE_MARKER_MENU';
export const MAP_DELETE_AREA = 'MAP_DELETE_AREA';
export const SET_FEATURE_COLLECTION = 'SET_FEATURE_COLLECTION';
export const HANDLE_ALERT = 'HANDLE_ALERT';
