<template>
  <div class="info">
    <img src="../assets/Info.svg" class="info-image">
    <div class="info-message">
      <p>Dados do Censo Demográfico IBGE 2010</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "MapMenuInfo"
}
</script>

<style scoped>
  .info {
    position: absolute;
    top: -7px;
    left: 135px;
  }

  .info-image {
    width: 18px;
  }

  .info-message {
    display: none;
    width: 100px;
    border: 1px solid #FAF6F0 ;
    border-radius: 4px;
    position: absolute;
    top: -48px;
    left: 17px;
    padding: 3px;
    background-color: #FAF6F0 ;
  }

  .info-message > p {
    font-size: 11px;
  }
  .info:hover .info-message {
    display: block;
  }

</style>