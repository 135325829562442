<template>
  <div class="popups" v-if="isTrue">
    <!-- Marker config -->
    <div class="container__popup-config" v-if="showMarkerConfig">
      <p>Deseja adicionar:</p>
      <el-radio-group v-model="radio">
        <el-radio :label="1">Ponto único</el-radio>
        <el-radio :label="2">Grupo de pontos</el-radio>
      </el-radio-group>
      <div class="popups__buttons">
        <el-button type="primario" @click="valueRadio()">Confirmar</el-button>
        <el-button type="secundario" @click="closePopup()">Cancelar</el-button>
      </div>
    </div>

    <!-- Group of points -->

    <div class="container__popup-grup" v-else>
      <p>Buscar por CNAE raiz ou tipo de negócio</p>
      <!-- Barra de pesquisa -->
      <div class="search-bar">
        <span class="barra-pesquisa_mark">
          <el-autocomplete
            placeholder="Digite um CNAE raiz ou tipo de negócio"
            v-model="searchPopup"
            :fetch-suggestions="querySearch"
            style="width: 100%"
            clearable
            @select="addResultSearch"
          >
          </el-autocomplete>
        </span>
        <dm-icon Search />
      </div>

      <!-- Sugestões -->

      <div class="popups__sugestion" v-if="!sugestions.length == 0">
        <h6>Sugestões</h6>
        <div
          class="popups__sugestion--item"
          v-for="sugestion in sugestions"
          :discriminator="sugestion.discriminator"
          @click="addSugestion"
        >
          <check style="cursor: pointer">
            <el-checkbox class="popups__marker" style="pointer-events: none" />
          </check>
          <span>{{ sugestion.name }} - {{ sugestion.cnae }}</span>
        </div>
      </div>

      <!-- Selecionados -->

      <div class="popups__selected">
        <h6>Selecionados</h6>
        <div class="popups__area">
          <div
            class="popups__sugestion--item"
            v-if="!arrSelectedItems.length == 0"
            v-for="item in arrSelectedItems"
          >
            <div @click="removeResultSearch(item.name)" style="cursor: pointer">
              <el-checkbox style="pointer-events: none" checked class="popups__marker" />
            </div>
            <span
              ><!-- CNAEs selecionados  -->
              {{ item.name }} - {{ item.cnae }}</span
            >
          </div>
          <p v-else style="color: var(--dm_rosa_00)">Selecione algum item</p>
        </div>
      </div>

      <div class="popups__buttons">
        <el-button
          type="primario"
          @click="groupsPointsAdd(), closePopup()"
          :disabled="featureNameFree"
          >Confirmar</el-button
        >
        <el-button type="secundario" @click="closePopup()">Cancelar</el-button>
      </div>
    </div>
      <ErrorMessages
        v-if="showPopupError"
        :cnaeMessage="cnaeMessage"
        :ClosePopUpButton="closePopUpButton"
        :title="titleError"
        :subtitle="subtitleError" 
        :buttonConfirm="buttonConfirmError"
      />
  </div>
</template>

<script>
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";

import { FeatureService } from "../services/feature-service";
import { LayerService } from "../services/layer-service";

import { GROUP_POINTS, GROUP_POINTS_COMPANY, GROUP_POINTS_COMPANY_2 } from "../helpers/constants";

import ErrorMessages from "@/components/ErrorMessages.vue";

export default {

  components: {ErrorMessages},

  setup() {
    const typingTimer = ref(null);
    const store = useStore();
    const user = store.getters["auth/getUser"];
    const getFeatureCollection = computed(() => store.getters['map/getFeatureCollection']);
    const layerService = new LayerService(user);
    const featureService = new FeatureService(user);
    const groupsList = computed(() => store.getters["map/getGroupList"]);
    const showPopupError = ref(false);
    const buttonConfirmError = ref(false);
    const titleError = ref("");
    const subtitleError = ref("");
    const cnaeMessage = ref(false);
    let radio = ref(1);
    let showPopups = ref(false);
    let showMarkerConfig = ref(true);
    let sugestions = ref([]);
    let searchPopup = ref([]); //v-model autocomplete
    let arrayPoints = ref([]); //Itens selecionados para adicionar no mapa

    let isTrue = ref();

    let arrSelectedItems = ref([]);
    let optionsSelected = ref([]);
    let restaurants = ref();
    const groups = [...(GROUP_POINTS_COMPANY_2[user.data.idCompany] || []), ...GROUP_POINTS];
    let discriminator = ref(groups);
    console.log("grupos disponiveis: ", discriminator.value);
    const mapPopup = computed(() => store.getters["map/getMapPopup"]);

    const block = ref(0);

    watch(mapPopup, () => {
      isTrue.value = mapPopup.value;
      if (block.value == 0) {
        block.value = 1;
        for (let i = 0; discriminator.value.length > i; i++) {
          optionsSelected.value.push({
            value: discriminator.value[i].name,
          });
        }
      }
      if (groupsList.value.length > 0) {
        const addInArr = [];
        groupsList.value.forEach((selectedGroups) => {
          discriminator.value.forEach((group) => {
            group.discriminator === selectedGroups.discriminator ? addInArr.push(group) : null;
          });
        });
        arrSelectedItems.value = addInArr;
      }
      restaurants.value = loadAll();
    });

    // Restaura as variáveis do popup para o estado padrão
    const closePopup = () => {
      isTrue.value = false;
      showPopups.value = false;
      showMarkerConfig.value = true;
      (radio.value = 1), (arrSelectedItems.value = []);
      sugestions.value = [];
      store.dispatch("map/changeMapPopup", false);
    };

    // Fecha popUp da mensagem de erro quando não encontra CNAE
    const closePopUpButton= () => {
      showPopupError.value = false;
    };

    // Define se criará um ponto ou grupo de pontos
    const valueRadio = () => {
      if (radio.value == 1) {
        showPopups.value = false;
        store.dispatch("map/finishCompute");
        store.dispatch("map/changeMapState", "clickBtnMarker");
        closePopup();
      } else {
        showMarkerConfig.value = false;
        sugestions.value.push(
          // Sugestões
          {
            discriminator: "restaurantes",
            name: "Restaurantes",
            cnae: "5611201",
          },
          {
            discriminator: "banks",
            name: "Bancos",
            cnae: "6422100",
          },
          {
            discriminator: "hospitals",
            name: "Hospitais",
            cnae: "8610102",
          }
        );

        const removeObj = (arrObj, arrStrings) => {
          const objFiltered = arrObj.filter((obj) => {
            return !arrStrings.includes(obj.discriminator);
          });
          return objFiltered;
        };

        if (groupsList.value.length > 0) {
          let newSugestions = removeObj(sugestions.value, groupsList.value.map(string => string.discriminator));
          sugestions.value = newSugestions;
        }
      }
    };

    const querySearch = async (queryString, cb) => {
      clearTimeout(typingTimer.value)

      typingTimer.value = setTimeout(() => {
        let filteredObjx;
        const dropDownSearchs = ref([]);

        if (parseInt(queryString)) {
          filteredObjx = discriminator.value.filter((obj) => obj.cnae.includes(queryString));
        } else {
          filteredObjx = discriminator.value.filter((obj) => {
            const normalizedQueryString = removeAccents(transform(queryString).toLowerCase());
            const normalizedObjectName = removeAccents(transform(obj.name).toLowerCase());
            return normalizedObjectName.includes(normalizedQueryString);
          });
        }
        if(filteredObjx.length === 0){
          searchPopup.value = "";
          showPopupError.value = true;
          titleError.value = 'CNAE não encontrado';
          subtitleError.value = 'O CNAE buscado não está cadastrado em nossa base de dados. Para solicitar a inclusão, envie uma solicitação para:';
          buttonConfirmError.value = false;
          cnaeMessage.value = true;
        }
        filteredObjx.forEach((obj) => [
          dropDownSearchs.value.push({ value: `${obj.name} - ${obj.cnae}` }),
        ]);
        cb(transformProxy(dropDownSearchs.value));
      },1000)
    };

    const removeAccents = (str) => {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }
    
    const transformProxy = (params) => {
      return JSON.parse(JSON.stringify(params))
    }
    
    const transform = (text) => {
      return text.toLowerCase()
    }

    const loadAll = () => {
      return JSON.parse(JSON.stringify(optionsSelected.value));
    };

    const DadosGerais = computed(() => store.getters["map/getDataList"]);

    /* Adiciona no mapa cada item que foi selecionado */
    const groupsPointsAdd = async () => {      
      store.dispatch(
        "map/changeGroupList",
        arrSelectedItems.value.map((group) => group.discriminator)
      );
      await layerService.updateLayer(
        getFeatureCollection.value,
        arrSelectedItems.value.map((group) => group.discriminator),
        DadosGerais.value
      );
    };

    /* Remove os itens */
    const removeResultSearch = (itemToDelete) => {
      const idRemove = arrSelectedItems.value.findIndex((obj) => obj.name == itemToDelete);
      arrSelectedItems.value.splice(idRemove, 1);

      const idsRemove = arrayPoints.value.findIndex((obj) => obj.name == itemToDelete);
      arrayPoints.value.splice(idsRemove, 1);
    };

    const addSugestion = (event) => {
      if (event.target.tagName == "CHECK") {
        const sugestionClicked = event.currentTarget.getAttribute("discriminator");
        let doesThisAlreadyExist = arrSelectedItems.value.some(
          (obj) => obj.name === event.currentTarget.innerText.split(" - ")[0]
        );
        if (!doesThisAlreadyExist) {
          let deleteFromSugestion = sugestions.value.findIndex(
            (obj) => obj.discriminator == sugestionClicked
          );

          arrSelectedItems.value.push({
            id: discriminator.value.findIndex(
              (obj) => obj.name == event.currentTarget.innerText.split(" - ")[0]
            ),
            discriminator: sugestions.value.filter(
              (obj) => obj.discriminator == sugestionClicked
            )[0].discriminator,
            name: sugestions.value.filter((obj) => obj.discriminator == sugestionClicked)[0].name,
            cnae: sugestions.value.filter((obj) => obj.discriminator == sugestionClicked)[0].cnae,
          });
          sugestions.value.splice(deleteFromSugestion, 1);
        }
        arrayPoints.value.push(sugestionClicked);
      }
    };

    /* Adiciona os itens que foram clicadoos pelo autocomplete */
    const addResultSearch = () => {
      const selectedCnae = searchPopup.value.split(" - ")[1];

      let getName = discriminator.value.filter((obj) => obj.cnae === selectedCnae);
      // let idDiscriminator = Object.keys(getName[0])[0] //Pega o discriminator do elemento clicado

      let isThereThisPoint = arrSelectedItems.value.some((obj) => obj.cnae == selectedCnae); //Verifica se já existe o item adicionado
      console.log("grupo selecionado: ", getName, getName[0].name);
      if (!isThereThisPoint) {
        arrSelectedItems.value.push({
          id: discriminator.value.findIndex((obj) => obj.cnae === selectedCnae),
          discriminator: getName[0].discriminator,
          name: getName[0].name,
          cnae: getName[0].cnae,
        });
        // arrSelectedItems.value.push(idDiscriminator)

        arrayPoints.value.push(getName[0].discriminator);
        searchPopup.value = "";
        removeSugestion(getName[0].discriminator);
      }
    };

    const removeSugestion = (discriminator) => {
      const sugestionClicked = sugestions.value.filter(
        (obj) => obj.discriminator === discriminator
      );

      if (sugestionClicked[0]) {
        const index = sugestions.value.findIndex(
          (obj) => obj.discriminator == sugestionClicked[0].discriminator
        );
        sugestions.value.splice(index, 1);
      }
    };

    return {
      showMarkerConfig,
      showPopups,
      sugestions,
      mapPopup,
      radio,
      discriminator,
      isTrue,
      arrSelectedItems,
      searchPopup,
      arrayPoints,
      valueRadio,
      closePopup,
      querySearch,
      addSugestion,
      groupsPointsAdd,
      removeResultSearch,
      addResultSearch,
      ErrorMessages,
      closePopUpButton,
      showPopupError,
      titleError,
      subtitleError,
      buttonConfirmError,
      cnaeMessage,
      typingTimer
    };
  },
};
</script>

<style>
.barra-pesquisa_mark .el-input__inner {
  padding: 10px !important;
  padding-left: 12px;
}
</style>

<style scoped>
/* Popups */
.popups {
  position: fixed;
  width: calc(100% - 312px);
  height: calc(100vh - 50px);
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  right: 0;
}

/* Marker Config */
.container__popup-config {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 400px;
  height: max-content;
  padding: 15px;
  background: var(--dm_creme_02);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
}

/* Grup of points */
.container__popup-grup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 400px;
  height: max-content;
  padding: 15px;
  background: var(--dm_creme_02);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
}

.search-bar {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
}

.items_list {
  display: flex;
  justify-content: space-between;
}

.container_item {
  display: flex;
  gap: 10px;
  align-items: center;
}

.item_in_list {
  font-size: 16px;
}
[Search] {
  position: absolute;
  right: 10px;
}
.popups__sugestion,
.popups__selected {
  display: flex;
  flex-direction: column;
  margin: 5px;
  gap: 5px;
}

.popups__selected {
  width: 100%;
}

.popups__area {
  max-height: 130px;
  width: 100%;
  overflow-y: scroll;
}
.popups__sugestion--item {
  display: flex;
  align-items: center;
  gap: 6px;
  width: 100%;
}

.popups__sugestion--item span {
  word-break: break-word;
  width: 100%;
}

.popups__marker {
  width: 20px;
}

.search-bar .el-input__wrapper {
  padding: 0 5px !important;
  width: 100%;
}

.barra-pesquisa_mark {
  width: 100% !important;
}

.barra-pesquisa_mark .el-input__wrapper {
  border-radius: 6px !important;
  border: 1px !important;
  border-color: var(--dm_cinza_03);
  padding: 0 60px 0 10px !important;
  width: 100% !important;
}

/* Btns */
.popups__buttons {
  margin-top: 20px;
}
</style>
