import axios from "axios";
import MyFeature from "../models/feature";
import propertiesModel from "../models/properties";
import { API_URL } from "@/modules/bi/helpers/constants";
import { DATA_TO_ADD_LEADS } from "../helpers/constants";

export class FeatureService {
  constructor(user) {
    this.user = user;
    this.requestsPaused = false;
    this.pendingRequests = [];
    this.url = API_URL;
  }

  async searchFeaturesByProperty(search, token) {
    const response = await axios.get(`${this.url}/companies/1/features?search=${search}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response) {
      const features = response.data;
      return features.map(
        (feature) =>
          new MyFeature(
            feature.id,
            this.user.data.idCompany,
            {},
            feature.geojson.properties,
            feature.geojson.properties.discriminator
          )
      );
    }
  }

  async createFeature(geometry, discriminator, properties, fromLeads = false) {
    console.log(geometry, discriminator, properties);
    const changeProperties = () => {
      let newProperties = [];
      for (const [key, value] of Object.entries(properties)) {
        if (fromLeads) {
          DATA_TO_ADD_LEADS.forEach((keyLead) => {
            if (keyLead === key)
              newProperties.push(new propertiesModel(key, `${key === 'displayName' ? value.text : value}`, "string").toJSON());
          });
        } else newProperties.push(new propertiesModel(key, `${value}`, "string").toJSON());
      }
      return newProperties;
    };
    try {
      const response = await axios.post(
        `${this.url}/features?create_properties=true`,
        {
          idCompany: this.user.data.idCompany,
          geometry: geometry,
          discriminator: discriminator,
          properties: changeProperties(),
        },
        {
          headers: {
            Authorization: `Bearer ${this.user.token}`,
          },
        }
      );
      response.data.geojson.properties.radius
        ? (response.data.geojson.properties.radius = parseInt(
            response.data.geojson.properties.radius
          ))
        : 0;
      return response.data;
    } catch (e) {
      console.error(e);
    }
  }

  async getFeatureByDiscriminator(discriminator, token, flagPublic = true) {
    const response = await axios.get(
      `${this.url}/companies/${this.user.data.idCompany}/features?discriminator=${discriminator}&onlyPublic=${flagPublic}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response) {
      const features = response.data;
      return features.map(
        (feature) =>
          new MyFeature(
            feature.id,
            this.user.data.idCompany,
            feature.geojson.geometry ? feature.geojson.geometry : {},
            feature.geojson.properties,
            discriminator
          )
      );
    }
  }

  async getDiscriminators() {
    const response = await axios.get(`${this.url}/properties/discriminators`, {
      headers: {
        Authorization: `Bearer ${this.user.token}`,
      },
    });
    if (response) {
      const features = response.data;
      return features;
    }
  }

  async getFeatureByDiscriminatorContainedFeatures(idFeature, token, discriminator) {
    const response = await axios.get(
      `${this.url}/companies/${this.user.data.idCompany}/features/${idFeature}/contained-features/${discriminator}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response) {
      const features = response.data;
      return features.map(
        (feature) =>
          new MyFeature(
            feature.id,
            this.user.data.idCompany,
            feature.geojson.geometry ? feature.geojson.geometry : {},
            feature.geojson.properties,
            discriminator
          )
      );
    }
  }

  async getFeatureByDiscriminatorWithin(idFeature, token, discriminator) {
    // lugares dentro de um id -> Ex: São Paulo - 35
    const response = await axios.get(
      `${this.url}/companies/${this.user.data.idCompany}/features/${idFeature}?within=${discriminator}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response) {
      const feature = response.data;
      return new MyFeature(
        feature.id,
        this.user.data.idCompany,
        feature.geojson.geometry ? feature.geojson.geometry : {},
        feature.geojson.properties,
        discriminator
      );
    }
  }

  async getFeatureCalculateArea(idFeature, token) {
    const response = await axios.get(
      `${this.url}/features/${idFeature}/properties/calculate-area`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response) {
      return response.data?.value ?? "";
    }
  }

  async getFeatureByCNAEOrCNPJ(stringSearch = "", typeSearch, token) {
    try {
      const response = await axios.get(
        `${this.url}/companies/${this.user.data.idCompany}/features?search=${stringSearch}&name=${typeSearch}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response) {
        return response.data[0].discriminator ?? null;
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async getFeatureByDiscriminatorQueryString(stringDiscriminator, token, queryString, flagPublic) {
    const response = await axios.get(
      `${this.url}/companies/${this.user.data.idCompany}/features?${stringDiscriminator}&search=${queryString}&onlyPublic=${flagPublic}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response) {
      const features = response.data;
      return features.map(
        (feature) =>
          new MyFeature(
            feature.id,
            this.user.data.idCompany,
            {},
            feature.geojson.properties,
            feature.discriminator
          )
      );
    }
  }

  async updateFeatureProperties(featureProperty, token) {
    try {
      const response = await axios.put(
        `${this.url}/properties`,
        {
          ...featureProperty,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (e) {
      console.log(e);
    }
  }

  async getFeatureById(id, token) {
    const response = await axios.get(
      `${this.url}/companies/${this.user.data.idCompany}/features/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response) {
      const feature = response.data;
      return new MyFeature(
        feature.id,
        this.user.data.idCompany,
        feature.geojson.geometry,
        feature.geojson.properties,
        "study",
        feature.properties
      );
    }
  }

  async deleteFeatureById(idFeature) {
    try {
      const response = await axios.delete(
        `${this.url}/companies/${this.user.data.idCompany}/features/${idFeature}`,
        {
          headers: {
            Authorization: `Bearer ${this.user.token}`,
          },
        }
      );
      return response;
    } catch (e) {
      console.log(e);
    }
  }
}

const featureService = new FeatureService();

export default featureService;
