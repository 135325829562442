
import store from '../../store';

export default function authGuard(to, from, next) {
  if ((!to.meta.requiresAuth) || (store.getters['auth/isAuthenticated'] && !store.getters['auth/isTokenExpired'])) {
    next();
  } else {
    if (to.meta.middleware) {
      to.meta.middleware({ store, next, to });
    } else {
      next({ name: 'AuthLogin' });
    }
  }
}