import studyIcon from "../assets/study.svg";
import pointsIcon from "../assets/points.svg";
export const POINTS_SOURCE = "points-group";
export const STATE_SOURCE = "map-coropletico-source";
export const STATE_LAYER = "map-layer-state";
export const MUNI_SOURCE = "map-coropletico-muni-source";
export const MUNI_LAYER = "map-layer-municipios";
export const SECTOR_SOURCE = "map-coropletico-sector-source";
export const SECTOR_LAYER = "map-layer-sector";
export const LEADS_SOURCE = "map-leads-source";
export const LEADS_LAYER = "leads-layer";
export const ESTADO = "states";
export const SETOR_CENSITARIO = "census tracts";
export const MUNICIPIO = "counties";
export const NOT_VALUE = "not_value";
export const RENDA = "renda nominal mensal";
export const DOMICILIOS = "numero de domicilios";
export const RESIDENTES = "numero de residentes";
export const CLASSES = "classe";
export const POP_CRIANCAS = "crianças";
export const POP_JOVENS = "jovens";
export const POP_ADULTOS = "adultos";
export const POP_IDOSOS = "idosos";
export const MORTES_HOMENS = "mortes homens";
export const MORTES_MULHERES = "mortes mulheres";
export const TOTAL_MORTES = "total mortes";
export const MORTES_DOMICILIOS = "mortes domicilio";
export const RENDA_HOMENS = "renda nominal mensal homens";
export const RENDA_MULHERES = "renda nominal mensal mulhres";
export const PIB = "pib a precos correntes";
export const STYLE_POINTS = ["Marker", "Studyarea"];
export const POINTS_NAME = ["razao social"];
export const CAPITAL_SOCIAL = ["capital social"];
export const CENSUS_BLOCK = [
  "mortes domicilio",
  "mortes homens",
  "mortes mulheres",
  "total mortes",
  "pib a precos correntes",
];
export const DATA_TO_ADD_LEADS = [
  "businessStatus",
  "displayName",
  "googleMapsUri",
  "priceLevel",
  "rating",
  "userRatingCount",
  "websiteUri",
  "shortFormattedAddress",
  "style",
  "visibility",
  "nationalPhoneNumber",
  // "primaryType",
  "name",
];
export const PRICES_LEVEL = {
  PRICE_LEVEL_UNSPECIFIED: "Não especificado",
  PRICE_LEVEL_FREE: "$",
  PRICE_LEVEL_INEXPENSIVE: "$",
  PRICE_LEVEL_MODERATE: "$$",
  PRICE_LEVEL_EXPENSIVE: "$$$",
  PRICE_LEVEL_VERY_EXPENSIVE: "$$$$",
};
export const CLASSIFICATION_PRICE_LEVEL = {
  PRICE_LEVEL_UNSPECIFIED: 0,
  PRICE_LEVEL_FREE: 1,
  PRICE_LEVEL_INEXPENSIVE: 2,
  PRICE_LEVEL_MODERATE: 3,
  PRICE_LEVEL_EXPENSIVE: 4,
  PRICE_LEVEL_VERY_EXPENSIVE: 5,
};

export const DROPDOWN_RATING = [
  { label: "Qualquer classificação", value: undefined },
  { label: "0,0", value: 0 },
  { label: "0,5", value: 0.5 },
  { label: "1,0", value: 1 },
  { label: "1,5", value: 1.5 },
  { label: "2,0", value: 2 },
  { label: "2,5", value: 2.5 },
  { label: "3,0", value: 3 },
  { label: "3,5", value: 3.5 },
  { label: "4,0", value: 4 },
  { label: "4,5", value: 4.5 },
  { label: "5,0", value: 5 },
];

export const DROPDOWN_PRICE = [
  { label: "Qualquer preço", value: undefined },
  { label: "$", value: "PRICE_LEVEL_INEXPENSIVE" },
  { label: "$  $", value: "PRICE_LEVEL_MODERATE" },
  { label: "$  $  $", value: "PRICE_LEVEL_EXPENSIVE" },
  { label: "$  $  $  $", value: "PRICE_LEVEL_VERY_EXPENSIVE" },
];

export const DROPDOWN_STATS = [
  { label: "Qualquer status", value: undefined },
  { label: "Aberto", value: "OPERATIONAL" },
  { label: "Fechado", value: "CLOSED" },
];

export const DICIONARY_TYPES = [
  { label: "Contabilidade", value: "accounting" },
  { label: "Aeroporto", value: "airport" },
  { label: "Parque de diversões", value: "amusement_park" },
  { label: "Aquário", value: "aquarium" },
  { label: "Galeria de arte", value: "art_gallery" },
  { label: "Caixa eletrônico", value: "atm" },
  { label: "Padaria", value: "bakery" },
  { label: "Banco", value: "bank" },
  { label: "Bar", value: "bar" },
  { label: "Salão de beleza", value: "beauty_salon" },
  { label: "Loja de bicicletas", value: "bicycle_store" },
  { label: "Livraria", value: "book_store" },
  { label: "Pista de boliche", value: "bowling_alley" },
  { label: "Estação de ônibus", value: "bus_station" },
  { label: "Café", value: "cafe" },
  { label: "Acampamento", value: "campground" },
  { label: "Revendedor de carros", value: "car_dealer" },
  { label: "Auguel de carros", value: "car_rental" },
  { label: "Conserto de carros", value: "car_repair" },
  { label: "Lavagem de carros", value: "car_wash" },
  { label: "Cassino", value: "casino" },
  { label: "Cemitério", value: "cemetery" },
  { label: "Igreja", value: "church" },
  { label: "Prefeitura", value: "city_hall" },
  { label: "Loja de roupas", value: "clothing_store" },
  { label: "Loja de conveniência", value: "convenience_store" },
  { label: "Tribunal", value: "courthouse" },
  { label: "Dentista", value: "dentist" },
  { label: "Loja de departamentos", value: "department_store" },
  { label: "Médico", value: "doctor" },
  { label: "Drogaria", value: "drugstore" },
  { label: "Eletricista", value: "electrician" },
  { label: "Loja de eletrônicos", value: "electronics_store" },
  { label: "Embaixada", value: "embassy" },
  { label: "Corpo de bombeiros", value: "fire_station" },
  { label: "Florista", value: "florist" },
  { label: "Casa funerária", value: "funeral_home" },
  { label: "Loja de móveis", value: "furniture_store" },
  { label: "Posto de gasolina", value: "gas_station" },
  { label: "Academia", value: "gym" },
  { label: "Cuidados com o cabelo", value: "hair_care" },
  { label: "Loja de ferragens", value: "hardware_store" },
  { label: "Templo hindu", value: "hindu_temple" },
  { label: "Loja de produtos para casa", value: "home_goods_store" },
  { label: "Hospital", value: "hospital" },
  { label: "Agência de seguros", value: "insurance_agency" },
  { label: "Loja de joias", value: "jewelry_store" },
  { label: "Lavanderia", value: "laundry" },
  { label: "Advogado", value: "lawyer" },
  { label: "Biblioteca", value: "library" },
  { label: "Estação de metrô", value: "light_rail_station" },
  { label: "Loja de bebidas", value: "liquor_store" },
  { label: "Escritório do governo local", value: "local_government_office" },
  { label: "Serralheiro", value: "locksmith" },
  { label: "Alojamento", value: "lodging" },
  { label: "Entrega de refeição", value: "meal_delivery" },
  { label: "Refeição para viagem", value: "meal_takeaway" },
  { label: "Mesquita", value: "mosque" },
  { label: "Aluguel de filme", value: "movie_rental" },
  { label: "Cinema", value: "movie_theater" },
  { label: "Empresa de mudança", value: "moving_company" },
  { label: "Museu", value: "museum" },
  { label: "Boate", value: "night_club" },
  { label: "Pintor", value: "painter" },
  { label: "Parque", value: "park" },
  { label: "Estacionamento", value: "parking" },
  { label: "Pet shop", value: "pet_store" },
  { label: "Farmácia", value: "pharmacy" },
  { label: "Fisioterapeuta", value: "physiotherapist" },
  { label: "Encanador", value: "plumber" },
  { label: "Polícia", value: "police" },
  { label: "Correios", value: "post_office" },
  { label: "Escola primária", value: "primary_school" },
  { label: "Agência imobiliária", value: "real_estate_agency" },
  { label: "Restaurante", value: "restaurant" },
  { label: "Empreiteiro de telhados", value: "roofing_contractor" },
  { label: "RV Park", value: "rv_park" },
  { label: "Escola", value: "school" },
  { label: "Escola secundária", value: "secondary_school" },
  { label: "Loja de sapatos", value: "shoe_store" },
  { label: "Shopping", value: "shopping_mall" },
  { label: "Spa", value: "spa" },
  { label: "Estádio", value: "stadium" },
  { label: "Armazenamento", value: "storage" },
  { label: "Loja", value: "store" },
  { label: "Estação de metrô", value: "subway_station" },
  { label: "Supermercado", value: "supermarket" },
  { label: "Sinagoga", value: "synagogue" },
  { label: "Ponto de táxi", value: "taxi_stand" },
  { label: "Atração turística", value: "tourist_attraction" },
  { label: "Estação de trem", value: "train_station" },
  { label: "Estação de trânsito", value: "transit_station" },
  { label: "Agência de viagens", value: "travel_agency" },
  { label: "Universidade", value: "university" },
  { label: "Atendimento veterinário", value: "veterinary_care" },
  { label: "Zoológico", value: "zoo" },
];

export const TO_CAPITALIZE = [
  "da",
  "das",
  "de",
  "des",
  "di",
  "do",
  "dos",
  "du",
  "dus",
  "na",
  "nas",
  "ne",
  "ni",
  "no",
  "nos",
  "nu",
];
export const OPTIONS = [
  // {
  //   value: 'Endereço',
  //   discriminator: 'others',
  // },
  {
    value: "Área de estudo",
    discriminator: "study",
    icon: studyIcon,
  },
  {
    value: "Ponto de interesse",
    discriminator: "points",
    icon: pointsIcon,
  },
  {
    value: "Estado/Município",
    discriminator: "counties_states",
  },
];

export const STYLE_POINTS_RESULT = {
  points: "Ponto único",
  study: "Área de estudo",
  group_zelo: "Grupo Zelo",
  competitors: "Competidores",
  cemeteries: "Cemitérios",
  hospitals: "Hospitais",
  funeral_homes: "Funerárias",
  medical_clinics: "Clínicas Médicas",
  churches: "Igrejas",
  dental_clinics: "Clínicas Dentais",
  labs_clinics: "Lab. Clínicos",
  banks: "Bancos",
  comercio_varejista_ferragens_ferramentas: "Comércio Varejista de Ferragens e Ferramentas",
  comercio_varejista_madeira_derivados: "Comércio Varejista de Madeira e Derivados",
  comercio_atacadista_ferragens_ferramentas: "Comércio Atacadista de Ferragens e Ferramentas",
  comercio_atacadista_madeira_derivados: "Comércio Atacadista de Madeira e Derivados",
  bares_com_entreterimento: "Bares com entretenimento",
  bares_sem_entreterimento: "Bares sem entretenimento",
  lanchonetes: "Lanchonetes",
  comercio_atacadista_vestuario_acessorios: "Comércio atacadista de artigos do vestuário e acessórios",
  comercio_varejista_vestuario_acessorios: "Comércio varejista de artigos do vestuário e acessórios",
  comercio_varejista_calcados: "Comércio varejista calçados",
  comercio_varejista_artigos_viagem: "Comércio varejista artigos de viagem",
  comercio_varejista_suvenires_bijuterias_artesanato: "Comércio varejista suvenires, bijuterias e artesanato",
};
export const ALPHABET = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];
export const DTYPE = {
  [RENDA]: { style: "currency" },
  [DOMICILIOS]: { style: "decimal", minimum: 0, maximum: 0 },
  [RESIDENTES]: { style: "decimal", minimum: 0, maximum: 0 },
  [PIB]: { style: "currency" },
  [CLASSES]: { style: "currency" },
  [POP_CRIANCAS]: { style: "decimal", minimum: 0, maximum: 0 },
  [POP_JOVENS]: { style: "decimal", minimum: 0, maximum: 0 },
  [POP_ADULTOS]: { style: "decimal", minimum: 0, maximum: 0 },
  [POP_IDOSOS]: { style: "decimal", minimum: 0, maximum: 0 },
  [MORTES_HOMENS]: { style: "decimal", minimum: 0, maximum: 0 },
  [MORTES_MULHERES]: { style: "decimal", minimum: 0, maximum: 0 },
  [TOTAL_MORTES]: { style: "decimal", minimum: 0, maximum: 0 },
  [MORTES_DOMICILIOS]: { style: "decimal", minimum: 6, maximum: 6 },
};
export const COLORS_PINS = {
  //De acordo com a ordem, o preenchimento receberá a da borda da const "COLORS_PINS_BORDER".
  "--dm_laranja_00": "#F25900", //1°
  "--dm_amarelo_00": "#FFA400", //2°
  "--dm_verde_00": "#3E9A00", //3°
  "--dm_lilas_00": "#9E6CFF", //4°
  "--dm_roxo_C03": "#68008C", //5°
  "--dm_verde_C02": "#2BDE02", //6°
  "--dm_amarelo_C03": "#F1E80C", //7°
  "--dm_azul_C04": "#02DEDA", //8°
  "--dm_azul_00": "#0567FF", //9°
  "--dm_rosa_C03": "#FA0060", //10°
  "--dm_azul_00": "#0567FF", //11° Não confirmado
  "--dm_rosa_C03": "#FA0060", //12° Não confirmado
};
export const COLORS_PINS_BORDER = {
  //De acordo com a ordem, a borda receberá o preenchimento da const "COLORS_PINS".
  "--dm_laranja6_C07": "#A63603", //1°
  "--dm_amarelo_CT01": "#956816", //2°
  "--dm_verde_CT01": "#356316", //3°
  "--dm_lilas_CT01": "#654C95", //4°
  "--dm_lilas_CT01_01": "#654C95", //5°
  "--dm_verde_C02": "#356316", //6°
  "--dm_amarelo_CT01_01": "#956816", //7°
  "--dm_azul_CT01": "#184995", //8°
  "--dm_azul_CT01_01": "#184995", //9°
  "--dm_rosa_CT01": "#903E61", //10°
  "--dm_azul_CT01_01": "#184995", //11° Não confirmado
  "--dm_rosa_CT01": "#903E61", //12° Não confirmado
};
export const COLORS = {
  [RENDA]: ["#C6DFF8", "#9ECAE1", "#6BAED6", "#3182BD", "#08519C", "#003C82"],
  [DOMICILIOS]: ["#C6DFF8", "#9ECAE1", "#6BAED6", "#3182BD", "#08519C", "#003C82"],
  [RESIDENTES]: ["#C6DFF8", "#9ECAE1", "#6BAED6", "#3182BD", "#08519C", "#003C82"],
  [PIB]: ["#C6DFF8", "#9ECAE1", "#6BAED6", "#3182BD", "#08519C", "#003C82"],
  [CLASSES]: ["#C6DFF8", "#6BAED6", "#003C82"],
  [PIB]: ["#C6DFF8", "#9ECAE1", "#6BAED6", "#3182BD", "#08519C", "#003C82"],
  [POP_CRIANCAS]: ["#C6DFF8", "#9ECAE1", "#6BAED6", "#3182BD", "#08519C", "#003C82"],
  [POP_JOVENS]: ["#C6DFF8", "#9ECAE1", "#6BAED6", "#3182BD", "#08519C", "#003C82"],
  [POP_ADULTOS]: ["#C6DFF8", "#9ECAE1", "#6BAED6", "#3182BD", "#08519C", "#003C82"],
  [POP_IDOSOS]: ["#C6DFF8", "#9ECAE1", "#6BAED6", "#3182BD", "#08519C", "#003C82"],
  [MORTES_HOMENS]: ["#C6DFF8", "#9ECAE1", "#6BAED6", "#3182BD", "#08519C", "#003C82"],
  [MORTES_MULHERES]: ["#C6DFF8", "#9ECAE1", "#6BAED6", "#3182BD", "#08519C", "#003C82"],
  [TOTAL_MORTES]: ["#C6DFF8", "#9ECAE1", "#6BAED6", "#3182BD", "#08519C", "#003C82"],
  [MORTES_DOMICILIOS]: ["#C6DFF8", "#9ECAE1", "#6BAED6", "#3182BD", "#08519C", "#003C82"],
};

export const CLASSES_LEGENDA = ["renda baixa", "renda média", "renda alta"];
export const ID_LAYERS = ["map-layer-state", "map-layer-municipios", "map-layer-sector"];
export const TRADUCTOR = {
  counties: "Município",
  points: "Ponto único",
  states: "Estado",
  group_zelo: "Grupo Zelo",
  competitors: "Competidores",
  cemeteries: "Cemitérios",
  hospitals: "Hospitais",
  funeral_homes: "Funerárias",
  medical_clinics: "Clínicas Médicas",
  churches: "Igrejas",
  dental_clinics: "Clínicas Dentais",
  labs_clinics: "Lab. Clínicos",
  restaurantes: "Restaurantes",
  banks: "Bancos",
  comercio_varejista_ferragens_ferramentas: "Comércio Varejista de Ferragens e Ferramentas",
  comercio_varejista_madeira_derivados: "Comércio Varejista de Madeira e Derivados",
  comercio_atacadista_ferragens_ferramentas: "Comércio Atacadista de Ferragens e Ferramentas",
  comercio_atacadista_madeira_derivados: "Comércio Atacadista de Madeira e Derivados",
  bares_com_entreterimento: "Bares com entretenimento",
  bares_sem_entreterimento: "Bares sem entretenimento",
  lanchonetes: "Lanchonetes",
  // comercio_atacadista_vestuario_acessorios: "Comércio atacadista de artigos do vestuário e acessórios",
  // comercio_varejista_vestuario_acessorios: "Comércio varejista de artigos do vestuário e acessórios",
  comercio_varejista_calcados: "Comércio varejista calçados",
  comercio_varejista_artigos_viagem: "Comércio varejista artigos de viagem",
  comercio_varejista_suvenires_bijuterias_artesanato: "Comércio varejista suvenires, bijuterias e artesanato",
};

export const GROUP_POINTS = [
  { name: "Cemitérios", discriminator: "cemeteries", cnae: "9603301" },
  { name: "Hospitais", discriminator: "hospitals", cnae: "8610102" },
  { name: "Funerárias", discriminator: "funeral_homes", cnae: "9603304" },
  { name: "Clínicas Médicas", discriminator: "medical_clinics", cnae: "8610101" },
  { name: "Igrejas", discriminator: "churches", cnae: "9491000" },
  { name: "Clínicas Dentais", discriminator: "dental_clinics", cnae: "8630504" },
  { name: "Lab. Clínicos", discriminator: "labs_clinics", cnae: "8640202" },
  { name: "Bancos", discriminator: "banks", cnae: "6422100" },
  { name: "Restaurantes", discriminator: "restaurantes", cnae: "5611201" },
  {
    name: "Comércio Varejista de Ferragens e Ferramentas",
    discriminator: "comercio_varejista_ferragens_ferramentas",
    cnae: "4744002",
  },
  {
    name: "Comércio Varejista de Madeira e Derivados",
    discriminator: "comercio_varejista_madeira_derivados",
    cnae: "4744001",
  },
  {
    name: "Comércio Atacadista de Ferragens e Ferramentas",
    discriminator: "comercio_atacadista_ferragens_ferramentas",
    cnae: "4672900",
  },
  {
    name: "Comércio Atacadista de Madeira e Derivados",
    discriminator: "comercio_atacadista_madeira_derivados",
    cnae: "4671100",
  },
  { name: "Bares com entretenimento", discriminator: "bares_com_entreterimento", cnae: "5611205" },
  { name: "Bares sem entretenimento", discriminator: "bares_sem_entreterimento", cnae: "5611204" },
  { name: "Lanchonetes", discriminator: "lanchonetes", cnae: "5611203" },
  // { name: "Comércio varejista de artigos do vestuário e acessórios", discriminator: "comercio_varejista_vestuario_acessorios", cnae: "4781400" },
  // { name: "Comércio atacadista de artigos do vestuário e acessórios", discriminator: "comercio_atacadista_vestuario_acessorios", cnae: "4642100" },
  { name: "Comércio varejista calçados", discriminator: "comercio_varejista_calcados", cnae: "4782201" },
  { name: "Comércio varejista suvenires, bijuterias e artesanato", discriminator: "comercio_varejista_suvenires_bijuterias_artesanato", cnae: "4789001" },
  { name: "Comércio varejista artigos de viagem", discriminator: "comercio_varejista_artigos_viagem", cnae: "4782202" },
];

export const GROUP_POINTS_COMPANY = [
  { name: "Grupo Zelo", discriminator: "group_zelo", cnae: "81236" }, // cnaes errados
  { name: "Competidores", discriminator: "competitors", cnae: "81296" }, // cnaes errados
  { name: "BHR", discriminator: "bhr", cnae: "81297" }, // cnaes errados
];

export const GROUP_POINTS_COMPANY_2 = {
  3: [
      { name: "Grupo Zelo", discriminator: "group_zelo", cnae: "81236" }, // cnaes errados
      { name: "Competidores", discriminator: "competitors", cnae: "81296" }, // cnaes errados
    ],
  1: [
      { name: "BHR", discriminator: "BHR", cnae: "81297" }, // cnaes errados
    ],

  5: [
    { name: "BHR", discriminator: "BHR", cnae: "81297" }, // cnaes errados
  ],
}
