export default {
  getMapActualState(state) {
    return state.map.state.getActualState();
  },
  getVisualization(state) {
    return state.map.visualization;
  },
  getZoomVisualization(state) {
    return state.map.visualizationZoom;
  },
  getLegend(state) {
    return state.map.legend;
  },
  getUpdateGroups(state) {
    return state.map.groupsList;
  },
  getCompanyGroups(state) {
    return state.map.groupsCompany;
  },
  getMapLoader(state) {
    return state.map.loader;
  },
  getAside(state) {
    return state.map.aside;
  },
  getLegendPosition(state) {
    return state.map.legendPosition;
  },
  getPointInter(state) {
    return state.map.pointInter;
  },
  getMapPopup(state) {
    return state.map.mapPopup;
  },
  getLeads(state) {
    return state.map.leads;
  },
  getGroupList(state) {
    return state.map.groupList;
  },
  getUpdateMarkerMenu(state) {
    return state.map.updateMarkerMenu;
  },
  getDataList(state) {
    return state.map.dataList;
  },
  getIsItMarker(state){
    return state.map.isItMarker;
  },
  getPipedrive(state){
    return state.map.popupPipedrive;
  },
  getDeleteArea(state){
    return state.map.deleteArea;
  },
  getFeatureCollection(state){
    return state.featureCollection;
  },
  getAlert(state){
    return state.map.alert;
  },
};
