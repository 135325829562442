import {
  CHANGE_STATE_MAP,
  FINISH_COMPUTE,
  CHANGE_VISUALIZATION,
  CHANGE_LEGEND,
  CHANGE_ASIDE,
  CHANGE_ZOOM_VISUALIZATION,
  MAP_LOADER,
  UPDATE_GROUPS,
  COMPANY_GROUPS,
  CHANGE_LEGEND_POSITION,
  CHANGE_POINT_INTER,
  MAP_POPUP,
  IS_MARKER,
  MAP_GROUP_LIST,
  MAP_DATA_LIST,
  MAP_PIPEDRIVE_POPUP,
  CHANGE_LEADS,
  UPDATE_MARKER_MENU,
  MAP_DELETE_AREA,
  SET_FEATURE_COLLECTION,
  HANDLE_ALERT
} from './mutations-types'

export default {
  changeMapState(context, input) {
    context.commit(CHANGE_STATE_MAP, input);
  },
  finishCompute(context) {
    context.commit(FINISH_COMPUTE);
  },
  mapLoader(context, input) {
    context.commit(MAP_LOADER, input);
  },
  changeVisualization(context, input) {
    context.commit(CHANGE_VISUALIZATION, input);
  },
  changeZoomVisualization(context, input) {
    context.commit(CHANGE_ZOOM_VISUALIZATION, input);
  },
  updateGroups(context, input) {
    context.commit(UPDATE_GROUPS, input);
  },
  changeCompanyGroups(context, input) {
    context.commit(COMPANY_GROUPS, input);
  },
  changeLegend(context, input) {
    context.commit(CHANGE_LEGEND, input);
  },
  changeAside(context, input) {
    context.commit(CHANGE_ASIDE, input);
  },
  changeLeads(context, input) {
    context.commit(CHANGE_LEADS, input);
  },
  changeLegendPosition(context, input) {
    context.commit(CHANGE_LEGEND_POSITION, input);
  },
  changePointInter(context, input) {
    context.commit(CHANGE_POINT_INTER, input);
  },
  changeMapPopup(context, input) {
    context.commit(MAP_POPUP, input);
  },
  updateMarkerMenu(context, input) {
    context.commit(UPDATE_MARKER_MENU, input);
  },
  changeIsMarker(context, input){
    context.commit(IS_MARKER, input)
  },
  changeGroupList(context, input) {
    context.commit(MAP_GROUP_LIST, input);
  },
  changeDataList(context, input) {
    context.commit(MAP_DATA_LIST, input);
  },
  changePopupPipedrive(context, input) {
    context.commit(MAP_PIPEDRIVE_POPUP, input);
  },
  changeDeleteArea(context, input) {
    context.commit(MAP_DELETE_AREA, input);
  },
  setFeatureCollection(context, input) {
    context.commit(SET_FEATURE_COLLECTION, input);
  },
  handleAlert(context, input) {
    context.commit(HANDLE_ALERT, input);
  },
};
