const Module = () => import('../ModuleAuth.vue');
const AuthView = () => import('../views/AuthView.vue');

const moduleRoute = {
  name: 'Auth',
  path: '/auth',
  component: Module,
  children: [
    {
      name: 'AuthLogin',
      path: '',
      component: AuthView,
    },
  ],
};

export default (router, parentRoute = null) => {
  if (parentRoute == null) router.addRoute(moduleRoute);
  else router.addRoute(parentRoute, moduleRoute);
};
