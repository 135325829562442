export const floatFormatter = (number) => {
  return number.toLocaleString('pt-br', { style: 'decimal', useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2});
};

export const percentFormatter = (percent) => {
  return percent.toLocaleString('pt-br', { style: 'percent', useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2});
}

export const miFormatter = (number) => {
  return number.toLocaleString('pt-br').replace(/,/g, '');
}

export const currencyFormatter = (currency) => {
  return currency.toLocaleString('pt-br', { style: 'currency', useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2, currency:'BRL' });
}

export const formatter = (number, { style='decimal', minimum=2, maximum=2 } ) => {
  return number.toLocaleString('pt-br', { style, useGrouping: true, minimumFractionDigits: minimum, maximumFractionDigits: maximum, currency:'BRL' });
}