export const API_URL = 'https://api.mapalytics.datamachina.com.br/mapalytics';
export const [PERCENTAGE, PRICE, THOUSANDS] = ['percentage', 'price','thousands'];
export const [AVERAGE, AMOUNT, COUNT] = ['average', 'amount', 'count'];
export const [ACTIVE, CANCELED] = ['ATIVO', 'CANCELADO'];
export const PERCENTAGE_INT = 'percentage_int';
export const [DAY, WEEK, MONTH] = ["daily", "weekly", "monthly"];
export const MONTHS = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"];
export const HEX_COLORS = ["#F45197", "#9E6CFF", "#0567FF", "#3E9A00", "#F25900", "#F45197", "#0567FF"];
export const TABLE_ORDER = {'membershipValue': 'membershipContractsValue', 'contracts': 'numberContracts'};
export const TABLE_FILTERS_VALUES = {
    membershipContractsValue: 'Receita (R$)',
    numberContracts: 'Qtd. de contratos ativos'
}
export const TABLE_FILTERS_PEOPLES = {
    directors: 'Diretores',
    sellers: 'Vendedores',
    coordinators: 'Coordenadores',
    supervisors: 'Supervisores',
}
export const KEYS_TO_GRAPH_LINE = {
    'dependentsNumber': 'Número de vidas', 
    'membershipValue': 'Adesão', 
    'monthlyValue': 'Mensalidade',
    'ATIVO': 'Contratos ativos',
    'CANCELADO': 'Quantidade de contratos cancelados',
    'share': 'Share',
}
export const LABEL_GRAPH_BAR = {
    'businessArea': {
        label: ['Área de', 'atuação'],
    },
    'paymethod': {
        label: ['Formas de', 'pagamento'],
    },
    'type': {
        label: ['Tipo de', 'venda'],
    },
    'status': {
        label: ['Status do', 'associado'],
    },
    'name': {
        label: ['Categoria']
    }
};
export const COLORS = ['#0567FF', '#F45197', '#FFA400'];
export const COLORS_BAR = {
    'Externo': '#0567FF',
    'BOLETO': '#0567FF',
    'Ativos': '#0567FF',
    'Novo contrato': '#0567FF',
    'Telemarketing': '#F45197',
    'RECORRENTE': '#F45197',
    'Reabilitação de contrato': '#F45197',
    'Suspensos': '#F45197',
    'Cancelados': '#FFA400',
    'Terceirizado': '#FFA400',
    'SEM CLASSIFICACAO': '#FFA400',
}