<template>
	<div class="global__content_marker">
		<input type="text" v-model="name" class="global-insteresting_points"/>
    <button class="icons_btn" @click="editConfirm(name)">
      <img src="../modules/map/assets/icons/blueicons/confirm_icon.svg" />
    </button>
	</div>

</template>

<script>
import { useStore } from 'vuex';
import { computed } from 'vue';
import { FeatureService } from '@/modules/map/services/feature-service';
export default {
  name: "EditFeatures",
	props: ["feature", "featureName", 'handleDesactive', 'idFeature'],
	data() {
		return {
			name: this.featureName,
		}
	},
	setup () {
    const store = useStore();
    return {
      user: computed(() => store.getters['auth/getUser']),
      getFeatureCollection: computed(() => store.getters['map/getFeatureCollection']),
    }
  },
	methods: {
		async editConfirm(newFeatureName) {
			const featureService = new FeatureService(this.user);
      let newFeature = await featureService.getFeatureById(this.feature.id, this.user.token)
			const layer = this.getFeatureCollection;
			layer.features.map(el => {
				el.id === this.idFeature ? el.properties.name = newFeatureName : 0;
			})
      await newFeature.propertiesUpdate.map(property => {
        if(property.name === 'name') {
          property.value = newFeatureName;
          property.idCompany = `${property.idCompany}`;
          featureService.updateFeatureProperties(property, this.user.token);
        }
      })
			this.$emit('handleDesactive');
		}
	}
};
</script>

<style scoped>
.global-insteresting_points{
  color: var(--dm_preto_00);
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  width: 100%;
	height: 20px;
}

.icons_btn {
  display: flex;
}
</style>