<template>
  <div class="popUp popUp__animation--add" v-if="showAlert">
    <div class="content_popUp">
      <dm-icon CorrectGreen v-if="type === 'sucess'"/>
      <dm-icon Warning v-if="type === 'error'"/>
      <p class="body_03">{{ message }}</p>
      <el-button type="primario" @click="hideAlert">Ok</el-button>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";

export default {
  setup() {
    const store = useStore();
    const showAlert = ref(false);
    const message = ref("Ocorreu um erro tente novamente mais tarde!");
    const type = ref('error');

    const showCustomAlert = (value) => {
      console.log(value);
      // setTimeout(() => {
      //   document.querySelector(".exportar").classList.add("exportar__animation--add");
      // }, 0);
      message.value = value.message;
      type.value = value.type;
      showAlert.value = value.active;
    };

    const hideAlert = () => {
      document.querySelector(".popUp").classList.add("popUp__animation--remove");
      setTimeout(() => {
      const hiddenAlert = {
        type: type.value,
        message: message.value,
        active: false
      }
      showAlert.value = false;
      store.dispatch('map/handleAlert', hiddenAlert);
      }, 1000);

    };

    return {
      showAlert,
      message,
      type,
      showCustomAlert,
      hideAlert,
    };
  },
};
</script>

<style scoped>
.content_popUp {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  position: relative;
  background: var(--dm_creme_00);
  padding: 20px;
  border-radius: 6px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
}

.popUp {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  top: 50px;
  right: 0;
  width: calc(100% - 312px);
  height: calc(100vh - 50px);
  z-index: 10;
  transition: 1s;
  opacity: 0;
}

.popUp__animation--add {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-name: popUp-add;
}

@keyframes popUp-add {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.popUp__animation--remove {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-name: popUp-remove;
}

@keyframes popUp-remove {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
</style>
