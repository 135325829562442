import store from './store/index';
import router from './router/index';
import './assets/login.css';
import { app, analytics, auth } from './firebase.config';
import authGuard from './auth-guard';

export default {
  store,
  router,
  app,
  auth,
  analytics,
  authGuard,
};
