/* eslint-disable import/extensions */
/* eslint-disable import/no-extraneous-dependencies */
// Styles
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';

// Vuetify
import { createVuetify } from 'vuetify';

export default createVuetify();
// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
