const axios = require("axios");
import { API_URL } from "../bi/helpers/constants";
export const loginWithEmailAndPassword = async (email, password) => {
  const data = {
    grant_type: "password",
    login: email,
    password: password,
  };
  const response = await axios.post(
    `${API_URL}/oauth/token`,
    data
  );
  return response;
};

export const createUserV1 = async (email, password, name, company) => {
  const data = {
    idCompany: 1,
    name: name,
    email: email,
    password: password,
    role: "v1",
  };
  return await axios.post(
    `${API_URL}/users/v1`,
    data
  );
};

export const resetPassword = async (email) => {
  return await axios.get(
    `${API_URL}/users/${email}/reset-password`
  );
};

export const getCompany = async (idCompany, token) => {
  try {
    return await axios.get(
      `${API_URL}/companies/${idCompany}`, 
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
  } catch (err) {
    console.error(err);
    throw err;
  }
};
