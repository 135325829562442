export default {
  SET_LOGGED_IN(state, value) {
    state.user.loggedIn = value;
  },
  SET_USER(state, data) {
    state.user.data = data;
  },
  SET_COMPANY(state, data) {
    state.company.data = data;
  },
  SET_TOKEN(state, data) {
    state.user.token = data;
  },
  SET_AUTH_EXPIRATION(state, data) {
    state.user.authExpiration = data;
  }
};
