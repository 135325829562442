import { loginWithEmailAndPassword, createUserV1, resetPassword, getCompany } from '../login-services';

export default {
  async register(context, { email, password, name, nameCorp}) {
    const response = await createUserV1(email, password, name, nameCorp);
    if (response) {

    } else {
      throw new Error('Unable to register user');
    }
  },

  async resetUserPassword(context, { email }) {
    try {
      const response = await resetPassword(email);
      return response.data;
    } catch (error) {
      return false;
    }
  },

  async logIn(context, { email, password }) {
    const response = await loginWithEmailAndPassword(email, password);
    if (response) {
      const company = await getCompany(response.data.user.idCompany, response.data.token);
      context.commit('SET_USER', response.data.user);
      context.commit('SET_COMPANY', company.data);
      context.commit('SET_LOGGED_IN', true);
      context.commit('SET_TOKEN', response.data.token);
      const now = new Date();
      const expirationTime = new Date(now.getTime() + 1800000); // 1 hora em milissegundos
      context.commit('SET_AUTH_EXPIRATION', expirationTime);

      // Salva as informações no localStorage
      localStorage.setItem('AUTH_TOKEN_KEY', response.data.token);
      localStorage.setItem('AUTH_EXPIRATION_KEY', expirationTime);
      localStorage.setItem('USER', JSON.stringify(response.data.user));
      localStorage.setItem('COMPANY', JSON.stringify(company.data));

    } else {
      throw new Error('Erro ao tentar salvar dados do login');
    }
  },

  async logOut(context) {
    context.commit('SET_USER', null);
    context.commit('SET_COMPANY', null);
    context.commit('SET_LOGGED_IN', false);
    context.commit('SET_TOKEN', null);

    // Remove as informações do localStorage
    localStorage.removeItem('AUTH_TOKEN_KEY');
    localStorage.removeItem('AUTH_EXPIRATION_KEY');
    localStorage.removeItem('USER');
    localStorage.removeItem('COMPANY');
  },

  fetchUser(context, user) {
    // context.commit('SET_LOGGED_IN', user !== null);
    // if (user) {
    //   context.commit('SET_USER', {
    //     displayName: user.displayName,
    //     email: user.email,
    //   });
    // } else {
    //   context.commit('SET_USER', null);
    // }
  },

  tryAutoLogin(context) {
    context.commit('SET_LOGGED_IN', false);
    const authToken = localStorage.getItem('AUTH_TOKEN_KEY');
    const expirationTime = localStorage.getItem('AUTH_EXPIRATION_KEY');
    if (!authToken || !expirationTime) {
      return false;
    }
    const now = new Date();
    const expirationDate = new Date(expirationTime);
    if (now.getTime() >= expirationDate.getTime()) {
      return false;
    }
    const user = localStorage.getItem('USER');
    const company = localStorage.getItem('COMPANY');
    try{
      context.commit('SET_USER', JSON.parse(user));
      context.commit('SET_COMPANY', JSON.parse(company));
    } catch (e) {
      return false;
    }
    context.commit('SET_LOGGED_IN', true);
    context.commit('SET_TOKEN', authToken);
    context.commit('SET_AUTH_EXPIRATION', expirationDate);
    return true;
  }
};
