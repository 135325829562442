import axios from 'axios';

export default async function authMiddleware({ store, next, to }) {
  await store.dispatch('auth/tryAutoLogin');
  if (!store.getters['auth/isAuthenticated']) {
    return next({ name: 'AuthLogin' });
  }

  const authToken = store.getters['auth/getToken'];

  // Define o token de autenticação no cabeçalho de autorização de cada solicitação
  axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;

  return next();
}