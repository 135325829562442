<template>
  <div class="container__map">
    <template v-if="layer">
      <MapMenu />
      <MapComponent />
    </template>
  </div>
</template>

<script>
import MapMenu from "../components/MapMenu.vue";
import MapComponent from "../components/MapComponent.vue";
import LS, { LayerService } from "../services/layer-service";
import { setFeatureCollection, addLayer, setCounties } from "../helpers/global";
import { useStore } from "vuex";
import { computed } from "vue";
import { MUNICIPIO, STYLE_POINTS } from "../helpers/constants";
import MyFeature from "../models/feature";
import { FeatureService } from "../services/feature-service";

export default {
  name: "MapView",
  components: { MapComponent, MapMenu },
  data() {
    return {
      layer: false,
    };
  },
  setup() {
    const store = useStore();
    const user = computed(() => store.getters["auth/getUser"]);

    setCounties(
      LS.getLayerByDiscriminatorAndBbox(
        store.getters["auth/getUser"].data.idCompany,
        MUNICIPIO,
        store.getters["auth/getToken"]
      )
    );
    return {
      user,
      store,
    };
  },
/*   async mounted() {
    await created;
  }, */
  async created() {
    const layerService = new LayerService(this.user);
    const featureService = new FeatureService(this.user);
    const layerListId = await layerService.getUserLayer();
    let featureCollection;
    // caso o user não possuir uma layer deve-se criar uma e adicionar a lista (vazia)
    if (layerListId.length) featureCollection = await layerService.getLayerById(layerListId[0].id, this.store);
    else {
      featureCollection = await layerService.createLayer("V1 de aniversário");
      let points = [];
      points.push(
        {
          geometry: {
            type: "Point",
            coordinates: [-46.64267773683233, -23.543998794966864],
          },
          discriminator: "points",
          properties: {
            city: "São Paulo",
            state: "São Paulo",
            nome: "Praça da República",
            style: STYLE_POINTS[0],
          },
        },
        {
          geometry: {
            type: "Point",
            coordinates: [-46.64267773683233, -23.543998794966864],
          },
          discriminator: "points",
          properties: {
            city: "São Paulo",
            state: "São Paulo",
            nome: "Praça da República (Raio)",
            radius: `${1.5 * 1000 * 13}`,
            style: STYLE_POINTS[1],
          },
        },
        {
          geometry: {
            type: "Point",
            coordinates: [-45.5600535, -23.0318682],
          },
          discriminator: "points",
          properties: {
            nome: "Praça Santa Terezinha",
            city: "Itajuba",
            state: "Minas Gerais",
            style: STYLE_POINTS[0],
          },
        },
        {
          geometry: {
            type: "Point",
            coordinates: [-45.5600535, -23.0318682],
          },
          discriminator: "points",
          properties: {
            nome: "Praça Santa Terezinha (Raio)",
            city: "Itajuba",
            state: "Minas Gerais",
            radius: `${2 * 1000 * 13}`,
            style: STYLE_POINTS[1],
          },
        }
      );
      points.forEach(async (el) => {
        let feature = await featureService.createFeature(
          el.geometry,
          el.discriminator,
          el.properties
        );
        if (feature) {
          await layerService.setFeatureInLayer(feature, featureCollection);
          let newFeature = new MyFeature(
            feature.id,
            this.user.data.idCompany,
            feature.geojson.geometry,
            feature.geojson.properties,
            feature.discriminator
          );
          featureCollection.addFeature(newFeature);
        }
      });
    }

    this.store.dispatch("map/changeGroupList", featureCollection.grupos);
    this.store.dispatch("map/changeDataList", featureCollection.dadosGerais);
    this.layer = true;
  },
};
</script>
<style scoped>
.container__map {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 312px 100%;
  margin-top: 50px;
}
</style>
