<template>
  <div class="container__sidebar">
    <img src="../assets/icons/blueicons/closeButton.svg" alt="botão para fechar" class="closeButton" @click="$emit('closeSideBar')">
    <div class="container__gasStation" style="margin: 30px 0 0 14px;">
      <h3>{{ data.name }}</h3>
      <div class="container__gasStation--info">
        <p><span style="font-weight: 500;">Endereço:</span> {{ data.adressRestaurant }}</p>
        <p><span style="font-weight: 500;">Avaliação do Google:</span> {{ data.googleReview }}</p>
        <div class="maps_details">
          <p><span style="font-weight: 500;">Google Maps: </span> <span style="color: var(--dm_azul_00); text-decoration: underline;">{{ data.nameMaps }}</span></p>
          <button @click="goToMaps()">
            <img src="../assets/icons/blueicons/detalhamento.svg" alt="detalhamento do posto no Google Maps">
          </button>
        </div>

      </div>
      <div class="container__gasStation--score">
        <h5>Score</h5>
        <p><span style="font-size: 30px; color: var(--dm_azul_00); font-weight: 500;">{{ data.scoreRestaurant }}</span>/10</p>
      </div>
    </div>
    <div class="container__restaurant">
      <h3>Restaurantes</h3>
      <div class="container__restaurant--total">
        <h5>Total de restaurantes</h5>
        <p style="font-size: 30px; color: var(--dm_azul_00); font-weight: 500;">{{ data.totalRestaurant }}</p>
      </div>
      <div style="margin-top: 20px;">
        <ul class="container__restaurant--ul">
          <li class="container__restaurant--list" v-for="(rest, index) in data.restaurant" :key="index">
            <p>{{ rest.qtd }}</p>
            <p>{{ rest.name }}</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="container__vehicle">
      <h3>Fluxo de veículos</h3>
      <div class="container__vehicle--value">
        <h5>Média de veículos</h5>
        <p style="font-size: 30px; color: var(--dm_azul_00); font-weight: 500;">{{ data.totalRestaurant }}</p>
      </div>
      <div style="margin-top: 20px;">
        <ul class="container__vehicle--ul">
          <li class="container__vehicle--list" v-for="(vehicle, index) in data.vehicle" :key="index">
            <p style="color: var(--dm_preto_00);">{{ vehicle.value }} /dia</p>
            <p>{{ vehicle.type }}</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="container__tancagem" style="margin-bottom: 30px; width: 93%;">
      <h3>Tancagem</h3>
      <el-table :data="data.tankage" stripe style="width: 100%;">
        <el-table-column prop="fuel" label="Combustível" width="110px" />
        <el-table-column prop="volume" label="Volume" width="100px" />
        <el-table-column prop="price" label="Preço" width="100px" />
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "MapSideBar",
  props: ['data'],
  methods: {
    goToMaps()  {
      let url = "https://www.google.com/maps/search/" + encodeURIComponent(this.data.adressRestaurant);
      window.open(url, "_blank");
    }
  },
}
</script>

<style>
  .el-table__header, .el-table__body  {
    width: 100% !important;
  }
</style>

<style scoped>
  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .closeButton:hover {
    cursor: pointer;
  }

  .container__sidebar {
    display: flex;
    flex-direction: column;
    position: fixed;
    color: var(--dm_cinza_01);
    left: 375px;
    top: 50px;
    width: 400px;
    height: 100%;
    z-index: 1;
    background-color: var(--dm_creme_02);
    border-left: 1px solid #cacaca;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .maps_details {
    display: flex;
    justify-content: space-between;
  }

  .container__gasStation, .container__restaurant, .container__vehicle, .container__tancagem {
    width: 93%;
    margin: 10px 0 0 14px;
  }

  .container__gasStation > h3, .container__restaurant > h3, .container__vehicle > h3, .container__tancagem > h3 {
    margin-bottom: 8px;
    color: var(--dm_preto_00);
  }

  .container__gasStation--info > p {
    line-height: 20px;
    margin-bottom: 7px;
  }

  .container__gasStation--score, .container__restaurant--total, .container__vehicle--value {
    border: 1px solid #cacaca;
    border-radius: 6px;
    padding: 8px;
    margin-top: 15px;
    text-align: center;
  }

  .container__restaurant--list, .container__vehicle--list {
    border: 1px solid #cacaca;
    border-radius: 6px;
    list-style: none;
    width: 100%;
    text-align: center;
    padding: 5px;
    font-weight: 500;
    margin-bottom: 15px;
  }

  .container__restaurant--ul, .container__vehicle--ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 10px;
  }
</style>