<template>
  <section>
    <div class="area">
      <div class="area_titulo">
        <img src="../assets/icons/blueicons/area.svg" />
        <h5>Áreas de estudo</h5>
      </div>
      <button class="area_btn" @click="openPopUpSearch">
        +
      </button>
    </div>
    <div style="margin: 3% 0 0 5%;" id="mapMenuLayer">
      <el-row v-for="marker in markers" :key="marker.properties.visibility" class="container__marker">
        <div class="global-div_marker">
          <img src="../assets/rectangle_icon.svg" alt="Traço da área">
          <div class="global__content_marker">
            <span class="global-insteresting_points"
              @click="flyToItem(marker)"
              :id="marker.id"
              @mouseenter="handleMouseOver"
              @mouseleave="hidePopUp"
            >
              {{ capitalizeStrings(marker.properties.nome) }}
            </span>
          </div>
        </div>
        <div class="actions">
          <el-checkbox :checked="marker.properties.visibility" @change="handleItemDeselected(marker)"
            class="checkbox_marker" />
          <button class="global-ballon">
            <div :id="`${marker.id}_Options`" class="options"  @click="handleChangeAside(marker)" />
            <div class="global-ballon__content">
              <div class="global-ballon__left">
                <div class="global-ballon__buttons">
                  <dm-icon Warning @click="handleChangeAside(marker)"/>
                  <dm-icon Delete @click="remove(marker)" />
                </div>
              </div>
            </div>
          </button>
        </div>
      </el-row>
    </div>
    <hr />
    <MapMenuLayerPopUp v-if="searchArea" :closePopUpSearch="closePopUpSearch" />
    <PopUpHover ref="popup" :longerText="19" />
  </section>
</template>

<script>
import MapMenuLayerPopUp from './MapMenuLayerPopUp.vue';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import MapService from '../services/map-service';
import { FeatureService } from '../services/feature-service'
import PopUpHover from '../../../components/PopUpHover.vue'
import { handleSelectedFeatures } from '../helpers/transforms'
import { capitalizeStrings } from '../helpers/transforms'
export default {
  components: {
    MapMenuLayerPopUp,
    PopUpHover,
  },
  setup() {
    const isItMarker = computed(() => store.getters["map/getIsItMarker"])
    const searchArea = ref(false);
    const store = useStore();
    const getFeatureCollection = computed(() => store.getters['map/getFeatureCollection']);
    const markers = ref(getFeatureCollection.value.getNonPointFeatures());
    const handleChangeAside = (feature) => {
      legendPosition()
      handleSelectedFeatures(feature.id, getFeatureCollection.value.features);
      MapService.flyto(feature)
      MapService.deselectPins();
      store.dispatch('map/changeAside', {...feature, isActive: true});
      store.dispatch("map/changeIsMarker", false);
    }

    const legendPosition = () =>{
      store.dispatch('map/changeLegendPosition', [true, '392px' ])
    }

    return {
      store,
      searchArea,
      isItMarker,
      user: computed(() => store.getters['auth/getUser']),
      mapState: computed(() => store.getters['map/getMapActualState']),
      deleteArea: computed(() => store.getters['map/getDeleteArea']),
      markers,
      getFeatureCollection,
      handleChangeAside,
      capitalizeStrings,
      legendPosition
    }
  },
  data() {
    return {
      showPopup: false,
    }
  },
  watch: {
    deleteArea(newVal){ // Recebe a requisição do usuário para deletar a área de estudo
        this.remove(newVal)
    },
    mapState: {
      handler(newVal, oldVal) {
        if (newVal === 'createPolygon' || newVal === 'createRadius' || newVal === 'createMarker') {
          this.markers = this.getFeatureCollection.getNonPointFeatures();
        }
      }
    }
  },
  methods: {
    hidePopUp(event) {
      this.$refs.popup.hidePopup(event);
    },
    handleMouseOver(event) {
      this.$refs.popup.showPopup(event);
    },
    flyToItem(feature) {
      MapService.flyto(feature)
    },
    openPopUpSearch() {
      this.$store.dispatch('map/finishCompute');
      this.searchArea = !this.searchArea;
    },
    closePopUpSearch() {
      this.searchArea = false;
    },
    async changeFeatureName(feature, markerName) {
      const featureService = new FeatureService(this.user);
      let newFeature = await featureService.getFeatureById(feature.id, this.user.token)
      feature.name = markerName;
      await newFeature.propertiesUpdate.map(property => {
        if (property.name === 'name') {
          property.value = markerName;
          property.idCompany = `${property.idCompany}`;
          featureService.updateFeatureProperties(property, this.user.token);
        }
      })
    },
    remove(feature) {
      this.store.dispatch('map/changeAside', 'closePopupTrue'); // Fecha o slidebar que foi deletedo
      const featureService = new FeatureService(this.user);
      featureService.deleteFeatureById(feature.id);
      this.getFeatureCollection.removeFeature(feature);
      MapService.updateSource(this.getFeatureCollection.toGeoJSON());
      this.markers = this.getFeatureCollection.getNonPointFeatures();
    },
    handleItemDeselected(feature) {
      feature.properties.visibility = !feature.properties.visibility;
      MapService.updateSource(this.getFeatureCollection.toGeoJSON());
    }
  },
}
</script>

<style>
#mapMenuLayer .el-checkbox__inner {
  width: 15px !important;
  height: 15px !important;
}

#mapMenuLayer .el-checkbox__inner::after {
  width: 4px;
  height: 12px;
}

#mapMenuLayer .el-checkbox__label {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
</style>

<style scoped>
section {
  position: relative;
}

.options {
  display: flex;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 20px;
  height: 20px;
  background-image: url('../../../assets/icons/blackicons/Options.svg');
}

.area {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

hr {
  border: 1px solid #cacaca;
  margin-top: 10px;
}

.area_titulo {
  display: flex;
  gap: 10px;
}

.area_btn {
  display: flex;
  color: var(--dm_azul_00);
  align-items: center;
  justify-content: center;
  background: var(--dm_creme_00);
  border-radius: 50px;
  border: 2px solid var(--dm_azul_00);
  width: 20px;
  height: 20px;
}

.area_btn:hover {
  background: var(--dm_azul_01);
  color: var(--dm_creme_02);
  border: var(--dm_azul_01);
}



.actions {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 2px;
}

.popup {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #c4c4c4;
  font-size: 12px;
  border-radius: 6px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  padding: 10px;
}


.checkbox_marker {
  width: 20px;
}

.icons_btn {
  display: flex;
}

.container__marker {
  flex-wrap: nowrap;
  padding: 0px 10px 5px 10px;

}

.container__marker:hover {
  background-color: var(--dm_cinza_05);
}
</style>
