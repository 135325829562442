<template>
  <div class="visualization-bg">
    <div class="popUp" id="popUp_visualization">
      <p class="body_05">
        Esta forma de visualização está desabilitada neste nível de <b>zoom</b> ou <b>camada</b>
      </p>
    </div>

    <el-radio-group
      v-model="filter"
      size="large"
      class="container__button--visualization"
      @change="handleClick"
    >
      <el-radio-button label="Estado" />
      <el-radio-button
        @mouseover="handleMouse(true, isLayerMunicipioZoom)"
        @mouseleave="handleMouse(false)"
        label="Município"
        :disabled="isLayerMunicipioZoom"
      />
      <el-radio-button
        @mouseover="handleMouse(true, notValue)"
        @mouseleave="handleMouse(false)"
        label="Setor censitário"
        :disabled="notValue"
      />
    </el-radio-group>
  </div>
</template>

<script>
import { ref, computed, watch } from "vue";
import { MUNICIPIO, ESTADO, SETOR_CENSITARIO, CENSUS_BLOCK, NOT_VALUE } from "../helpers/constants";
import { useStore } from "vuex";

export default {
  name: "MapComponentVisualization",
  props: {
    levelZoom: Number
  },
  setup() {
    const store = useStore();
    let mouseOver = ref(false);
    const handleClick = (e) => {
      switch (e) {
        case "Estado":
          store.dispatch("map/changeVisualization", ESTADO);
          break;
        case "Município":
          store.dispatch("map/changeVisualization", MUNICIPIO);
          break;
        case "Setor censitário":
          store.dispatch("map/changeVisualization", SETOR_CENSITARIO);
          break;
      }
    };

    const notValue = ref(false);
    const isLayerMunicipioZoom = ref(false);

    const mapLegend = computed(() => store.getters["map/getLegend"]);
    const getVisualization = computed(() => store.getters["map/getVisualization"]);

    const handleMouse = (handler, notValue) => {
      let popUp = document.getElementById("popUp_visualization");
      handler && notValue ? (popUp.style.display = "flex") : (popUp.style.display = "none");
    };

    return {
      store,
      mapLegend,
      filter: ref("Estado"),
      mouseOver,
      handleClick,
      handleMouse,
      isLayerMunicipioZoom,
      notValue,
      getVisualization,
      NOT_VALUE,
    };
  },

  watch: {
    levelZoom: {
      handler(newVal, oldVal) {
        //Município
        if (newVal < 6) {
          this.isLayerMunicipioZoom = true;
        } else {
          this.isLayerMunicipioZoom = false;
        }
        //Censitário
        if (newVal < 10 || CENSUS_BLOCK.includes(newVal[2])) {
          this.notValue = true;
        } else {
          this.notValue = false;
        }
      },
    },
    getVisualization: {
      async handler(newVal, oldVal) {
        if (newVal === NOT_VALUE) {
          this.filter = "Município"
        };
      },
    },
  },
};
</script>

<style>
.el-radio-button__original-radio:checked + .el-radio-button__inner {
  background: var(--dm_creme_02);
  color: var(--dm_azul_00);
  border: none;
  border-right: 1px solid;
}

.el-radio-button__inner,
.el-radio-button__original-radio:checked + .el-radio-button__inner {
  border-color: var(--dm_cinza_03);
}

.el-radio-button__inner {
  border-width: 0 1px;
}

.el-radio-button__original-radio:disabled + .el-radio-button__inner {
  background: var(--dm_cinza_05);
  color: var(--dm_cinza_03);
}
</style>

<style scoped>
.visualization-bg {
  position: fixed;
  bottom: 1%;
  left: 50%;
}

.popUp {
  display: none;
  position: fixed;
  left: 52.5%;
  bottom: 55px;
  align-items: center;
  padding: 10px;
  background-color: var(--dm_creme_02);
  border-radius: 3px;
}

.container__button--visualization {
  width: 20%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  position: relative;
  left: calc(60% - 10%);
  filter: drop-shadow(0 4px 2px rgba(0, 0, 0, 0.25));
}
</style>
