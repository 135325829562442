<template>
  <div class="container__search">
    <p>Adicionar estado ou município</p>
    <el-autocomplete
      v-model="locationSearch"
      :fetch-suggestions="querySearch"
      placeholder="São Paulo, SP"
      @select="handleSelect"
      style="width: 370px"
    >
      <template #default="{ item }">
        <div class="value">
          {{
            item.discriminator === "Município"
              ? item.discriminator + " - " + item.value + " / " + item.states
              : item.discriminator + " - " + item.value
          }}
        </div>
      </template>
    </el-autocomplete>
    <span v-if="existFeature" class="name_layer">Camada já existente</span>

    <p>Nomear a localidade</p>
    <el-input
      v-model="locationName"
      placeholder="Local A"
      @keyup="checkNames"
      :value="this.getValue"
    />
    <span v-if="featureNameFree" class="name_layer">Nome de camada já está sendo utilizado</span>
    <div>
      <el-button type="primario" @click="confirmar" :disabled="featureNameFree || existFeature">
        Confirmar
      </el-button>
      <el-button type="secundario" @click="closePopUpSearch"> Cancelar </el-button>
    </div>
  </div>
</template>

<script>
import { Search } from "@element-plus/icons-vue";
import { FeatureService } from "../services/feature-service";
import { useStore } from "vuex";
import { ref, computed } from "vue";
import MapService from "../services/map-service";
import { STYLE_POINTS, MUNICIPIO, ESTADO, SETOR_CENSITARIO, OPTIONS } from "../helpers/constants";
import { LayerService } from "../services/layer-service";
import MyFeature from "../models/feature";

export default {
  name: "MapMenuLayerPopUp",
  props: {
    closePopUpSearch: Function,
  },
  data() {
    return {
      mapState: {},
      featureNameFree: false,
      feature: null,
      existFeature: false,
      getValue: "",
    };
  },
  setup() {
    const locationSearch = ref("");
    const searchResults = ref([]);
    const store = useStore();
    const locationName = ref("");

    return {
      Search,
      locationName,
      searchResults,
      locationSearch,
      mapDiscriminator: {
        [MUNICIPIO]: "Município",
        [SETOR_CENSITARIO]: "Setor Censitário",
        [ESTADO]: "Estado",
      },
      user: computed(() => store.getters["auth/getUser"]),
      token: computed(() => store.getters["auth/getToken"]),
      getFeatureCollection: computed(() => store.getters['map/getFeatureCollection']),
    };
  },
  async created() {
    const featureService = new FeatureService(this.user);
    const states = await featureService.getFeatureByDiscriminator("states", this.token);
    states.forEach((state) => {
      this.mapState[state.id.toString()] = state.properties.nome;
    });
  },
  methods: {
    checkNames() {
      this.featureNameFree = false;
      this.getFeatureCollection.features.forEach((el) => {
        el.properties.nome === this.locationName ? (this.featureNameFree = true) : 0;
      });
    },
    async querySearch(queryString, cb) {
      const featureService = new FeatureService(this.user);
      const mapState = await this.mapState;
      if (queryString) {
        let result = [];
        const arrDiscriminators = OPTIONS[2].discriminator.split("_");
        let discriminator;
        for (const searchs of arrDiscriminators) {
          const stringDiscriminator = `discriminator=${searchs}`;
          const responseData = await featureService.getFeatureByDiscriminatorQueryString(
            stringDiscriminator,
            this.token,
            queryString,
            false
          );
          responseData.forEach((el) => {
            discriminator = this.mapDiscriminator[el.properties.discriminator];
            result.push({
              value: el.properties.nome,
              id: el.id,
              discriminator: discriminator,
              states: mapState[el.id.toString().substring(0, 2)],
            });
          });
        }
        cb(result);
      }
    },
    checkData(arr1, arr2) {
      const string1 = JSON.stringify(arr1);
      const string2 = JSON.stringify(arr2);
      return string1 === string2;
    },
    async handleSelect(item) {
      this.existFeature = false;
      const featureService = new FeatureService(this.user);
      this.result = await featureService.getFeatureById(item.id, this.token);
      this.result.properties.states = item.states;
      this.getFeatureCollection.features.forEach((el) => {
        const res = this.checkData(this.result.geometry.coordinates, el.geometry.coordinates);
        if (res) {
          this.existFeature = true;
        }
      });
      this.getValue = item.value;
    },
    async confirmar() {
      const layerService = new LayerService(this.user);
      const featureService = new FeatureService(this.user);
      const featureResult = await this.result;
      featureResult.properties.nome = !this.locationName
        ? featureResult.properties.nome
        : this.locationName;
      featureResult.properties.idFeatureStudy = featureResult.id;
      featureResult.properties.style = STYLE_POINTS[1];
      featureResult.idCompany = this.user.data.idCompany;
      const createFeature = await featureService.createFeature(
        featureResult.geometry,
        featureResult.discriminator,
        featureResult.properties
      );
      const feature = new MyFeature(
        createFeature.id,
        this.user.data.idCompany,
        createFeature.geojson.geometry,
        createFeature.geojson.properties,
        createFeature.discriminator
      );
      layerService.setFeatureInLayer(feature, this.getFeatureCollection);
      this.getFeatureCollection.addFeature(feature);
      MapService.updateSource(this.getFeatureCollection.toGeoJSON());
      MapService.addPolygonLayer();
      MapService.flyto(feature);
      this.$store.dispatch("map/changeMapState", "confirmar");
      this.closePopUpSearch();
    },
  },
};
</script>

<style scoped>
.name_layer {
  font-size: 12px;
  color: firebrick;
}
.container__search {
  position: absolute;
  left: calc(61vw - 200px);
  top: 25vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 400px;
  max-height: 280px;
  padding: 15px;
  background: var(--dm_creme_02);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  z-index: 200;
}
</style>
