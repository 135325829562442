<template>
   <div class="container__menu">
      <MapMenuLayer />
      <MapMenuMarker />
      <MapMenuData />
      <MapAsideBar />
   </div>
</template>

<script>
import MapMenuData from "./MapMenuData.vue";
import MapMenuLayer from "./MapMenuLayer.vue";
import MapMenuMarker from "./MapMenuMarker.vue";
import MapAsideBar from "./MapAsideBar.vue";

export default {
   name: "MapMenu",
   data() {
      return {
         showAside: false,
         asideBarData: {},
      }
   },
   components: {
      MapMenuData,
      MapMenuLayer,
      MapMenuMarker,
      MapAsideBar
   },
   methods: {
      showAsideValue(marker) {
         this.showAside = !this.showAside;
         this.asideBarData = marker;
      }
   }
}
</script>

<style scoped>
.container__menu {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 30px;
    background-color: var(--dm_creme_02);
    max-height: calc(100vh - 70px);
    width: 100%;
    min-width: 312px;
    padding: 10px;
    z-index: 1;
    margin-bottom: 82px;
}
</style>