export default class MyFeature {
  type = 'Feature'
  constructor(id, idCompany, geometry, properties, discriminator, propertiesUpdate=null) {
    this.id = id;
    this.idCompany = idCompany
    this.geometry = geometry
    this.properties = properties;
    this.discriminator = discriminator;
    this.properties.visibility = true;
    this.propertiesUpdate = propertiesUpdate;
  }

  addProperty(property) {
    this.properties.push([property]);
  }

  toGeoJSON() {
    return {
      type: 'Feature',
      geometry: this.geometry,
      properties: {
        ...this.properties,
        discriminator: this.discriminator,
        id: this.id
      },
      propertiesUpdate: this.propertiesUpdate,
    }
  }

  toJSON() {
    return {
      type: 'Feature',
      geometry: this.geometry,
      idCompany: this.idCompany,
      discriminator: this.discriminator,
      id: this.id,
      properties: {
        ...this.properties,
      }
    }
  }
}