export default class properties {
  constructor(name, value, dtype) {
    this.name = name;
    this.value = value;
    this.dtype = dtype;
  }

  toJSON() {
    const properties = {
      name: this.name,
      value: this.value,
      dtype: this.dtype,
    };
  	return properties;
  }
}