import { STYLE_POINTS } from "../helpers/constants";
import * as turf from "@turf/turf";

export class CustomFeatureCollection {
  type = "FeatureCollection";
  features = [];
  constructor(id, name, features = [], grupos = [], dadosGerais = []) {
    this.features = features.map((feature) => {
      feature.properties.visibility = true;
      return feature;
    });
    this.name = name;
    this.id = id;
    this.grupos = grupos;
    this.dadosGerais = dadosGerais;
  }

  addFeature(feature) {
    feature.properties.visibility = true;
    this.features.push(feature);
  }

  getMarkers() {
    const markers = this.features.filter((feature) => {
      return feature.properties?.style === STYLE_POINTS[0];
    });
    return markers;
  }

  removeFeature(feature) {
    const index = this.features.findIndex((f) => f.id === feature.id);
    if (index > -1) {
      this.features.splice(index, 1);
    }
  }

  getNonPointFeatures() {
    return this.features.filter((feature) => {
      return feature.properties?.style === STYLE_POINTS[1];
    });
  }

  toGeoJSON() {
    const options = { steps: 200, units: "kilometers" };
    return {
      type: this.type,
      features: this.features.map((feature) => {
        const featureModify = {
          type: `${feature.type}`,
          properties: feature.properties,
          geometry: feature.geometry,
          id: feature.id,
        };

        if (feature.geometry.type == "Point" && feature.properties.style === STYLE_POINTS[1]) {
          const circle = turf.circle(
            feature.geometry.coordinates,
            parseInt(feature.properties.radius) / 1000,
            options
          );
          featureModify.geometry = circle.geometry;
        }
        return featureModify;
      }),
    };
  }
}
