<template>
  <div>
    <button class="button__radius" @click="changeMapState('clickBtnRadius')">
      <img src="../../../assets/icons/blackicons/raio.svg">
      Raio
    </button>
    <MapComponentRadiusConfig v-if="configuration" />
  </div>
</template>

<script>
import MapComponentRadiusConfig from './MapComponentRadiusConfig.vue';
import { useStore } from 'vuex';
import { computed } from "vue";

export default {
  name: "MapComponentRadius",
  components: {
    MapComponentRadiusConfig
  },
  setup() {
    const store = useStore();
    return {
      store: store,
      notification: computed(() => store.getters['map/getMapActualState'] === 'clickRadius'),
      configuration: computed(() => store.getters['map/getMapActualState'] === 'popupRadius'),
    }
  },
  methods: {
    changeMapState(action) {
      this.$store.dispatch('map/finishCompute');
      this.store.dispatch('map/changeMapState', action);
    }
  }
}
</script>

<style scoped>
.button__radius {
  background-color: var(--dm_creme_02);
  padding: 7px 8px 7px 5px;
  width: 90px;
  height: 35px;
  border-radius: 6px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
</style>