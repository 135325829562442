import axios from "axios";
import { CustomFeatureCollection } from "../models/feature-collection";
import propertiesModel from "../models/properties";
import qs from "qs";
import { RENDA, RESIDENTES } from "../helpers/constants";
import { API_URL } from "@/modules/bi/helpers/constants";
export class LayerService {
  constructor(user) {
    this.user = user;
    this.url = API_URL;
  }

  async getUserLayer() {
    let response;
    try {
      response = await axios.get(`${this.url}/users/${this.user.data.id}/layers`, {
        headers: {
          Authorization: `Bearer ${this.user.token}`,
        },
      });
      return response.data;
    } catch (e) {
      console.log(e);
    }
  }

  async createLayer(nameLayer) {
    let response;
    try {
      response = await axios.post(
        `${this.url}/layers`,
        {
          name: nameLayer,
          idUser: this.user.data.id,
          idsFeatures: [],
        },
        {
          headers: {
            Authorization: `Bearer ${this.user.token}`,
          },
        }
      );
      return new CustomFeatureCollection(
        response.data.id,
        response.data.name,
        response.data.features,
        response.data.grupos
      );
    } catch (e) {
      console.error(e);
    }
  }

  async updateLayer(layer, groups=[], dataToMap=[]) {
    try {
      let response = await axios.put(
        `${this.url}/layers`,
        {
          id: layer.id,
          name: layer.name,
          idUser: this.user.data.id,
          grupos: groups,
          dadosGerais: dataToMap,
        },
        {
          headers: {
            Authorization: `Bearer ${this.user.token}`,
          },
        }
      );
      return response.data;
    } catch (e) {
      console.error(e);
    }
  }

  async getLayerById(layerId, store) {
    try {
      let response = await axios.get(
        `${this.url}/companies/${this.user.data.idCompany}/layers/${layerId}`,
        {
          headers: {
            Authorization: `Bearer ${this.user.token}`,
          },
        }
      );
      if (response) {
        response.data.geojson.features.map((el) => {
          el.properties.radius ? (el.properties.radius = parseInt(el.properties.radius)) : 0;
          el.properties.visibility ? (el.properties.visibility = true) : 0;
        });
        store.dispatch("map/setFeatureCollection", response.data);
        return response.data;
      }
    } catch (e) {
      console.error(e);
    }
  }

  async setFeatureInLayer(feature, layer) {
     
    try {
      let response = await axios.post(
        `${this.url}/layers-features`,
        {
          idFeature: Number(feature.id),
          idLayer: Number(layer.id),
        },
        {
          headers: {
            Authorization: `Bearer ${this.user.token}`,
          },
        }
      );
    } catch (e) {
      console.error(e);
    }
  }

  async getLayerByDiscriminatorAndBbox(idCompany, discriminator, token, bbox, zoom) {
    try {
      let url = `${this.url}/companies/${idCompany}/layers/features`;
      if (bbox) {
        url = `${this.url}/companies/${idCompany}/layers/features?bbox=${bbox.join("&bbox=")}`;
      }
      const params = {
        discriminator,
        zoom,
      };
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(url, { params, headers });
      if (response) {
        let data = response.data.geojson;
        data.features.map((feature) => {
          feature.properties[RENDA] && feature.properties[RESIDENTES]
            ? (feature.properties[RENDA] =
                feature.properties[RENDA] / feature.properties[RESIDENTES])
            : null;
        });
        return data;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async getLayerQuantiles(idCompany, discriminator, propertyName, numIntervals, colors, token) {
    try {
      const url = `${this.url}/companies/${idCompany}/layers/features?colors=${colors
        .join("&colors=")
        .replace(/#/g, "%23")}`;
      const params = {
        discriminator,
        propertyName,
        numIntervals,
        onlyQuantiles: true,
      };

      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.get(url, {
        headers,
        params,
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
      });
      return response.data;
    } catch (error) {
      console.error(
        "Erro ao tentar buscar os quantiles",
        discriminator,
        propertyName,
        numIntervals,
        colors,
        error
      );
      return null;
    }
  }
}

const layerService = new LayerService();

export default layerService;
