<template>
  <section
    class="container__list global-shadow global-asideBarAnimation--on"
    id="asidePoints"
    v-if="isOpenSideBar"
  >
    <div class="container__sidebarTable">
      <button @click="hadleSideBarTable()" style="position: absolute; right: 10px">
        <!-- Posicionar a direita -->
        <dm-icon Close></dm-icon>
      </button>

      <div class="container_input">
        <h2 class="input_text">Ponto de Interesse</h2>
        <input
          v-model="inputValue"
          type="text"
          class="input"
          placeholder="Buscar"
          @keyup="filterTable()"
        />
      </div>

      <div class="container_title_table">
        <h5>{{ namePoint }}</h5>
        <DropdownSelect :optionsToSelect="optionsToSelect" @updateTable="updateTable" />
      </div>
      <el-table :data="tableDataFiltered" stripe empty-text=" ">
        <el-table-column prop="name" label="Nome" sortable>
          <template #default="scope">
            <div class="ellipsis" @mouseenter="handleMouseOver" @mouseleave="hidePopUp">
              {{ scope.row.name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column :prop="selectedOption" sortable width="93px" :label="updateLabel" />
        <el-table-column prop="actions" width="46px">
          <template #default="scope">
            <button @click.prevent="openAsideBar(scope.row)">
              <img src="../assets/details.svg" alt="Ação" />
            </button>
          </template>
        </el-table-column>
      </el-table>
      <div class="background-container" v-if="loadingTable">
        <div class="loading-container">
          <div class="loading-spinner"></div>
        </div>
      </div>
    </div>
    <PopUpHover ref="popup" :longerText="17" />
  </section>
</template>

<script>
import MapSideBar from "./MapSideBar.vue";
import PopUpHover from "../../../components/PopUpHover.vue";
import DropdownSelect from "../../../components/DropdownSelect.vue";
import { ref, computed } from "vue";
import {
  CAPITAL_SOCIAL,
  POINTS_NAME,
  GROUP_POINTS,
  GROUP_POINTS_COMPANY,
  GROUP_POINTS_COMPANY_2,
} from "../helpers/constants";
import MapService from "../services/map-service";
import { useStore } from "vuex";
import { FeatureService } from "../services/feature-service";
export default {
  name: "MapSideBarTable",
  components: { MapSideBar, PopUpHover, DropdownSelect },
  setup() {
    const store = useStore();
    const dataOfPoints = ref();
    const isOpenSideBar = ref(false);
    const tableDataFiltered = ref([]);
    const tableDataOrigin = ref([]);
    const loadingTable = ref(true);
    const namePoint = ref("");
    const user = store.getters["auth/getUser"];
    const featureService = new FeatureService(user);
    const accumulatorFeature = ref([]);

    const getTableDataFiltered = (data) => {
      data.forEach((points) => {
        GROUP_POINTS.forEach((group) =>
          group.discriminator === points.discriminator ? (namePoint.value = group.name) : null
        );
        GROUP_POINTS_COMPANY_2[user.data.idCompany].forEach((group) =>
          group.discriminator === points.discriminator ? (namePoint.value = group.name) : null
        );
        tableDataOrigin.value.push({
          name: points.properties.nome,
          geometry: points.geometry,
          state: points.properties.state,
          city: points.properties.city,
          [CAPITAL_SOCIAL]: parseInt(points.properties[CAPITAL_SOCIAL]).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          }),
        });
      });

      tableDataFiltered.value = tableDataOrigin.value;
      loadingTable.value = false;
    };

    const blockHadleSideBarTable = ref(true);
    //Ao clicar várias vez no botão close, o componete abre e fecha (deveria apenas fechar), para evitar isso, a const "blockHadleSideBarTable" bloqueia para apenas um clique.

    const hadleSideBarTable = () => {
      store.dispatch("map/changePointInter", false);
      if (blockHadleSideBarTable.value) {
        blockHadleSideBarTable.value = false;
        setTimeout(() => {
          isOpenSideBar.value = !isOpenSideBar.value;
          !isOpenSideBar.value ? MapService.defaultSideBarOpen() : null;
          blockHadleSideBarTable.value = true;
        }, 500);
      }
      //Adiciona animação ao fechar o componente.
      document.getElementById("asidePoints").classList.add("global-asideBarAnimation--off");
    };

    return {
      store,
      dataOfPoints,
      tableDataFiltered,
      tableDataOrigin,
      isOpenSideBar,
      featureService,
      namePoint,
      accumulatorFeature,
      user,
      loadingTable,
      mapGetFeatures: computed(() => store.getters["map/getUpdateGroups"]),
      mapGetFeaturesCompany: computed(() => store.getters["map/getCompanyGroups"]),
      getTableDataFiltered,
      hadleSideBarTable,
    };
  },
  data() {
    return {
      data: {},
      inputValue: "",
      fullTextPopUp: "",
      selectedOption: "state",
      updateLabel: "Estado",
      optionsToSelect: [
        {
          value: "state",
          label: "Estado",
        },
        {
          value: "city",
          label: "Cidade",
        },
        {
          value: CAPITAL_SOCIAL,
          label: "Capital Social",
        },
      ],
    };
  },
  watch: {
    mapGetFeatures: {
      async handler(newVal, oldVal) {
        this.tableDataOrigin = [];
        if (typeof newVal === "boolean" && !newVal) {
          this.isOpenSideBar = false;
          this.store.dispatch("map/changeLegendPosition", [false]);
        } else if (newVal.activeCompany) {
          this.isOpenSideBar = true;
          this.mapGetFeaturesCompany.forEach((el) => {
            el[newVal.nameGroup]
              ? (this.getTableDataFiltered(el[newVal.nameGroup]),
                (this.dataOfPoints = el[newVal.nameGroup]))
              : null;
          });
        } else {
          this.loadingTable = true;
          this.isOpenSideBar = true;
          const newData = [];
          for (const el of newVal) {
            const data = await this.featureService.getFeatureById(
              el.properties.identificador_back,
              this.user.token
            );
            newData.push({ ...data });
          }

          this.getTableDataFiltered(newData);
          this.dataOfPoints = newData;
        }
      },
    },
  },
  methods: {
    updateTable(option) {
      this.selectedOption = option.value;
      this.updateLabel = option.label;
    },
    hidePopUp(event) {
      this.$refs.popup.hidePopup(event);
    },
    handleMouseOver(event) {
      this.fullTextPopUp = event.toElement.innerText;
      this.$refs.popup.showPopup(event);
    },
    async filterTable() {
      this.accumulatorFeature = [];
      const remove = (value) => {
        let formatValue = value
          .toLocaleLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");
        return formatValue;
      };
      this.inputValue === "" ? (this.tableDataFiltered = this.tableDataOrigin) : null;
      this.tableDataFiltered = this.tableDataOrigin.filter((el) => {
        return remove(el.name).includes(remove(this.inputValue));
      });

      if (this.tableDataFiltered.length === 0) {
        let discriminator;
        Object.entries(GROUP_POINTS).forEach((el) => {
          el[1].name === this.namePoint ? (discriminator = el[1].discriminator) : null;
        });
        Object.entries(GROUP_POINTS_COMPANY_2[this.user.data.idCompany]).forEach((el) => {
          el[1].name === this.namePoint ? (discriminator = el[1].discriminator) : null;
        });
        const res = await this.featureService.getFeatureByDiscriminatorQueryString(
          `discriminator=${discriminator}`,
          this.user.token,
          this.inputValue,
          false
        );
        for (const feature of res) {
          this.accumulatorFeature.push(
            await this.featureService.getFeatureById(feature.id, this.user.token)
          );
        }
        this.dataOfPoints = this.accumulatorFeature;
        this.tableDataFiltered = this.accumulatorFeature.map((features) => {
          return {
            name: features.properties.nome
              ? features.properties.nome
              : features.properties[POINTS_NAME],
            geometry: features.geometry,
            state: features.properties.state,
            city: features.properties.city,
            [CAPITAL_SOCIAL]: parseInt(features.properties[CAPITAL_SOCIAL]).toLocaleString(
              "pt-BR",
              {
                style: "currency",
                currency: "BRL",
              }
            ),
          };
        });
      }
    },
    openAsideBar(selected) {
      this.dataOfPoints.forEach((feature) => {
        if (selected.geometry.coordinates === feature.geometry.coordinates) {
          MapService.flyto(feature);
          this.store.dispatch("map/changeAside", { ...feature, isActive: true });
        }
      });
    },
  },
};
</script>

<style scoped>
.background-container {
  z-index: 1;
  width: 100%;
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 5px dashed black;
  border-radius: 50%;
  animation: spin 3s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
.container_input {
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  gap: 15px;
}

.input_text {
  color: var(--dm_preto_00);
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.input {
  width: 100%;
  height: 29px;
  padding: 10px 40px;
  border-radius: 6px;
  border: 1px solid var(--tons-pretos-dm-cinza-02, #959595);
  background: var(--cremes-dm-creme-02, #fdfbf9);
  background-image: url("../assets/search_icon.svg");
  background-repeat: no-repeat;
  background-position: 2%;
}

.container_title_table {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

h5 {
  color: var(--dm_preto_00);
}

.container__list {
  position: fixed;
  width: 324px;
  height: calc(100vh - 50px);
  color: var(--dm_cinza_01);
  left: 312px;
  top: 50px;
  border-left: 1px solid #cacaca;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: var(--dm_creme_02);
}

.container__sidebarTable {
  padding: 20px 10px;
}
</style>
