//index BI
const Module = () => import('../ModuleBi.vue');

//Rotas Painel Edmil
const BI = () => import('../views/BI.vue');
const Concorrentes = () => import('../views/Concorrentes.vue');

const moduleRoute = {
  name: 'Bi',
  path: '/bi',
  component: Module,
  children: [
    {
      name: 'BI',
      path: '/BI',
      component: BI,
    },
    {
      name: 'Concorrentes',
      path: '/concorrentes',
      component: Concorrentes,
    },
  ],
  meta: {
    requiresAuth: true,
  },
};

export default (router) => {
  router.addRoute(moduleRoute);
};
