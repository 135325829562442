// global.js

let featureCollection;
let temp;
const layers = [];
let counties;

export const setCounties = (data) => {
  counties = data;
}

export const getCounties = () => {
  return counties;
}

export const setFeatureCollection = (featureCollectionVar) => {
  featureCollection = featureCollectionVar;
};

export const getFeatureCollection = () => {
  return featureCollection;
};

export const setTemp = (tempVar) => {
  temp = tempVar;
};

export const getTemp = () => {
  return temp;
};

export default featureCollection;