<template>
	<div class="search-bar">
		<div class="seletor">
			<el-select v-model="searchType" placeholder="Selecione">
				<el-option 
				v-for="item in options"
				 :key="item.value"
				:label="item.value"
				 :value="item.discriminator"
					:disabled="item.disabled"
					@click="selectedValue = {...item}"
					/>
			</el-select>
		</div>
		<span class="barra-pesquisa">
			<el-autocomplete 
				v-model="searchOption" 
				:fetch-suggestions="querySearch" 
				placeholder="Pesquisar no mapa"
				@select="handleSelect"
			>
				<template #default="{item}">
					<!-- <div class="body_02">{{ selectedValue.value }}</div> -->
					<div class="items_list">
						<div class="container_item">
							<img
							 :src="selectedValue.icon"
							 alt="Icone por busca"
							 height="20"
							 width="20"
								v-if="selectedValue.discriminator === options[0].discriminator || selectedValue.discriminator === options[1].discriminator"
								/>
							<span class="item_in_list">{{ item.nome }}</span>
						</div>
						<span class="detail_04">{{ TRADUCTOR[item.discriminator] }}</span>
					</div>
				</template>
			</el-autocomplete>
		</span>
		<dm-icon Search />
	</div>
</template>

<script>
import { ref, computed } from 'vue';
import { FeatureService } from '../services/feature-service';
import { useStore } from 'vuex';
import MapService from '../services/map-service'
import { TRADUCTOR, OPTIONS, GROUP_POINTS } from '../helpers/constants'
import { handleSelectedFeatures } from '../helpers/transforms'
import { capitalizeStrings } from '../helpers/transforms'

export default {
	name: 'MapSearchBar',
	setup() {
		const searchOption = ref('');
		const selectedValue = ref({});
		const searchType = ref('Selecionar');
		const store = useStore();
		const user = store.getters['auth/getUser'];
   		const getFeatureCollection = computed(() => store.getters['map/getFeatureCollection']);
		const featureService = new FeatureService(user)

		const searchOptions = async (value, queryString) => {
			const stringDiscriminator = value.map(stringItem => `discriminator=${stringItem.replace(/ /g, '&')}`).join('&');
			console.log(stringDiscriminator)
			const responseData = await featureService.getFeatureByDiscriminatorQueryString(stringDiscriminator, user.token, queryString, false)
			return responseData;
		}

		const formatSearch = (responseArray) => {
			const formatedItem = responseArray.map(feature => {
				return {
					nome: capitalizeStrings(feature.properties.nome),
					discriminator: feature.discriminator,
					id: feature.id,
				}
			})
			return formatedItem;
		}

		const querySearch = async (queryString, cb) => {
			const { value } = searchType;
			if (!queryString) return;

			if(value.split('_')[1]) {
					let first = await searchOptions([value.split('_')[0]], queryString);
					let second = await searchOptions([value.split('_')[1]], queryString);
					let accumulative = first.concat(second);
				cb(formatSearch(accumulative))
			} else if (value === OPTIONS[0].discriminator || value === OPTIONS[1].discriminator) {
				if (value === OPTIONS[0].discriminator) {
					const features = getFeatureCollection.value.getNonPointFeatures().filter(el => el.properties.nome.includes(queryString))

					cb(formatSearch(features));
					
				} else {
					let groups = [];
					for (const group of GROUP_POINTS) {
						groups.push(group.discriminator)
					}
					const features = await searchOptions(groups, queryString)
					const points = getFeatureCollection.value.getMarkers().filter(el => el.properties.nome.includes(queryString))
					let accumulative = features.concat(points);
					cb(formatSearch(accumulative));
				}
			} else {
				const features = await searchOptions([value], queryString)
				cb(formatSearch(features));
			}
		}

		const handleSelect = async (e) => {
			const featureResponse = await featureService.getFeatureById(e.id, user.token);
			MapService.flyto(featureResponse);
			MapService.selectPin(featureResponse.geometry.coordinates, e.properties.discriminator === OPTIONS[1].discriminator);
			handleSelectedFeatures(featureResponse.id, getFeatureCollection.value.features);
			const activeAside = e.properties.discriminator === OPTIONS[2].discriminator.split('_')[1] || e.properties.discriminator === OPTIONS[2].discriminator.split('_')[0];
			activeAside ?
				null :
				store.dispatch('map/changeAside', {...featureResponse, isActive: true});
		}

		return {
			options: OPTIONS,
			searchOption,
			searchType,
			selectedValue,
			selectedIcon: 'Study',
			TRADUCTOR: TRADUCTOR,
			querySearch,
			handleSelect
		}
	}
}
</script>

<style>
.search-bar .el-input__wrapper{
	padding: 0 0;
}

.seletor .el-input__wrapper {
	border-radius: 6px 0 0 6px;
	border-color: var(--dm_cinza_03);
	width: 166px;
	color: var(--dm_azul_00);
	padding: 0px 10px 0 10px;
}

.seletor .el-input__inner {
	color: var(--dm_azul_00);
}

.barra-pesquisa .el-input__wrapper {
	border-radius: 0 6px 6px 0; 
	border-left: none;
	border-color: var(--dm_cinza_03);
	padding: 0 60px 0 10px;
	width: 300px;
}
</style>

<style scoped>
.search-bar {
	position: relative;
	display: flex;
	align-items: center;
}

.items_list {
	display: flex;
	justify-content: space-between;
}

.container_item {
	display: flex;
	gap: 10px;
	align-items: center;
}

.item_in_list {
	font-size: 16px;
}

.search-bar__filter {
	display: flex;
	align-items: center;
	gap: 20px;
	height: 35px;
	background: var(--dm_creme_02);
	border-radius: 1px solid var(--dm_cinza_03);
	border: 2px solid var(--dm_cinza_03);
	padding: 0 10px;
	border-radius: 6px 0 0 6px;
}

[Search] {
	position: absolute;
	right: 10px;
}
</style>