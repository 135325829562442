export default {
  isAuthenticated(state) {
    return !!state.user.loggedIn;
  },
  isTokenExpired(state) {
    if (state.user.authExpiration === null) return true;
    const now = new Date();
    return now.getTime() >= state.user.authExpiration.getTime();
  },
  getUser(state) {
    return state.user;
  },
  getCompany(state) {
    return state.company;
  },
  getToken(state) {
    return state.user.token;
  },
  getTokenExpiration(state) {
    return state.user.authExpiration;
  }
};
