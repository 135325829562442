import { createApp } from 'vue';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import './assets/styles/index.css';
import pt from 'element-plus/dist/locale/pt';
import App from './App.vue';
import router from './router';
import store from './store';
import { loadFonts } from './plugins/webfontloader';
import { registerModules } from './register-modules';
import authModule from './modules/auth';
import mapModule from './modules/map';
import biModule from './modules/bi';
import vuetify from './plugins/vuetify';
import './plugins/customButtonTypes.js'
import axios from './plugins/axiosInterceptors'; // Import the axios instance with interceptors
loadFonts();

registerModules({
  auth: authModule,
  map: mapModule,
  bi: biModule
});

router.beforeEach((to, from, next) => {
  authModule.authGuard(to, from, next);
});

let app;
authModule.auth.onAuthStateChanged((user) => {
  if (!app) {
    app = createApp(App)
      .provide('$axios', axios)
      .use(router)
      .use(store)
      .use(vuetify)
      .use(ElementPlus, { locale: pt })
    app.mount('#app');
    store.dispatch('auth/fetchUser', user);
  }
});
